var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ArticleLink, Wrapper } from "./styles";
import ArticleImage from "src/customer/articleImage/components/ArticleImage";
var SliderArticle = function (_a) {
    var active = _a.active, article = _a.article, basePath = _a.basePath, params = _a.params;
    return (_jsx(Wrapper, __assign({ active: active, width: 4, height: 5 }, { children: _jsx(ArticleLink, __assign({ to: "" + (basePath || '') + article.id + params, className: 'ratioContainer' }, { children: _jsx(ArticleImage, { article: article, size: "thumbnail" }, void 0) }), void 0) }), void 0));
};
export default SliderArticle;
