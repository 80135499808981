var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { StyledButton, Wrapper, ErrorMsg } from './styles';
import { requiredValidator } from './validators';
import { email } from '@shared/utils/validation';
var composeValidators = function () {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (value) {
        return validators.reduce(function (error, validator) { return error || validator(value); }, undefined);
    };
};
var CustomerDataForm = function (props) {
    return (_jsxs(Wrapper, { children: [_jsx("h3", { children: "Kontaktdaten zur Abholung" }, void 0), _jsx(Form, { onSubmit: props.onSubmit, render: function (_a) {
                    var handleSubmit = _a.handleSubmit, form = _a.form, submitting = _a.submitting, pristine = _a.pristine, values = _a.values;
                    return (_jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(Field, __assign({ name: 'name' }, { children: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (_jsxs("div", { children: [_jsx("label", { children: "Name" }, void 0), _jsx("br", {}, void 0), _jsx("input", __assign({}, input, { type: 'text', placeholder: 'Vorname Nachname', style: {
                                                    width: '100%',
                                                    height: '25px',
                                                    marginTop: '5px',
                                                    marginBottom: '10px',
                                                } }), void 0), _jsx("br", {}, void 0), meta.error && meta.touched && (_jsx(ErrorMsg, { children: meta.error }, void 0))] }, void 0));
                                } }), void 0), _jsx(Field, __assign({ name: 'eMail', validate: composeValidators(requiredValidator, email) }, { children: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (_jsxs("div", { children: [_jsx("label", { children: "E-Mail" }, void 0), _jsx("br", {}, void 0), _jsx("input", __assign({}, input, { type: 'text', placeholder: 'mail@example.com', style: {
                                                    width: '100%',
                                                    height: '25px',
                                                    marginTop: '5px',
                                                    marginBottom: '10px',
                                                } }), void 0), _jsx("br", {}, void 0), meta.error && meta.touched && (_jsx(ErrorMsg, { children: meta.error }, void 0))] }, void 0));
                                } }), void 0), _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: '-', id: 'clickAndReserve.notifyMsg' }, void 0) }, void 0), _jsx(StyledButton, __assign({ disabled: submitting }, { children: _jsx(FormattedMessage, { defaultMessage: 'Weiter', id: 'checkout.bottomMenu.next' }, void 0) }), void 0)] }), void 0));
                } }, void 0)] }, void 0));
};
export default CustomerDataForm;
