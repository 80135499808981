var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
export var MainImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var Slide = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  text-decoration: none;\n"], ["\n  height: 100%;\n  text-decoration: none;\n"])));
export var Fullscreen = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 50;\n"], ["\n  background: ", ";\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 50;\n"])), palette('transparentBlack'));
export var SliderWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n  height: 100%;\n"])));
export var Close = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: white;\n  border-radius: 100rem;\n  height: 2rem;\n  padding: 0.5rem;\n  position: absolute;\n  right: 1rem;\n  top: 1rem;\n  width: 2rem;\n  z-index: 55;\n"], ["\n  background: white;\n  border-radius: 100rem;\n  height: 2rem;\n  padding: 0.5rem;\n  position: absolute;\n  right: 1rem;\n  top: 1rem;\n  width: 2rem;\n  z-index: 55;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
