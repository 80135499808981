var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { palette } from '@shared/utils/styled';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import SessionLayout from 'templates/SessionLayout';
import CategoryGrid from 'organisms/CategoryGrid';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import history from '@store/history';
import SubMenu from 'organisms/SubMenu';
import messages from './messages';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search(props) {
        var _this = _super.call(this, props) || this;
        _this.getBaseTitle = function () {
            var _a = _this.props, match = _a.match, primaryCategories = _a.primaryCategories;
            if (!Boolean(primaryCategories) ||
                !(Boolean(match) &&
                    Boolean(match.params) &&
                    Boolean(match.params.primaryCategoryId))) {
                return '';
            }
            var current = primaryCategories.find(function (item) { return item.id === parseInt(match.params.primaryCategoryId, 10); });
            return current && Boolean(current.displayName) ? current.displayName : '';
        };
        _this.getTabs = function () {
            var _a = _this.props, primaryCategories = _a.primaryCategories, session = _a.session;
            return primaryCategories.reduce(function (acc, item) {
                acc.push({
                    title: item.displayName,
                    target: "/" + session.uuid + "/search/" + item.id,
                });
                return acc;
            }, []);
        };
        return _this;
    }
    Search.prototype.render = function () {
        var _a = this.props, categories = _a.categories, categoryCurrentPage = _a.categoryCurrentPage, categoryLoading = _a.categoryLoading, categoryPageCount = _a.categoryPageCount, deleteWatchlistItem = _a.deleteWatchlistItem, getCategories = _a.getCategories, intl = _a.intl, match = _a.match, session = _a.session, watchlist = _a.watchlist;
        return (_jsx(SessionLayout, __assign({ backgroundColor: palette('white'), deleteWatchlistItem: deleteWatchlistItem, session: session, watchlist: watchlist, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push("/" + session.uuid + "/search"); }, fill: 'white', withText: true }, void 0), title: this.getBaseTitle()
                    ? this.getBaseTitle()
                    : intl.formatMessage(messages.title) }, void 0) }, { children: _jsx(CategoryGrid, { basePath: "/" + session.uuid, baseTitle: this.getBaseTitle(), categories: categories, currentPage: categoryCurrentPage, getCategories: getCategories, loading: categoryLoading, pageCount: categoryPageCount, primaryCategoryId: match.params.primaryCategoryId
                    ? parseInt(match.params.primaryCategoryId, 10)
                    : null }, void 0) }), void 0));
    };
    return Search;
}(Component));
export default withRouter(injectIntl(Search));
