// @flow
export var getSearchParams = function (urlParams) {
    var paramsArray = urlParams.substr(1).split('&');
    var params = {};
    for (var i = 0; i < paramsArray.length; i += 1) {
        var pair = paramsArray[i].split('=');
        params[pair[0]] = pair[1];
    }
    return params;
};
