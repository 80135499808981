// @flow
import { handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    currentPage: 0,
    data: List(),
    loaded: false,
    loadedAll: false,
    loading: false,
    loadingAll: false,
    pageCount: 0,
});
var updateEntry = function (entry) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (existingEntry) { return existingEntry.get('id') === entry.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(entry);
    }
    // $FlowIgnoreNextLine
    return existing.set(index, entry);
}; };
var updateEntries = function (entries) { return function (existing) {
    return entries;
    // DEV-120 (https://42-projekte.atlassian.net/browse/DEV-120)
    // Don't know why we need this function, but it's causing the error. Let's keep it here for now in case
    // we need it later. 😅
    /* if (existing.size === 0) {
      return entries;
    }
    let newEntries = existing;
    entries.forEach(device => {
      // $FlowIgnoreNextLine
      newEntries = newEntries.update(updateEntry(device));
    });
    return newEntries; */
}; };
var reducer = handleActions({
    GET_CATEGORY: function (state) { return state.set('loading', true); },
    GET_CATEGORY_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateEntry(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    GET_CATEGORIES: function (state) { return state.set('loadingAll', true); },
    GET_CATEGORIES_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('currentPage', action.payload.toJS().page)
                .set('pageCount', action.payload.toJS().totalPages)
                .update('data', updateEntries(fromJS(action.payload.toJS().content)));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    RESET_CATEGORIES: function (state) { return state.set('data', List()).set('currentPage', 0); },
}, initialState);
export default reducer;
