var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils & libs
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getSearchParams } from '@shared/utils/params';
import { stringify, parse } from 'query-string';
import { toJS } from '@shared/utils/immutable';
import sortBy from 'lodash/sortBy';
// selectors
import { selectFilters, selectLoadingAll as selectLoadingAllFilters, selectLoading as selectLoadingFilter, } from '@store/filter/selectors';
import { selectTempFilterSelections } from '@store/filterSelection/selectors';
// actions
import { getFilters, getFilter } from '@store/filter/actions';
import { selectFilterItem, deselectFilterItem, applyTempFilterSelections, resetFilterSelections, } from '@store/filterSelection/actions';
// components & container
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/Filter';
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(props) {
        var _this = _super.call(this, props) || this;
        _this.fetch = function () {
            var pageParams = getSearchParams(_this.props.location.search);
            if (!_this.props.filtersLoading) {
                _this.props.getFilters(__assign({}, pageParams));
            }
        };
        _this.applyFilterSelection = function (sessionUuid) {
            var _a = _this.props, tempFilterSelections = _a.tempFilterSelections, location = _a.location;
            _this.props.applyTempFilterSelections();
            var baseParams = parse(location.search, { arrayFormat: 'bracket' });
            var filterParams = tempFilterSelections.reduce(function (acc, filterSelection) {
                if (!Boolean(filterSelection.items))
                    return acc;
                acc[filterSelection.name] = filterSelection.items.reduce(function (flat, item) {
                    flat.push(item.id);
                    return flat;
                }, []);
                return acc;
            }, baseParams);
            _this.props.history.push({
                pathname: "/" + sessionUuid + "/results",
                search: "?" + stringify(filterParams, { arrayFormat: 'bracket' }),
            });
        };
        _this.resetFilters = function (sessionUuid) {
            var _a = _this.props, resetFilterSelections = _a.resetFilterSelections, location = _a.location;
            resetFilterSelections();
            var currentParams = parse(location.search, { arrayFormat: 'bracket' });
            var baseParams = {};
            if (currentParams.category !== undefined) {
                baseParams.category = currentParams.category;
            }
            if (currentParams.primaryCategory !== undefined) {
                baseParams.primaryCategory = currentParams.primaryCategory;
            }
            if (currentParams.sort !== undefined) {
                baseParams.sort = currentParams.sort;
            }
            _this.props.history.push({
                pathname: "/" + sessionUuid + "/results",
                search: "?" + stringify(baseParams, { arrayFormat: 'bracket' }),
            });
        };
        return _this;
    }
    Filter.prototype.render = function () {
        var _this = this;
        var _a = this.props, filterPage = _a.filterPage, filterPageCount = _a.filterPageCount, filters = _a.filters, filtersLoading = _a.filtersLoading, location = _a.location, tempFilterSelections = _a.tempFilterSelections, deselectFilterItem = _a.deselectFilterItem, filterLoading = _a.filterLoading, selectFilterItem = _a.selectFilterItem, getFilter = _a.getFilter;
        var sortedFilters = sortBy(filters, 'displayName');
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { fetch: function () { return _this.fetch(); }, filterPage: filterPage, filterPageCount: filterPageCount, filters: sortedFilters, filterSelections: tempFilterSelections, filtersLoading: filtersLoading, params: location.search, applyFilterSelection: function () {
                    return _this.applyFilterSelection(sessionProps.session.uuid);
                }, deselectFilterItem: deselectFilterItem, filterLoading: filterLoading, selectFilterItem: selectFilterItem, getFilter: getFilter, resetFilters: function () { return _this.resetFilters(sessionProps.session.uuid); } }), void 0)); } }, void 0));
    };
    return Filter;
}(Component));
var select = function () { return function (state) { return ({
    filters: selectFilters(state),
    filtersLoading: selectLoadingAllFilters(state),
    tempFilterSelections: selectTempFilterSelections(state),
    filterLoading: selectLoadingFilter(state),
}); }; };
export default withRouter(connect(select, {
    getFilters: getFilters,
    getFilter: getFilter,
    selectFilterItem: selectFilterItem,
    deselectFilterItem: deselectFilterItem,
    applyTempFilterSelections: applyTempFilterSelections,
    resetFilterSelections: resetFilterSelections,
})(toJS(Filter)));
