var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import Button from '@shared/atoms/Button';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var StyledButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 100%;\n"], ["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 100%;\n"])), palette('secondaryHighlight'), palette('white'), palette('activeWhite'));
export var ErrorMsg = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #cc2c24;\n"], ["\n  color: #cc2c24;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
