import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Fragment, useEffect } from 'react';
import ArticleListItem from 'molecules/ArticleListItem';
import TileInfiniteScroll from '@shared/organisms/TileInfiniteScroll';
import { FilterDescription, NoResultFound, FilterWrapper } from './styles';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectLoadingAllComplete } from 'src/store/article/selectors';
import { filterSelection } from '@store/filterSelections/filterSelectors';
var ArticleList = function (_a) {
    var articles = _a.articles, basePath = _a.basePath, currentPage = _a.currentPage, filterDescription = _a.filterDescription, loading = _a.loading, pageCount = _a.pageCount, primaryCategory = _a.primaryCategory, fetchArticles = _a.fetchArticles, params = _a.params;
    var getSelectedFilter = useSelector(filterSelection);
    var articlesLoaded = useSelector(selectLoadingAllComplete);
    var location = useLocation();
    var fetch = function () {
        if (!loading) {
            fetchArticles({ page: currentPage + 1 });
        }
    };
    useEffect(function () {
        fetch();
    }, []);
    useEffect(function () {
        var _a;
        var section = document.getElementById((_a = location.state) === null || _a === void 0 ? void 0 : _a.articleId);
        articlesLoaded && section && section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlesLoaded]);
    var renderItems = function (articles, basePath) {
        return articles.map(function (article) {
            return (_jsx(ArticleListItem, { article: article, basePath: basePath, params: params }, article.id + '_' + article.color));
        });
    };
    var items = renderItems(articles, basePath);
    return (_jsxs(Fragment, { children: [Boolean(filterDescription) && (_jsx(FilterWrapper, { children: _jsx(FilterDescription, { children: _jsx(_Fragment, { children: getSelectedFilter.map(function (item) {
                            return item.name + ", ";
                        }) }, void 0) }, void 0) }, 'filterDescription')), !loading && articles.length === 0 && _jsx(NoResultFound, { children: "Keine Ergebnisse gefunden" }, 'noResults'), _jsx(TileInfiniteScroll, { items: items, hasMore: currentPage < pageCount, currentPage: currentPage, height: 'calc(100vh - 5rem)', loading: loading, loadMore: fetch }, void 0)] }, void 0));
};
export default ArticleList;
