var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//  @flow
import React from 'react';
import { connect } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { normalize } from 'polished';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@shared/utils/immutable';
import { Switch, Route, withRouter, } from 'react-router-dom';
import ArticleContainer from 'containers/Article';
import SessionDashboard from 'containers/SessionDashboard';
import Inspiration from 'containers/Inspiration';
import Result from 'containers/Result';
import Search from 'containers/Search';
import Filter from 'containers/Filter';
import Sort from 'containers/Sort';
import Scan from 'containers/Scan';
import Department from 'containers/Department';
import ClickAndCollect from 'containers/ClickAndCollect';
import ClickAndReserve from 'containers/ClickAndReserve';
import NotFound from 'containers/NotFound';
import ThankYou from 'containers/ThankYou';
import Category from 'containers/Category';
import { selectFullTheme } from '@store/theme/selectors';
import { MatchAndFitIntegration } from 'components/molecules/MatchAndFitIntegration';
import CheckoutError from "containers/CheckoutError";
// eslint-disable-next-line no-unused-expressions
var GlobalStyles = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  html, body, #app {\n    height: 100%;\n    -webkit-font-smoothing: antialiased;\n    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);\n  }\n  body {\n    font-family: Nunito, Helvetica Neue, Helvetica, Roboto, sans-serif;\n    font-weight: 300;\n    letter-spacing: 0.4px;\n    line-height: 1.375rem;\n    margin: 0;\n    overflow: hidden;\n  }\n  * {\n    box-sizing: border-box;\n  }\n  button, input {\n    font-family: inherit;\n  }\n  svg:not(:root) {\n    overflow: visible;\n  }\n  .odd {\n    background-color:#eee;\n  }\n"], ["\n  ", "\n\n  html, body, #app {\n    height: 100%;\n    -webkit-font-smoothing: antialiased;\n    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);\n  }\n  body {\n    font-family: Nunito, Helvetica Neue, Helvetica, Roboto, sans-serif;\n    font-weight: 300;\n    letter-spacing: 0.4px;\n    line-height: 1.375rem;\n    margin: 0;\n    overflow: hidden;\n  }\n  * {\n    box-sizing: border-box;\n  }\n  button, input {\n    font-family: inherit;\n  }\n  svg:not(:root) {\n    overflow: visible;\n  }\n  .odd {\n    background-color:#eee;\n  }\n"])), normalize());
var App = function (_a) {
    var theme = _a.theme;
    return (_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(GlobalStyles, {}, void 0), _jsxs(Switch, { children: [_jsx(Route, { path: '/', component: SessionDashboard, exact: true }, void 0), _jsx(Route, { path: '/checkout', render: function (props) { return _jsx(SessionDashboard, __assign({}, props, { checkout: true }), void 0); }, exact: true }, void 0), _jsx(Route, { path: '/:id', component: SessionDashboard, exact: true }, void 0), _jsx(Route, { path: '/:id/articles/:articleId', component: ArticleContainer, exact: true }, void 0), _jsx(Route, { path: '/:id/inspiration', component: Inspiration, exact: true }, void 0), _jsx(Route, { path: '/:id/departments', component: Department, exact: true }, void 0), _jsx(Route, { path: '/:id/search', component: Category, exact: true }, void 0), _jsx(Route, { path: '/:id/search/:primaryCategoryId', component: Search, exact: true }, void 0), _jsx(Route, { path: '/:id/results', component: Result }, void 0), _jsx(Route, { path: '/:id/filters/:primaryCategoryId', component: Filter }, void 0), _jsx(Route, { path: '/:id/filters/', component: Filter }, void 0), _jsx(Route, { path: '/:id/sorts', component: Sort }, void 0), _jsx(Route, { path: '/:id/click-and-collect', component: ClickAndCollect }, void 0), _jsx(Route, { path: '/:id/click-and-reserve', component: ClickAndReserve }, void 0), _jsx(Route, { path: '/:id/thank-you', component: ThankYou }, void 0), _jsx(Route, { path: '/:id/checkout/error', component: CheckoutError }, void 0), _jsx(Route, { path: '/:id/scan', component: Scan, exact: true }, void 0), _jsx(Route, { path: '/:id/scanFeet', component: MatchAndFitIntegration, exact: true }, void 0), _jsx(Route, { component: NotFound }, void 0)] }, void 0)] }), void 0));
};
var select = createStructuredSelector({
    theme: selectFullTheme,
});
export default withRouter(connect(select, {})(toJS(App)));
var templateObject_1;
