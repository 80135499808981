var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { useEffect, useState } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { defineMessages, useIntl } from 'react-intl';
// import { parse } from 'query-string';
// components
import ArticleView from 'organisms/ArticleView';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
var Article = function (_a) {
    var createWatchlistItem = _a.createWatchlistItem, article = _a.article, deleteWatchlistItem = _a.deleteWatchlistItem, primaryCategoryId = _a.primaryCategoryId, categoryId = _a.categoryId, session = _a.session, source = _a.source, watchlist = _a.watchlist;
    var _b = useState(null), currentSize = _b[0], setCurrentSize = _b[1];
    var _c = useState(), returnParams = _c[0], setReturnParams = _c[1];
    var search = useLocation().search;
    var values = queryString.parse(search);
    var history = useHistory();
    var params = useParams();
    var intl = useIntl();
    //   const autoAddArticleToWatchList = () => {
    //     createWatchlistItem({
    //       articleId: params.articleId,
    //       sizeId: null,
    //     });
    //   };
    useEffect(function () {
        setReturnParams(values);
    }, []);
    useEffect(function () {
        /*// If this link is called from the QR scanner ad has ref=qr we add current article in the watch list
             const queries = parse(history.location.search);
            //  Deactivated auto-add in accordance with https://42-projekte.atlassian.net/browse/NEED-470
             if (queries.ref === 'qr') {
              this.autoAddArticleToWatchList();
            } */
        // Reset size select on article change
        setCurrentSize(null);
    }, [params.articleId]);
    var queryParams = "?primaryCategory=" + (returnParams && returnParams.primaryCategory) + "&category=" + (returnParams && returnParams.category) + "&source=" + source;
    var currentSizeId = currentSize ? currentSize.sizeId : null;
    var currentItemStatus = currentSize ? currentSize.stock : null;
    var watchlistItem = Boolean(article)
        ? watchlist.find(function (item) { return item.articleId === article.id && item.sizeId === currentSizeId; })
        : null;
    var msg = defineMessages({
        article: { defaultMessage: 'Artikel', id: 'article.page.headline' },
    });
    var title = undefined !== article && undefined !== article.primaryCategory
        ? article.primaryCategory
        : intl.formatMessage(msg.article);
    return (_jsx(_Fragment, { children: _jsx(SessionLayout, __assign({ session: session, backgroundColor: palette('white'), subMenu: _jsx(SubMenu, { cancelIcon: source === 'scan' ? (_jsx(BackButtonSmall, { action: function () { return history.push("/" + session.uuid + "/scan"); }, withText: true }, void 0)) : null, action: function () {
                    return history.push({
                        pathname: "/" + session.uuid + "/results",
                        search: "" + history.location.search,
                        state: { articleId: "" + article.id },
                    });
                }, fill: 'white', title: title }, void 0), deleteWatchlistItem: deleteWatchlistItem, session: session, watchlist: watchlist }, { children: _jsx(ArticleView, { article: article, createWatchlistItem: function () {
                    currentSizeId &&
                        createWatchlistItem({
                            articleId: article.id,
                            sizeId: currentSizeId,
                            status: currentItemStatus,
                        });
                }, handleSizeSelect: setCurrentSize, basePath: "/" + session.uuid + "/articles/", currentSize: currentSize, params: queryParams, watchlistItem: watchlistItem, primaryCategoryId: primaryCategoryId }, void 0) }), void 0) }, void 0));
};
export default Article;
