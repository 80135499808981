import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import localStore from 'store';
import SizeSelectItem from 'molecules/SizeSelectItem';
import { getUsSizeForGender } from '@shared/utils/matchAndFit';
import { Name, Wrapper, Grid } from './styles';
var SizeSelect = function (_a) {
    var clickHandler = _a.clickHandler, sizes = _a.sizes, current = _a.current, primaryCategoryId = _a.primaryCategoryId;
    var matchnFitSizes = [];
    matchnFitSizes.push(localStore.get('sizeEu'), localStore.get('sizeUk'), getUsSizeForGender(primaryCategoryId));
    var currentSize = sizes === null || sizes === void 0 ? void 0 : sizes.find(function (size) { return size.sizeId === current; });
    var isFittingSize = function (labelSize) {
        return ((matchnFitSizes.includes(labelSize) &&
            !matchnFitSizes.includes(('' +
                (parseFloat(labelSize.replace(/(\d+)(½)/, '$1.5')) - 0.5)).replace(/(\d+)(\.5)/, '$1½'))) ||
            (matchnFitSizes.includes(('' +
                (parseFloat(labelSize.replace(/(\d+)(½)/, '$1.5')) - 0.5)).replace(/(\d+)(\.5)/, '$1½')) &&
                !matchnFitSizes.includes(labelSize)));
    };
    return (sizes === null || sizes === void 0 ? void 0 : sizes.length) > 0 ? (_jsxs(Wrapper, { children: [_jsx(Name, { children: "Gr\u00F6\u00DFen" }, void 0), _jsx(Grid, { children: sizes === null || sizes === void 0 ? void 0 : sizes.map(function (size) {
                    // Workaround for DEV-69 (https://42-projekte.atlassian.net/browse/DEV-69)
                    // TODO Remove later
                    return size.stock === 'available' && (_jsx(SizeSelectItem, { size: size, active: Boolean(current) && current === size.sizeId, clickHandler: clickHandler, fitting: isFittingSize(size.labelSize) }, size.sizeId));
                }) }, void 0), currentSize && (_jsxs("div", { children: [currentSize.stock === 'available' && (_jsx("span", { children: "Lieferbar in der Filiale." }, void 0)), currentSize.stock === 'inDifferentStore' &&
                        currentSize.deliveryTime &&
                        currentSize.stores.length > 0 && (_jsxs("div", { children: [_jsxs("span", { children: ["Lieferbar im Unternehmen (Lieferzeit ca.", ' ', currentSize.deliveryTime, ' ', currentSize.deliveryTime > 1 ? 'Werktage' : 'Werktag', ")"] }, void 0), currentSize.stores.map(function (store) { return (_jsx("div", { children: store.store }, store.id)); })] }, void 0)), currentSize.stock === 'inStock' && currentSize.deliveryTime && (_jsxs("span", { children: ["Lieferbar im Unternehmen (Lieferzeit ca.", ' ', currentSize.deliveryTime, ' ', currentSize.deliveryTime > 1 ? 'Werktage' : 'Werktag', ")"] }, void 0)), currentSize.stock === 'orderable' && currentSize.deliveryTime && (_jsxs("span", { children: ["Lieferbar \u00FCber Lieferant (Lieferzeit ca.", ' ', currentSize.deliveryTime, ' ', currentSize.deliveryTime > 1 ? 'Werktage' : 'Werktag', ")"] }, void 0)), currentSize.stock === 'scheduled' && currentSize.deliveryDate && (_jsxs("span", { children: ["Disponiert (lieferbar ab", ' ', new Date(currentSize.deliveryDate).toLocaleDateString(), ")"] }, void 0))] }, void 0))] }, void 0)) : (_jsx("div", {}, void 0));
};
export default SizeSelect;
