var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import H2 from '@shared/atoms/H2';
import P from '@shared/atoms/P';
import { palette, fontSize } from '@shared/utils/styled';
export var Main = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  height: calc(100vh - 35rem);\n  width: 100%;\n  padding: 0 10%;\n  min-height: 18.125rem;\n"], ["\n  background: ", ";\n  height: calc(100vh - 35rem);\n  width: 100%;\n  padding: 0 10%;\n  min-height: 18.125rem;\n"])), palette('white'));
export var Center = styled(P)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  margin: 0;\n"], ["\n  text-align: center;\n  margin: 0;\n"])));
export var Headline = styled(H2)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 0.5rem;\n  margin-bottom: 0;\n"], ["\n  text-align: center;\n  margin-top: 0.5rem;\n  margin-bottom: 0;\n"])));
export var Error = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: red;\n  font-size: ", ";\n  min-height: 1.375rem;\n  width: 100%;\n  text-align: center;\n"], ["\n  color: red;\n  font-size: ", ";\n  min-height: 1.375rem;\n  width: 100%;\n  text-align: center;\n"])), fontSize('small'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
