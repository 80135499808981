var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import { palette } from '@shared/utils/styled';
// components
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import ScanSearch from 'organisms/ScanSearch';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import { useHistory } from 'react-router';
var Scan = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, searchArticle = _a.searchArticle, scanArticle = _a.scanArticle, session = _a.session, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, resetArticleError = _a.resetArticleError;
    var history = useHistory();
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, backgroundColor: palette('white'), currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { title: 'Scan', cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push('/'); }, fill: 'white', withText: true }, void 0) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: _jsx(ScanSearch, { sessionId: session.uuid, searchArticle: searchArticle, scanArticle: scanArticle, resetArticleError: resetArticleError }, void 0) }), void 0));
};
export default Scan;
