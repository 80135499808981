var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
import styled from 'styled-components';
// components
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* background-color: ", "; */\n  background-color: ", ";\n  display: grid;\n  height: calc(100% - 3rem);\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 0;\n  transform-origin: 0 100%;\n  transform: ", ";\n  transition: transform 0.5s;\n  width: 100%;\n  z-index: 2;\n  align-content: start;\n  border-bottom: 0.125rem solid ", ";\n"], ["\n  /* background-color: ", "; */\n  background-color: ", ";\n  display: grid;\n  height: calc(100% - 3rem);\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 0;\n  transform-origin: 0 100%;\n  transform: ", ";\n  transition: transform 0.5s;\n  width: 100%;\n  z-index: 2;\n  align-content: start;\n  border-bottom: 0.125rem solid ", ";\n"])), palette('primaryBackground'), palette('white'), function (props) { return (props.visible ? 'scaleY(1)' : 'scaleY(0)'); }, palette('border'));
export var WatchlistWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: calc(100vh - 13rem);\n  overflow: auto;\n"], ["\n  height: calc(100vh - 13rem);\n  overflow: auto;\n"])));
export var CheckoutInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #fff;\n  height: calc(100vh - 10rem);\n  overflow-y: auto;\n  color: #000;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 50px;\n"], ["\n  background-color: #fff;\n  height: calc(100vh - 10rem);\n  overflow-y: auto;\n  color: #000;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 50px;\n"])));
export var CheckoutWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n  color: black;\n  background: white;\n  z-index: inherit;\n  height: 5.75rem;\n"], ["\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n  color: black;\n  background: white;\n  z-index: inherit;\n  height: 5.75rem;\n"])));
export var StyledButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 100%;\n  position: relative;\n  top: 5%;\n"], ["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 100%;\n  position: relative;\n  top: 5%;\n"])), palette('secondaryHighlight'), palette('white'), palette('activeWhite'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
