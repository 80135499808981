// @ts-ignore
import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import isIn from 'validator/lib/isIn';
import isURL from 'validator/lib/isURL';
import isMobilePhone from 'validator/lib/isMobilePhone';
var now = new Date();
var yearLimit = new Date().setFullYear(now.getFullYear() - 120);
var isEmpty = function (value) {
    return value === undefined || value === null || value === '';
};
var join = function (rules) { return function (value, data) {
    return rules
        .map(function (rule) { return rule(value, data); })
        .filter(function (error) { return !!error; })[0];
}; };
export var phone = function (value) {
    if (value === void 0) { value = ''; }
    return !isMobilePhone(value) && !isEmpty(value)
        ? 'Ungültige Telefonnummer'
        : undefined;
};
export var email = function (value) {
    return !isEmail(value, {
        allow_utf8_local_part: false,
        blacklisted_chars: 'äöüß',
    })
        ? 'Invalide E-Mail-Adresse'
        : undefined;
};
export var url = function (value) {
    return !isEmpty(value) && !isURL(value) ? 'Invalid URL' : undefined;
};
export var required = function (value) {
    return isEmpty(value) ? 'Pflichtfeld' : undefined;
};
export var minLength = function (min) { return function (value) {
    return !isEmpty(value) && value.length < min
        ? "Must be at least " + min + " characters"
        : undefined;
}; };
export var maxLength = function (max) { return function (value) {
    return !isEmpty(value) && value.length > max
        ? "Must be no more than " + max + " characters"
        : undefined;
}; };
export var maxLength2 = maxLength(2);
export var maxLength4 = maxLength(4);
export var integer = function (value) {
    return !isInt(value) ? 'Must be an integer' : undefined;
};
export var containsNoInteger = function (value) {
    return /\d/.test(value) ? 'Darf keine Zahl enthalten' : undefined;
};
export var oneOf = function (values) { return function (value) {
    return !isIn(value, values) ? "Must be one of: " + values.join(', ') : undefined;
}; };
export var match = function (field) { return function (value, data) {
    return data && value !== data[field] ? 'Must match' : undefined;
}; };
export var day = function (value) {
    return !isEmpty(value) && !isInt(value, { min: 1, max: 31 })
        ? 'Ungültig'
        : undefined;
};
export var month = function (value) {
    return !isEmpty(value) && !isInt(value, { min: 1, max: 12 })
        ? 'Ungültig'
        : undefined;
};
export var year = function (value) {
    return !isEmpty(value) &&
        !isInt(value, {
            min: new Date(yearLimit).getFullYear(),
            max: now.getFullYear(),
        })
        ? 'Ungültig'
        : undefined;
};
export var createValidator = function (rules) {
    return function (data) {
        if (data === void 0) { data = {}; }
        var errors = {};
        Object.keys(rules).forEach(function (key) {
            var rule = join([].concat(rules[key]));
            var error = rule(data[key], data);
            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
};
export var composeValidators = function () {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (value) {
        return validators.reduce(function (error, validator) { return error || validator(value); }, undefined);
    };
};
