var cancellablePromise = function (promise) {
    var isCancelled = { value: false };
    var wrappedPromise = new Promise(function (res, rej) {
        promise
            .then(function (d) {
            return isCancelled.value ? rej(isCancelled) : res(d);
        })
            .catch(function (e) {
            rej(isCancelled.value ? isCancelled : e);
        });
    });
    return {
        promise: wrappedPromise,
        cancel: function () {
            isCancelled.value = true;
        },
    };
};
export default cancellablePromise;
