import { createSelector } from 'reselect';
import store from 'store';
var selectSessionState = function (state) { return state.get('session'); };
var selectSessionId = function () { return store.get('sessionId'); };
export var selectCheckingOut = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkingOut'); });
export var selectCheckoutNumber = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkoutNumber'); });
export var selectLoading = createSelector(selectSessionState, function (sessionState) {
    return sessionState.get('loading');
});
export var selectLoaded = createSelector(selectSessionState, function (sessionState) {
    return sessionState.get('loaded');
});
export var selectSessions = createSelector(selectSessionState, function (sessionState) {
    return sessionState.get('data');
});
export var makeSelectSession = function () {
    return createSelector([selectSessions, selectSessionId], function (sessions, sessionId) {
        return sessions.find(function (session) { return session.get('uuid') === sessionId; });
    });
};
