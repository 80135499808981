var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { key } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  overflow-y: auto;\n"], ["\n  flex: 1;\n  overflow-y: auto;\n"])));
export var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n  max-width: 90%;\n"], ["\n  display: block;\n  margin: 0 auto;\n  max-width: 90%;\n"])));
export var Section = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 auto 1.25rem auto;\n  max-width: 81.25rem;\n  width: 100%;\n"], ["\n  margin: 0 auto 1.25rem auto;\n  max-width: 81.25rem;\n  width: 100%;\n"])));
export var SectionBig = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 auto 1.25rem auto;\n  @media screen and (min-width: ", ") {\n    width: 90%;\n  }\n"], ["\n  margin: 0 auto 1.25rem auto;\n  @media screen and (min-width: ", ") {\n    width: 90%;\n  }\n"])), key('breakpoints.large'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
