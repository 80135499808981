var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '@shared/utils/styled';
import CenterText from '@shared/atoms/CenterText';
export var Wrapper = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 0.937rem;\n  overflow: hidden;\n  padding: 4%;\n  text-decoration: none;\n  text-overflow: ellipsis;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  margin-bottom: 0.937rem;\n  overflow: hidden;\n  padding: 4%;\n  text-decoration: none;\n  text-overflow: ellipsis;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('activeWhite'));
export var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  position: relative;\n  &:before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n"], ["\n  background-color: ", ";\n  position: relative;\n  &:before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n"])), palette('white'));
export var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: 0.0625rem solid ", ";\n  bottom: 0;\n  height: auto;\n  left: 0;\n  margin: auto;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 100%;\n"], ["\n  background-color: 0.0625rem solid ", ";\n  bottom: 0;\n  height: auto;\n  left: 0;\n  margin: auto;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 100%;\n"])), palette('white'));
export var Centered = styled(CenterText)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0.3125rem 0 0.3125rem 0;\n"], ["\n  padding: 0.3125rem 0 0.3125rem 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
