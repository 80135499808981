var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { palette } from '@shared/utils/styled';
import styled from 'styled-components';
import VerticalFlex from 'atoms/VerticalFlex';
var Content = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  flex: 1;\n  /* min-height: ", "; */\n  height: calc(100vh - 3.125rem);\n"], ["\n  background-color: ", ";\n  flex: 1;\n  /* min-height: ", "; */\n  height: calc(100vh - 3.125rem);\n"])), function (props) {
    return props.backgroundColor
        ? props.backgroundColor
        : palette('primaryBackground');
}, function (props) { return (props.minHeight ? props.minHeight : 'auto'); });
// discussion with hannes needed
var Menu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), palette('white'));
var BottomMenu = styled(Menu)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  bottom: 0;\n  height: 3.125rem;\n"], ["\n  position: relative;\n  bottom: 0;\n  height: 3.125rem;\n"])));
var BaseLayout = /** @class */ (function (_super) {
    __extends(BaseLayout, _super);
    function BaseLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BaseLayout.prototype.render = function () {
        var _a = this.props, children = _a.children, bottomMenu = _a.bottomMenu, topMenu = _a.topMenu, backgroundColor = _a.backgroundColor, minHeight = _a.minHeight;
        return (_jsxs(VerticalFlex, { children: [topMenu && _jsx(Menu, { children: topMenu }, void 0), _jsx(Content, __assign({ backgroundColor: backgroundColor, minHeight: minHeight, style: { overflowY: 'scroll' } }, { children: children }), void 0), bottomMenu && _jsx(BottomMenu, { children: bottomMenu }, void 0)] }, void 0));
    };
    return BaseLayout;
}(Component));
export default BaseLayout;
var templateObject_1, templateObject_2, templateObject_3;
