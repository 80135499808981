import localStorage from "store";
/**
 * Returns the correct US size from localStorage for a given primaryCategoryId.
 *
 * @param {number} primaryCategoryId - Primary Category Id (1 = Male, 2 = Female, 3,4 = Kids).
 */
export var getUsSizeForGender = function (primaryCategoryId) {
    switch (parseInt(primaryCategoryId, 10)) {
        case 1:
            return localStorage.get("sizeUsMen") || localStorage.get("sizeUs");
        case 2:
            return localStorage.get("sizeUsWomen") || localStorage.get("sizeUs");
        case 3:
        case 4:
            return localStorage.get("sizeUsKids") || localStorage.get("sizeUs");
        default:
            // TODO Handle 'diverse'
            return null;
    }
};
