var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { defineMessages, injectIntl } from "react-intl";
import { getPriceString, hasDiscounts } from "@shared/utils/currency";
import Icon from "@shared/atoms/Icon";
import PriceTag from "@shared/molecules/PriceTag";
import DiscountBadgeSmall from "molecules/DiscountBadgeSmall";
import { Bold, ChangeIcon, ContentWrapper, DeleteIcon, Image, Info, ItemContainer, Tag, Wrapper } from "./styles";
import ArticleImage from "src/customer/articleImage/components/ArticleImage";
var WatchlistItem = /** @class */ (function (_super) {
    __extends(WatchlistItem, _super);
    function WatchlistItem(props) {
        var _this = _super.call(this, props) || this;
        _this.getCurrentPrice = function () {
            var item = _this.props.item;
            var article = item.article;
            var prices = article.prices, targetPrices = article.targetPrices;
            if (item.sizeId) {
                var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
                if (size) {
                    if (size.price === size.targetPrice) {
                        return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
                    }
                    return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), " ", _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), " "] }, void 0));
                }
            }
            return _jsx(PriceTag, { prices: prices, targetPrices: targetPrices }, void 0);
        };
        _this.isDiscounted = function () {
            var item = _this.props.item;
            if (!item.sizeId) {
                return hasDiscounts(item.article.prices, item.article.targetPrices);
            }
            var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            if (size) {
                return size.price !== size.targetPrice;
            }
            return false;
        };
        _this.state = {
            deleting: false
        };
        _this.deleteItem = function () {
            if (props.readonly) {
                return;
            }
            _this.setState({
                deleting: !_this.state.deleting
            });
            _this.props.deleteWatchlistItem(_this.props.item.id);
        };
        _this.getLabelSize = function () {
            var _a = _this.props, item = _a.item, intl = _a.intl;
            var msg = defineMessages({
                size: { defaultMessage: "Größe", id: "watchlist.item.size" }
            });
            if (!Boolean(item.sizeId) || !Boolean(_this.props.item.article)) {
                return _jsx("span", {}, void 0);
            }
            var size = _this.props.item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            return (_jsx("span", { children: intl.formatMessage(msg.size) + ": " + (size ? size.labelSize : "") }, void 0));
        };
        return _this;
    }
    WatchlistItem.prototype.openArticle = function () {
        var _a = this.props, sessionId = _a.sessionId, item = _a.item, toggleWatchlist = _a.toggleWatchlist;
        this.props.history.push("/" + sessionId + "/articles/" + item.article.id);
        toggleWatchlist();
    };
    WatchlistItem.prototype.render = function () {
        var _this = this;
        var _a = this.props, index = _a.index, item = _a.item, readonly = _a.readonly;
        var deleting = this.state.deleting;
        var article = item.article;
        return (_jsx(Wrapper, __assign({ "data-cy-id": "watch-list-item-" + index }, { children: _jsxs(ItemContainer, { children: [_jsxs(ContentWrapper, __assign({ onClick: function () { return _this.openArticle(); } }, { children: [_jsxs(Image, { children: [_jsx(ArticleImage, { article: article, size: "thumbnail" }, void 0), this.isDiscounted() && _jsx(DiscountBadgeSmall, {}, void 0)] }, void 0), _jsxs(Info, { children: [_jsx(Bold, { children: article.brand.name }, void 0), article.displayName, " ", article.color && article.color, _jsx("br", {}, void 0), (item.sizeId && this.getLabelSize()) || "", article.prices && _jsx(Bold, { children: this.getCurrentPrice() }, void 0)] }, void 0)] }), void 0), !readonly && (_jsx(DeleteIcon, __assign({ onClick: function () { return _this.deleteItem(); }, deleting: deleting }, { children: _jsx(Icon, { icon: "icon_trashcan-sm", width: 20, fill: "#134e9d", style: { marginRight: "5px" } }, void 0) }), void 0)), !readonly && (_jsx(ChangeIcon, __assign({ onClick: function () { return _this.openArticle(); } }, { children: _jsx(Icon, { icon: "pencil", width: 17, style: { marginRight: "5px" } }, void 0) }), void 0))] }, void 0) }), void 0));
    };
    return WatchlistItem;
}(Component));
export var Item = WatchlistItem;
export default withRouter(injectIntl(WatchlistItem));
