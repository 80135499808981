var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import reduce from "lodash/reduce";
export function getDisplayName(Component) {
    return Component.displayName || Component.name || "Component";
}
/**
 * Decorator that converts immutable props to plain JS.
 *
 * @param Component - Input component.
 * @returns {ToJS} - Mutated component.
 */
export function toJS(Component) {
    var ToJS = /** @class */ (function (_super) {
        __extends(ToJS, _super);
        function ToJS() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        ToJS.prototype.toJS = function () {
            return reduce(this.props, function (result, value, key) {
                // eslint-disable-next-line no-param-reassign
                result[key] =
                    value && typeof value.toJS === "function" ? value.toJS() : value;
                return result;
            }, {});
        };
        ToJS.prototype.render = function () {
            return _jsx(Component, __assign({}, this.toJS()), void 0);
        };
        ToJS.displayName = "ToJS(" + getDisplayName(Component) + ")";
        ToJS.WrappedComponent = Component;
        return ToJS;
    }(React.Component));
    return ToJS;
}
