var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  /* padding: 20px; */\n  margin-bottom: 5px;\n  position: relative;\n"], ["\n  background-color: ", ";\n  /* padding: 20px; */\n  margin-bottom: 5px;\n  position: relative;\n"])), palette('white'));
export var CardBack = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  display: grid;\n  grid-template-rows: 5% 95%;\n  height: calc(100% - 5rem);\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 3.75rem; // header height\n  transform-origin: 100% 0%;\n  transform: ", ";\n  transition: transform 0.5s;\n  width: 100%;\n  z-index: 1;\n  padding: 0.2rem 1rem;\n"], ["\n  background-color: ", ";\n  display: grid;\n  grid-template-rows: 5% 95%;\n  height: calc(100% - 5rem);\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 3.75rem; // header height\n  transform-origin: 100% 0%;\n  transform: ", ";\n  transition: transform 0.5s;\n  width: 100%;\n  z-index: 1;\n  padding: 0.2rem 1rem;\n"])), palette('white '), function (props) { return (props.visible ? 'scaleX(1)' : 'scaleX(0)'); });
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-flow: column wrap;\n  word-break: break-word;\n  overflow: auto;\n  padding-bottom: 100px;\n"], ["\n  flex-flow: column wrap;\n  word-break: break-word;\n  overflow: auto;\n  padding-bottom: 100px;\n"])));
export var FlipCard = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  justify-self: right;\n"], ["\n  position: relative;\n  justify-self: right;\n"])));
export var CardFront = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 90% 10%;\n  grid-template-areas: 'title arrow';\n  height: 100%;\n  position: relative;\n  padding: 20px;\n"], ["\n  display: grid;\n  grid-template-columns: 90% 10%;\n  grid-template-areas: 'title arrow';\n  height: 100%;\n  position: relative;\n  padding: 20px;\n"])));
export var CardTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  grid-area: title;\n"], ["\n  grid-area: title;\n"])));
export var CardArrow = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  grid-area: arrow;\n  font-weight: bold;\n"], ["\n  grid-area: arrow;\n  font-weight: bold;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
