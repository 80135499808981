var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
import { Link } from 'react-router-dom';
import AspectRatio from '@shared/atoms/AspectRatio';
export var Wrapper = styled(AspectRatio)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0.0625rem solid\n    ", ";\n  width: 100%;\n  height: 100%;\n"], ["\n  border: 0.0625rem solid\n    ", ";\n  width: 100%;\n  height: 100%;\n"])), function (props) { return (props.active ? palette('highlightText') : 'transparent'); });
export var ArticleLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    cursor: pointer;\n    text-decoration: none;\n\n    img {\n        max-width: 100%;\n        height: auto;\n    }\n"], ["\n    cursor: pointer;\n    text-decoration: none;\n\n    img {\n        max-width: 100%;\n        height: auto;\n    }\n"])));
var templateObject_1, templateObject_2;
