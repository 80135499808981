var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { palette } from '@shared/utils/styled';
import styled from 'styled-components';
import { stringify } from 'query-string';
import InspirationSections from 'organisms/InspirationSections';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import TabMenu from 'molecules/TabMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import Spinner from '@shared/atoms/Spinner';
import DepartmentIcon from 'molecules/DepartmentIcon';
var TabMenuWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 0.0625rem solid ", ";\n"], ["\n  border-bottom: 0.0625rem solid ", ";\n"])), palette('border'));
var Inspiration = /** @class */ (function (_super) {
    __extends(Inspiration, _super);
    function Inspiration(props) {
        var _this = _super.call(this, props) || this;
        _this.checkIfTabIsActive = function (tabTarget) {
            var _a = _this.props, inspiration = _a.inspiration, location = _a.location;
            var currentPath = '';
            if (Boolean(inspiration)) {
                var params = {
                    // $FlowIgnoreNextLine
                    priCat: inspiration.primaryCategoryId,
                };
                // $FlowIgnoreNextLine
                if (Boolean(inspiration.departmentId)) {
                    // $FlowIgnoreNextLine
                    params.department = inspiration.departmentId;
                }
                currentPath = location.pathname + "?" + stringify(params, {
                    arrayFormat: 'bracket',
                });
                return currentPath === tabTarget;
            }
            return false;
        };
        _this.getTabs = function () {
            var _a = _this.props, primaryCategories = _a.primaryCategories, session = _a.session, inspiration = _a.inspiration;
            return primaryCategories.reduce(function (acc, item) {
                var params = {
                    priCat: item.id,
                };
                // $FlowIgnoreNextLine
                if (Boolean(inspiration) && Boolean(inspiration.departmentId)) {
                    // $FlowIgnoreNextLine
                    params.department = inspiration.departmentId;
                }
                acc.push({
                    title: item.displayName,
                    target: "/" + session.uuid + "/inspiration?" + stringify(params, {
                        arrayFormat: 'bracket',
                    }),
                });
                return acc;
            }, []);
        };
        return _this;
    }
    Inspiration.prototype.getDepermentLink = function () {
        var _a = this.props, inspiration = _a.inspiration, session = _a.session;
        var params = {};
        if (Boolean(inspiration)) {
            // $FlowIgnoreNextLine
            params.priCat = inspiration.primaryCategoryId;
            // $FlowIgnoreNextLine
            if (Boolean(inspiration.departmentId)) {
                params.department = inspiration.departmentId;
            }
        }
        return "/" + session.uuid + "/departments?" + stringify(params, {
            arrayFormat: 'bracket',
        });
    };
    Inspiration.prototype.render = function () {
        var _this = this;
        var _a = this.props, deleteWatchlistItem = _a.deleteWatchlistItem, hasDepartments = _a.hasDepartments, history = _a.history, inspiration = _a.inspiration, inspirationsLoading = _a.inspirationsLoading, session = _a.session, title = _a.title, watchlist = _a.watchlist;
        var tabs = this.getTabs();
        return (_jsxs(SessionLayout, __assign({ deleteWatchlistItem: deleteWatchlistItem, session: session, subMenu: _jsx(SubMenu, { title: title, cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push("/" + session.uuid); }, fill: 'white', withText: true }, void 0), iconRight: hasDepartments ? (_jsx(DepartmentIcon, { action: function () { return history.push(_this.getDepermentLink()); } }, void 0)) : null }, void 0), watchlist: watchlist, backgroundColor: palette('white') }, { children: [_jsx(TabMenuWrapper, { children: _jsx(TabMenu, { tabs: tabs, checkIfTabIsActive: this.checkIfTabIsActive }, void 0) }, void 0), !inspiration && inspirationsLoading && _jsx(Spinner, {}, void 0), inspiration && inspiration.sections && !inspirationsLoading && (_jsx(InspirationSections, { sections: inspiration.sections, session: session }, inspiration.id))] }), void 0));
    };
    return Inspiration;
}(Component));
export default withRouter(Inspiration);
var templateObject_1;
