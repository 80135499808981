import store from "store";
var sign = store.get("currencySign") || "€";
var currency = store.get("currency") || "EUR";
var formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: currency,
});
export var formatCents = function (cents) {
    // dirty hack to temp fix provisioning errors
    var price = formatter.format(cents / 100);
    return price.substr(0, price.length - 3) + "," + price.substr(price.length - 2);
};
export var getPriceString = function (price) { return sign + " " + formatCents(price); };
export var getMinMaxPriceString = function (price) {
    var min = price.min, max = price.max;
    if (min === max) {
        return formatCents(min);
    }
    return formatCents(min) + " - " + formatCents(max);
};
export var getMinPriceString = function (prices) {
    if (prices && prices.min === prices.max) {
        return getPriceString(prices.min);
    }
    if (prices && prices.min) {
        return "ab " + getPriceString(prices.min);
    }
    return null;
};
export var hasDiscounts = function (current, target) {
    return current.min < target.min || current.max < target.max;
};
export var getCrossedPrice = function (current, target) {
    if (!hasDiscounts(current, target)) {
        return "";
    }
    return getMinPriceString(target);
};
