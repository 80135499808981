// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    currentLanguage: 'de',
});
var reducer = handleActions({
    SELECT_LANGUAGE: function (state) { return state; },
    SELECT_LANGUAGE_COMPLETE: {
        next: function (state, action) {
            return state.set('currentLanguage', action.payload);
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
