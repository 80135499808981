var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
export var Wrapper = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
export var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n"], ["\n  display: block;\n  width: 100%;\n"])));
export var ButtonRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: ", ";\n  bottom: 0;\n  display: flex;\n  flex: 1;\n  justify-content: ", ";\n  width: 100%;\n  padding: 0.625rem 0.9375rem;\n"], ["\n  align-items: ", ";\n  bottom: 0;\n  display: flex;\n  flex: 1;\n  justify-content: ", ";\n  width: 100%;\n  padding: 0.625rem 0.9375rem;\n"])), function (props) {
    return props.buttonPosition === 'left' ? 'flex-start' : 'flex-end';
}, function (props) {
    return props.buttonPosition === 'left' ? 'flex-start' : 'flex-end';
});
export var StyledButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('secondaryHighlight'), palette('white'), palette('activeWhite'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
