var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 50px;\n  min-height: 75%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 50px;\n  min-height: 75%;\n"])));
export var Main = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 10px;\n  background-color: white;\n  z-index: 50;\n  height:fit-content;\n  width: 100%;\n  outline: 2px solid ", ";\n  border-radius: 5px;\n"], ["\n  margin-top: 10px;\n  background-color: white;\n  z-index: 50;\n  height:fit-content;\n  width: 100%;\n  outline: 2px solid ", ";\n  border-radius: 5px;\n"])), palette('border'));
export var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding:0.625rem;\n  border-bottom:1px solid;\n"], ["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding:0.625rem;\n  border-bottom:1px solid;\n"])));
export var ItemBlock = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color : white;\n  margin:1.2rem 0.4rem;\n  \n"], ["\n  background-color : white;\n  margin:1.2rem 0.4rem;\n  \n"])));
export var ItemButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    text-align: center;\n    border: ", ";\n    border-radius: 5px;\n    height: 41px;\n    min-width: 5rem;\n    background-color: transparent;\n"], ["\n    text-align: center;\n    border: ", ";\n    border-radius: 5px;\n    height: 41px;\n    min-width: 5rem;\n    background-color: transparent;\n"])), palette('border'));
export var FilterConfirm = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position:relative;\n  background-color: ", ";\n  border: 1px black;\n  color: #fff;\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  margin: 0.3rem 0;\n  transition: background-color 0.2s ease;\n  width: 80%;\n  top:9rem;\n"], ["\n  position:relative;\n  background-color: ", ";\n  border: 1px black;\n  color: #fff;\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  margin: 0.3rem 0;\n  transition: background-color 0.2s ease;\n  width: 80%;\n  top:9rem;\n"])), palette('secondaryHighlight'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
