var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, fontWeight, key } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0;\n  position: relative;\n  &:first-child:not(:only-child) > div {\n    border-top: 0.06275rem solid transparent;\n  }\n  ", " &:active {\n    > div {\n      border-top: 0.06275rem solid ", ";\n    }\n    + div > div {\n      border-top: 0.06275rem solid ", ";\n    }\n  }\n"], ["\n  padding: 0;\n  position: relative;\n  &:first-child:not(:only-child) > div {\n    border-top: 0.06275rem solid transparent;\n  }\n  ", " &:active {\n    > div {\n      border-top: 0.06275rem solid ", ";\n    }\n    + div > div {\n      border-top: 0.06275rem solid ", ";\n    }\n  }\n"])), function (props) {
    return props.active &&
        "\n      + div > div {\n        border-top: 0.06275rem solid white;\n      }\n  ";
}, palette('activeWhite'), palette('activeWhite'));
export var InnerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  position: relative;\n  margin-top: 1rem;\n  align-items: center;\n  justify-content: center;\n  padding: 0.625rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  position: relative;\n  margin-top: 1rem;\n  align-items: center;\n  justify-content: center;\n  padding: 0.625rem;\n"])));
export var ThumbnailImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 320px;\n  height: 320px;\n  text-align: center;\n  @media screen and (max-width: ", ") {\n    width: 160px;\n    height: 160px;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 120px;\n    height: 120px;\n  }\n  img {\n    width: 100%;\n  }\n"], ["\n  position: relative;\n  width: 320px;\n  height: 320px;\n  text-align: center;\n  @media screen and (max-width: ", ") {\n    width: 160px;\n    height: 160px;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 120px;\n    height: 120px;\n  }\n  img {\n    width: 100%;\n  }\n"])), key('breakpoints.medium'), key('breakpoints.xsmall'));
export var Info = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-top: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  color: ", ";\n  line-height: 1.25rem;\n  width: 90%;\n  text-align: center;\n"], ["\n  padding-top: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  color: ", ";\n  line-height: 1.25rem;\n  width: 90%;\n  text-align: center;\n"])), palette('text'));
export var Headline = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-weight: ", ";\n  margin: 0;\n"])), palette('text'), fontWeight('bold'));
export var Description = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
