var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import localStore from 'store';
import PaypalMethod from 'molecules/PaypalMethod';
import paymentTypes from 'services/enums/paymentMethods';
import { getPriceString } from '@shared/utils/currency';
import { deliveryModes } from '@shared/utils/constants';
import { Wrapper, FullWidthRow, PaymentInfoAmount, PaymentInfoTitle, MessageWrapper, Separator, BranchWrapper, } from './styles';
var ClickAndCollect = /** @class */ (function (_super) {
    __extends(ClickAndCollect, _super);
    function ClickAndCollect(props) {
        var _this = _super.call(this, props) || this;
        _this.deviceId = localStore.get('deviceId');
        return _this;
    }
    ClickAndCollect.prototype.render = function () {
        var _a = this.props, sessionId = _a.sessionId, createSession = _a.createSession, cartPrice = _a.cartPrice, store = _a.store, theme = _a.theme;
        var paymentType = this.props.history.location.state.paymentType ||
            paymentTypes.clickAndCollect;
        var deliveryMode = (this.props.history.location.state &&
            this.props.history.location.state.deliveryMode) ||
            null;
        var currency = localStore.get('currency') || 'EUR';
        var sign = localStore.get('currencySign') || '€';
        var shipping = parseFloat(theme.shipping) || 0;
        if (deliveryMode === deliveryModes.MANUFACTURE_ORDER ||
            paymentType === paymentTypes.clickAndCollect) {
            shipping = 0.0;
        }
        var clientId = this.props.theme.paypal.clientId;
        return (_jsxs(Wrapper, { children: [_jsx(PaymentInfoTitle, { children: _jsx(FormattedMessage, { defaultMessage: 'Preis des Warenkorbs', id: 'clickAndCollect.basketPrice' }, void 0) }, void 0), _jsx(PaymentInfoAmount, { children: getPriceString(cartPrice) }, void 0), _jsx(PaymentInfoTitle, { children: _jsx(FormattedMessage, { defaultMessage: 'Versand', id: 'clickAndCollect.shipping' }, void 0) }, void 0), _jsx(PaymentInfoAmount, { children: getPriceString(shipping) }, void 0), _jsx(PaymentInfoTitle, { children: _jsx(FormattedMessage, { defaultMessage: 'GesamteSumme', id: 'clickAndCollect.totalPrice' }, void 0) }, void 0), _jsx(PaymentInfoAmount, { children: getPriceString(shipping + cartPrice) }, void 0), this.props.history.location.state.paymentType ===
                    paymentTypes.clickAndCollect && (_jsxs(React.Fragment, { children: [_jsx(FullWidthRow, { children: _jsxs(BranchWrapper, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { defaultMessage: 'Abholfiliale', id: 'clickAndCollect.pickupBranch' }, void 0) }, void 0), store && _jsx("p", { children: store.name }, void 0), store && _jsx("p", { children: store.street }, void 0), store && _jsx("p", { children: store.city + " " + store.zip }, void 0)] }, void 0) }, void 0), _jsx(MessageWrapper, { children: _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: 'Wir werden dich benachrichtigen, sobald die Ware zur Abholung\n            bereitsteht', id: 'clickAndCollect.notifyMsg' }, void 0) }, void 0) }, void 0)] }, void 0)), _jsx(Separator, {}, void 0), _jsx(MessageWrapper, { children: _jsx(FormattedMessage, { defaultMessage: 'Jetzt Bezahlen', id: 'clickAndCollect.payNow' }, void 0) }, void 0), _jsx(MessageWrapper, { children: _jsx(PaypalMethod, { sessionId: sessionId, deviceId: this.deviceId, clientId: clientId, currency: currency, createSession: createSession, redirectAfterCheckout: true, paymentType: paymentType, deliveryMode: deliveryMode }, void 0) }, void 0), _jsx(MessageWrapper, { children: _jsx(FormattedMessage, { defaultMessage: 'Du wirst an Paypal weitergeleitet, um den Bezahlvorgang abzuschlie\u00DFen.', id: 'clickAndCollect.paypalRedirectMsg' }, void 0) }, void 0)] }, void 0));
    };
    return ClickAndCollect;
}(Component));
export default withTheme(withRouter(injectIntl(ClickAndCollect)));
