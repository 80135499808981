var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-template-columns: repeat(7, 1fr);\n  padding-top: 3.5rem;\n  justify-items: stretch;\n"], ["\n  background: ", ";\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-template-columns: repeat(7, 1fr);\n  padding-top: 3.5rem;\n  justify-items: stretch;\n"])), palette('white'));
export var FullWidthRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 3 / 6;\n"], ["\n  grid-column: 3 / 6;\n"])));
export var PaymentInfoTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 1/ 5;\n  width: 100%;\n  text-align: right;\n"], ["\n  grid-column: 1/ 5;\n  width: 100%;\n  text-align: right;\n"])));
export var PaymentInfoAmount = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-column: 5/ 8;\n  padding-left: 2rem;\n"], ["\n  grid-column: 5/ 8;\n  padding-left: 2rem;\n"])));
export var BranchWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 2.5rem;\n  text-align: center;\n  border-top: 2px solid #000;\n  border-bottom: 2px solid #000;\n"], ["\n  margin-top: 2.5rem;\n  text-align: center;\n  border-top: 2px solid #000;\n  border-bottom: 2px solid #000;\n"])));
export var MessageWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  grid-column: 2 / 7;\n  padding-top: 2rem;\n  text-align: center;\n"], ["\n  grid-column: 2 / 7;\n  padding-top: 2rem;\n  text-align: center;\n"])));
export var Separator = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  grid-column: 1 / 8;\n  height: 2px;\n  background-color: #000;\n  margin-top: 2rem;\n"], ["\n  grid-column: 1 / 8;\n  height: 2px;\n  background-color: #000;\n  margin-top: 2rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
