var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.625rem;\n  padding: 0 0.625rem;\n"], ["\n  margin-top: 0.625rem;\n  padding: 0 0.625rem;\n"])));
export var ContentWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  font-size: ", ";\n  justify-content: center;\n  min-height: 5.625rem;\n  opacity: ", ";\n  padding: 0.3125rem 0.5rem;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);\n  }\n"], ["\n  align-items: center;\n  color: ", ";\n  font-size: ", ";\n  justify-content: center;\n  min-height: 5.625rem;\n  opacity: ", ";\n  padding: 0.3125rem 0.5rem;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);\n  }\n"])), palette('text'), fontSize('default'), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
export var DeleteIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #000;\n  opacity: ", ";\n  padding: 0.5rem;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  color: #000;\n  opacity: ", ";\n  padding: 0.5rem;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
export var ChangeIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #000;\n  opacity: ", ";\n  padding: 0.5rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  color: #000;\n  opacity: ", ";\n  padding: 0.5rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
export var Image = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex: 1;\n  font-size: 0;\n  justify-content: center;\n  margin-right: 0.625rem;\n  max-width: 35%;\n  position: relative;\n  img {\n    max-width: 100%;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  flex: 1;\n  font-size: 0;\n  justify-content: center;\n  margin-right: 0.625rem;\n  max-width: 35%;\n  position: relative;\n  img {\n    max-width: 100%;\n    width: 100%;\n  }\n"])));
export var Info = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  font-weight: ", ";\n  padding-right: 1.125rem;\n"], ["\n  flex: 1;\n  font-weight: ", ";\n  padding-right: 1.125rem;\n"])), fontWeight('light'));
export var Bold = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
export var Tag = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
export var ItemContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  border-bottom: 2px solid ", ";\n"], ["\n  position: relative;\n  border-bottom: 2px solid ", ";\n"])), palette('border'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
