import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
// langs
import en from '../../lang/en.json';
import de from '../../lang/de.json';
var translations = {
    de: de,
    en: en,
};
function mapStateToProps(state) {
    var selectedLanguage = state.get('intl').get('currentLanguage');
    var messages = translations[selectedLanguage];
    return { locale: selectedLanguage, messages: messages, key: selectedLanguage };
}
export default connect(mapStateToProps)(IntlProvider);
