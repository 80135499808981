var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
import Icon from "@shared/atoms/Icon";
var Wrapper = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  align-self: flex-end;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  outline: none;\n  height: 100%;\n  min-width: 4.375rem;\n"], ["\n  align-items: center;\n  align-self: flex-end;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  outline: none;\n  height: 100%;\n  min-width: 4.375rem;\n"])));
var BackIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { return (props.width ? props.width : "1rem"); });
var Text = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.9rem;\n  color: ", ";\n"], ["\n  font-size: 0.9rem;\n  color: ", ";\n"])), palette("white"));
var BackButtonSmall = /** @class */ (function (_super) {
    __extends(BackButtonSmall, _super);
    function BackButtonSmall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BackButtonSmall.prototype.render = function () {
        var _a = this.props, action = _a.action, customIcon = _a.customIcon, width = _a.width, withText = _a.withText, fill = _a.fill;
        return (_jsxs(Wrapper, __assign({ "data-cy-id": "back-button", onClick: action }, { children: [_jsx(BackIcon, { icon: customIcon || "back", width: width, fill: fill }, void 0), withText && _jsx(Text, { children: "Zur\u00FCck" }, void 0)] }), void 0));
    };
    return BackButtonSmall;
}(Component));
BackButtonSmall.defaultProps = {
    fill: palette("iconColor"),
};
/** @component */
export { BackButtonSmall };
var templateObject_1, templateObject_2, templateObject_3;
