var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { PaypalButtons } from 'atoms/PaypalButtons';
import { PaypalButtonWrapper } from './styles';
import { withRouter } from 'react-router-dom';
import paymentTypes from 'services/enums/paymentMethods';
import { injectIntl, defineMessages } from 'react-intl';
var paypalErrorMsg = defineMessages({
    paypalErrorMsg: {
        defaultMessage: "Es ist ein Fehler aufgetreten: {errorText}.<br /> Bitte w\u00E4hle eine andere Zahlungsart oder kontaktiere den Kundendienst",
        id: 'checkout.paypal.error',
    },
}).paypalErrorMsg;
var PaypalMethod = /** @class */ (function (_super) {
    __extends(PaypalMethod, _super);
    function PaypalMethod(props) {
        var _this = _super.call(this, props) || this;
        //TODO - remove the props and remove the window..location to have on theme for the whole project
        _this.onCheckout = function () {
            var _a = _this.props, deviceId = _a.deviceId, sessionId = _a.sessionId, createSession = _a.createSession, redirectAfterCheckout = _a.redirectAfterCheckout, history = _a.history;
            createSession(deviceId);
            if (redirectAfterCheckout) {
                history.push("/" + sessionId + "/thank-you");
            }
            else {
                window.location.href = "/checkout?" + deviceId;
            }
        };
        _this.state = {
            paypalError: false,
            paypalErrorText: null,
        };
        _this.createOrder = _this.createOrder.bind(_this);
        _this.onCheckout = _this.onCheckout.bind(_this);
        _this.afterApprove = _this.afterApprove.bind(_this);
        return _this;
    }
    PaypalMethod.prototype.createOrder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, deviceId, sessionId, paymentType, deliveryMode, apiUrl, token, res, orderData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, deviceId = _a.deviceId, sessionId = _a.sessionId, paymentType = _a.paymentType, deliveryMode = _a.deliveryMode;
                        apiUrl = process.env.REACT_APP_API_URL;
                        token = btoa("device;needle:" + deviceId);
                        return [4 /*yield*/, fetch(apiUrl + "paypal/order/" + sessionId, {
                                method: 'post',
                                headers: {
                                    'content-type': 'application/json',
                                    authorization: "Basic " + token,
                                },
                                body: JSON.stringify({ paymentType: paymentType, delivery_mode: deliveryMode }),
                            })];
                    case 1:
                        res = _b.sent();
                        if (res.status !== 200) {
                            throw res.statusText;
                        }
                        return [4 /*yield*/, res.json()];
                    case 2:
                        orderData = _b.sent();
                        return [2 /*return*/, orderData.id]; // Use the same key name for order ID on the client and server
                }
            });
        });
    };
    PaypalMethod.prototype.afterApprove = function (orderInfo) {
        return __awaiter(this, void 0, void 0, function () {
            var orderId, _a, deviceId, sessionId, paymentType, deliveryMode, token, apiUrl;
            var _this = this;
            return __generator(this, function (_b) {
                orderId = orderInfo.orderID;
                _a = this.props, deviceId = _a.deviceId, sessionId = _a.sessionId, paymentType = _a.paymentType, deliveryMode = _a.deliveryMode;
                token = btoa("device;needle:" + deviceId);
                apiUrl = process.env.REACT_APP_API_URL;
                return [2 /*return*/, fetch(apiUrl + "paypal/capture/" + orderId, {
                        method: 'post',
                        headers: {
                            'content-type': 'application/json',
                            authorization: "Basic " + token,
                        },
                        body: JSON.stringify({
                            orderID: orderId,
                            paymentType: paymentType,
                            delivery_mode: deliveryMode,
                        }),
                    })
                        .then(function (res) {
                        if (res.status !== 200) {
                            _this.setState({ paypalError: true, paypalErrorText: res.statusText });
                            throw res.statusText;
                        }
                        return res;
                    })
                        .then(function (res) {
                        return res.json();
                    })
                        .then(function (details) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, fetch(apiUrl + "sessions/" + sessionId + "/archive", {
                                        method: 'put',
                                        headers: {
                                            'content-type': 'application/json',
                                            authorization: "Basic " + token,
                                        },
                                    })];
                                case 1:
                                    _a.sent();
                                    this.orderData = {};
                                    this.onCheckout();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    PaypalMethod.prototype.render = function () {
        var _a = this.props, sessionId = _a.sessionId, clientId = _a.clientId, currency = _a.currency, intl = _a.intl;
        var _b = this.state, paypalError = _b.paypalError, paypalErrorText = _b.paypalErrorText;
        return (_jsxs(React.Fragment, { children: [!paypalError && (_jsx(PaypalButtonWrapper, { children: clientId && (_jsx(PaypalButtons, { clientId: clientId, currency: currency, createOrder: this.createOrder, onApprove: this.afterApprove, sessionId: sessionId }, void 0)) }, void 0)), paypalError && (_jsx("p", __assign({ style: { color: 'red' } }, { children: intl.formatMessage(paypalErrorMsg, {
                        errorText: paypalErrorText,
                    }) }), void 0))] }, void 0));
    };
    return PaypalMethod;
}(Component));
PaypalMethod.defaultProps = {
    redirectAfterCheckout: false,
    paymentType: paymentTypes.clickAndDeliver,
    deliveryMode: 'null',
};
export default injectIntl(withRouter(PaypalMethod));
