// @flow
import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    loading: false,
    loaded: false,
    loadedAll: false,
    loadingAll: false,
});
var updateFilter = function (filter) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (existingFilter) { return existingFilter.get('name') === filter.get('name'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(filter.set('id', filter.get('id')));
    }
    // $FlowIgnoreNextLine
    return existing.set(index, filter.set('id', filter.get('id')));
}; };
// $FlowIgnoreNextLine
export var updateFilters = function (filters) { return function (existing) {
    var newFilters = existing;
    filters.forEach(function (filter) {
        newFilters = newFilters.update(updateFilter(filter));
    });
    return newFilters;
}; };
var reducer = handleActions({
    GET_FILTERS: function (state) { return state.set('loadingAll', true); },
    GET_FILTERS_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', updateFilters(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    GET_FILTER: function (state) { return state.set('loading', true); },
    GET_FILTER_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateFilter(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
}, initialState);
export default reducer;
