var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import history from '@store/history';
import SessionContainer from 'containers/SessionContainer';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import CancelButton from '@shared/molecules/CancelButton';
import { MatchAndFit } from '@shared/atoms/MatchAndFit';
var MatchAndFitIntegration = function (props) {
    var intl = useIntl();
    // eslint-disable-next-line
    var sessionId = props.match.params.id;
    var theme = props.theme;
    var target = "/" + sessionId + "/search";
    var handler = function () {
        history.push(target);
    };
    var matchAndFitUrl = "https://feet.matchandfit.com/loqa/" + theme.customerId + "?loqa=etosIframe&v=1";
    var matchAndFitOptions = 'm=2';
    var msg = defineMessages({
        feetMeasurement: {
            defaultMessage: 'Fußvermessung',
            id: 'feetMeasurement.title',
        },
    });
    return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(SessionLayout, __assign({}, sessionProps, { subMenu: _jsx(SubMenu
            // eslint-disable-next-line
            , { 
                // eslint-disable-next-line
                title: intl.formatMessage(msg.feetMeasurement), cancelIcon: _jsx(CancelButton, { action: function () { return history.push("/" + sessionId); }, fill: 'white', customIcon: 'arrow-previous' }, void 0) }, void 0) }, { children: _jsx(MatchAndFit, { handler: handler, matchAndFitUrl: matchAndFitUrl, matchAndFitOptions: matchAndFitOptions }, void 0) }), void 0)); } }, void 0));
};
export default withTheme(withRouter(MatchAndFitIntegration));
