var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
// components
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import ClickAndCollectView from 'organisms/ClickAndCollect';
import Spinner from '@shared/atoms/Spinner';
import paymentTypes from 'services/enums/paymentMethods';
var ClickAndCollect = /** @class */ (function (_super) {
    __extends(ClickAndCollect, _super);
    function ClickAndCollect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClickAndCollect.prototype.render = function () {
        var _a = this.props, session = _a.session, history = _a.history, createSession = _a.createSession, cartPrice = _a.cartPrice, isThemeLoading = _a.isThemeLoading;
        return (_jsx(SessionLayout, __assign({ session: session, backgroundColor: palette('white'), subMenu: _jsx(SubMenu, { cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push("/" + session.uuid); }, fill: 'white', withText: true }, void 0), title: history.location.state.paymentType ===
                    paymentTypes.clickAndCollect
                    ? 'Click & Collect'
                    : 'Click & Deliver' }, void 0), hideBottomMenu: true }, { children: isThemeLoading ? (_jsx(Spinner, {}, void 0)) : (_jsx(ClickAndCollectView, { store: session.store, sessionId: session.uuid, createSession: createSession, cartPrice: cartPrice }, void 0)) }), void 0));
    };
    return ClickAndCollect;
}(Component));
export default withRouter(injectIntl(ClickAndCollect));
