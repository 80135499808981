var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils & libs
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toJS } from '@shared/utils/immutable';
import { parse } from 'query-string';
// selectors
import { selectAll as selectDepartments, selectShouldLoadAll as selectShouldLoadDepartments, } from '@store/department/selectors';
// actions
import { getDepartments } from '@store/department/actions';
// components & container
import SessionContainer from 'containers/SessionContainer';
import Page from 'components/pages/Department';
var Department = /** @class */ (function (_super) {
    __extends(Department, _super);
    function Department() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Department.prototype.componentDidMount = function () {
        var shouldLoadDepartments = this.props.shouldLoadDepartments;
        if (shouldLoadDepartments) {
            this.props.getDepartments();
        }
    };
    Department.prototype.render = function () {
        var _a = this.props, departments = _a.departments, location = _a.location;
        var params = parse(location.search, {
            arrayFormat: 'bracket',
        });
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { departments: departments, params: params }), void 0)); } }, void 0));
    };
    return Department;
}(Component));
var select = function () { return function (state) { return ({
    departments: selectDepartments(state),
    shouldLoadDepartments: selectShouldLoadDepartments(state),
}); }; };
export default withRouter(connect(select, { getDepartments: getDepartments })(toJS(Department)));
