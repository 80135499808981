var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useEffect } from 'react';
// utils and libs
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { toJS } from '@shared/utils/immutable';
// actions
import { createSession, getSession } from '@store/session/actions';
import { getWatchlist, deleteWatchlistItem, deleteWatchlist, } from '@store/watchlist/actions';
// selectors
import { makeSelectSession, selectLoaded as sessionSelectLoaded, selectLoading as sessionSelectLoading, } from '@store/session/selectors';
import { selectWatchlist, selectWatchlistError, } from '@store/watchlist/selectors';
// components
import PageSpinner from '@shared/atoms/PageSpinner';
import ModalText from '@shared/atoms/ModalText';
import Error from '@shared/molecules/ConnectionError';
import { getUUID } from '@shared/utils/auth';
import localStore from 'store';
import useDidUpdate from 'src/hooks/useDidUpdate';
var SessionContainer = function (_a) {
    var deleteWatchlistItem = _a.deleteWatchlistItem, loaded = _a.loaded, loading = _a.loading, createSession = _a.createSession, children = _a.children, getWatchlist = _a.getWatchlist, session = _a.session, watchlist = _a.watchlist, watchlistError = _a.watchlistError;
    var history = useHistory();
    var params = useParams();
    var intl = useIntl();
    useEffect(function () {
        var deviceId = getUUID(window.location.search) || localStore.get('deviceId');
        localStore.remove('enteredFromScan');
        if (params.id) {
            localStore.set('enteredFromScan', true);
        }
        var id = params.id || localStore.get('sessionId');
        if (!loading && !loaded) {
            getSession({ deviceId: deviceId, sessionId: id });
        }
        getWatchlist(id);
        return function () {
            localStore.remove('enteredFromScan');
        };
    }, [getWatchlist, loaded, loading, params]);
    useDidUpdate(function () {
        var stayHere = localStore.get('posCheckoutNotConfirmed');
        if (!history.location.pathname.includes('thank-you') &&
            !stayHere &&
            (session === null || session === void 0 ? void 0 : session.uuid)) {
            history.push("/" + session.uuid);
            getWatchlist(session.uuid);
        }
    }, [history, session === null || session === void 0 ? void 0 : session.uuid]);
    var showWatchlistError = watchlistError;
    if (showWatchlistError) {
        return (_jsx(Error, __assign({ onClick: function () {
                createSession(session.deviceId);
            } }, { children: _jsx(ModalText, { children: intl.formatMessage({
                    defaultMessage: 'Die Customer App erlaubt aktuell keine Artikel mit unterschiedlichen Bestandsarten. Klicke auf diese Meldung, um zur Startseite zu gelangen.',
                    id: "" + watchlistError.message,
                }) }, void 0) }), void 0));
    }
    else if (Boolean(session)) {
        return children({
            session: session,
            watchlist: watchlist,
            deleteWatchlistItem: function (articleId) {
                deleteWatchlistItem(articleId, session.uuid);
            },
            deleteWatchlist: function (sessionId) {
                deleteWatchlist(sessionId);
            },
        });
    }
    return _jsx(PageSpinner, {}, void 0);
};
var select = function () { return function (state, props) { return ({
    loaded: sessionSelectLoaded(state),
    loading: sessionSelectLoading(state),
    session: makeSelectSession()(state, props),
    watchlist: selectWatchlist(state),
    watchlistError: selectWatchlistError(state),
}); }; };
export var Container = SessionContainer;
export default connect(select, {
    createSession: createSession,
    getSession: getSession,
    getWatchlist: getWatchlist,
    deleteWatchlistItem: deleteWatchlistItem,
    deleteWatchlist: deleteWatchlist,
})(toJS(SessionContainer));
