var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CancelButton from '@shared/molecules/CancelButton';
var GoBack = /** @class */ (function (_super) {
    __extends(GoBack, _super);
    function GoBack() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GoBack.prototype.render = function () {
        var _a = this.props, history = _a.history, icon = _a.icon, action = _a.action;
        return (_jsx(CancelButton, { customIcon: icon, action: action || history.goBack, fill: 'white' }, void 0));
    };
    return GoBack;
}(Component));
export default withRouter(GoBack);
