var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import CancellablePromise from '../../../utils/cancellablePromise';
var Canvas = function (_a) {
    var lib = _a.lib, onScan = _a.onScan, handleCameraAccess = _a.handleCameraAccess;
    var videoRef = useRef(null);
    var getResult = function (result) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!(Object.keys(result).length > 0 &&
                        Object.keys(result.results).length !== 0)) return [3 /*break*/, 2];
                    onScan(result.results[0].barcodeData);
                    return [4 /*yield*/, lib.CDCamera.stopPreview()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1, 'get result error');
                    handleCameraAccess(false);
                    lib.CDCamera.stopPreview();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getActiveCameraId = function (cameras) {
        var cameraId = null;
        if (cameras.length) {
            var backCamera = cameras.find(function (camera) {
                return camera.label.toLowerCase().includes('back') ||
                    camera.label.toLowerCase().includes('rück');
            });
            cameraId = backCamera ? backCamera.id : cameras[0].id;
        }
        return cameraId;
    };
    useEffect(function () {
        var initVideoPromise = lib.CDCamera.init(videoRef.current);
        // this is a pattern for unmounting the component and stop any async process
        // recommended by react team https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
        var _a = CancellablePromise(initVideoPromise), promise = _a.promise, cancel = _a.cancel;
        promise
            .then(function (res) {
            try {
                if (lib && lib.CDSymbology && lib.CDSymbology.QR) {
                    lib.CDSymbology.QR.enable = true;
                }
                if (lib && lib.CDSymbology && lib.CDSymbology.UPCA) {
                    lib.CDSymbology.UPCA.enable = true;
                    lib.CDSymbology.UPCA.enableConvertToEAN13 = true;
                }
            }
            catch (error) {
                console.log(error);
            }
            var cameraId = getActiveCameraId(lib.CDCamera.getConnectedCameras());
            if (null === cameraId) {
                console.log('no Camera id');
                handleCameraAccess(false);
            }
            else {
                lib.CDCamera.setCamera(cameraId);
                // check if there is a result then stop the camera and file the onScan prop
                lib.CDCamera.getPreview(getResult).then(function () { });
            }
        })
            .catch(function (err) {
            console.log('error occured in use effect', err);
            handleCameraAccess(false);
            lib.CDCamera.stopPreview();
        });
        return cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", { children: _jsx("div", __assign({ id: 'videoContainer', style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '1rem',
            } }, { children: _jsx("video", { style: {
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    width: '400px',
                    height: '170px',
                }, id: 'video', ref: videoRef, playsInline: true }, void 0) }), void 0) }, void 0));
};
export default Canvas;
