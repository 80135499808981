var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Centered, Image, ImageContainer, Wrapper } from './styles';
import queryString from 'query-string';
var CategoryGridItem = function (_a) {
    var basePath = _a.basePath, category = _a.category, primaryCategoryId = _a.primaryCategoryId;
    var getTargetLink = function () {
        var target = basePath + "/results";
        var queryParams = {
            category: category.id,
            primaryCategory: primaryCategoryId,
        };
        var currentQueryParams = queryString.parse(window.location.search);
        var newQueryParams = __assign(__assign({}, currentQueryParams), queryParams);
        var queryStringified = queryString.stringify(newQueryParams);
        return target + "?" + queryStringified;
    };
    var image = (category === null || category === void 0 ? void 0 : category.image) || 'https://storage.googleapis.com/c91b2bb74b7db747a90a1741add6745f/needle_images/app/fallback.svg';
    return (_jsxs(Wrapper, __assign({ to: getTargetLink() }, { children: [_jsx(ImageContainer, { children: _jsx(Image, { alt: category.displayName, src: image }, void 0) }, void 0), _jsx(Centered, { children: category.displayName }, void 0)] }), void 0));
};
export default CategoryGridItem;
