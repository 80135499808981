var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import TilesSmall from 'molecules/TilesSmall';
import sortBy from 'lodash/sortBy';
import TilesBig from 'molecules/TilesBig';
import Banner from 'molecules/Banner';
import { Link } from 'react-router-dom';
import SimpleBanner from 'molecules/SimpleBanner';
import ArticleSlider from 'molecules/ArticleSlider';
import { Wrapper, Image, Section, SectionBig } from './styles';
var InspirationSections = /** @class */ (function (_super) {
    __extends(InspirationSections, _super);
    function InspirationSections() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InspirationSections.prototype.render = function () {
        var _a = this.props, sections = _a.sections, session = _a.session;
        return (_jsx(Wrapper, { children: sortBy(sections, 'sort').map(function (section) {
                if (section.type === 'banner' && Boolean(section.imageUrlMobile)) {
                    return (_jsx(SectionBig, { children: _jsx(Banner, { buttonPosition: section.buttonPosition || null, buttonText: section.buttonText, imageUrl: section.imageUrlMobile, targetUrl: "/" + session.uuid + section.targetUrl }, void 0) }, section.sort));
                }
                if (section.type === 'simpleBanner' &&
                    Boolean(section.imageUrlMobile)) {
                    return (_jsx(SectionBig, { children: _jsx(SimpleBanner, { imageUrl: section.imageUrlMobile, targetUrl: "/" + session.uuid + section.targetUrl }, void 0) }, section.sort));
                }
                if (section.type === 'tilesSmall') {
                    return (_jsx(Section, { children: _jsx(TilesSmall, { bannerUrl: section.bannerUrl, session: session, tiles: section.items }, void 0) }, section.sort));
                }
                if (section.type === 'tilesBig') {
                    return (_jsx(Section, { children: _jsx(TilesBig, { tiles: section.items, session: session }, void 0) }, section.sort));
                }
                if (section.type === 'articleSlider') {
                    return (_jsx(Section, { children: _jsx(ArticleSlider, { articles: section.items, headline: section.headline, session: session }, void 0) }, section.sort));
                }
                if (section.type === 'image' && Boolean(section.imageUrlMobile)) {
                    if (!section.targetUrl) {
                        return (_jsx(Section, { children: _jsx(Image, { src: section.imageUrlMobile }, void 0) }, section.sort));
                    }
                    return (_jsx(Section, { children: _jsx(Link, __assign({ to: "/" + session.uuid + section.targetUrl }, { children: _jsx(Image, { src: section.imageUrl }, void 0) }), void 0) }, section.sort));
                }
                return null;
            }) }, void 0));
    };
    return InspirationSections;
}(Component));
export default InspirationSections;
