var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import { withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
// components
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import ThankYouView from 'organisms/ThankYou';
var ThankYou = /** @class */ (function (_super) {
    __extends(ThankYou, _super);
    function ThankYou() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ThankYou.prototype.render = function () {
        var _a = this.props, intl = _a.intl, session = _a.session, history = _a.history;
        var msg = defineMessages({
            article: { defaultMessage: 'Vielen Dank', id: 'thankyou.page.headline' },
        });
        return (_jsx(SessionLayout, __assign({ session: session, backgroundColor: palette('white'), subMenu: _jsx(SubMenu, { cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push("/" + session.uuid); }, fill: 'white', withText: true }, void 0), title: intl.formatMessage(msg.article) }, void 0) }, { children: _jsx(ThankYouView, { session: session }, void 0) }), void 0));
    };
    return ThankYou;
}(Component));
export default withRouter(injectIntl(ThankYou));
