var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Icon from '@shared/atoms/Icon';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      max-height: 4.125rem;\n      max-width: 4.125rem;\n      left: 0;\n      padding: 0.625rem;\n      position: absolute;\n      top: 0;\n      z-index: 1;\n    "], ["\n      max-height: 4.125rem;\n      max-width: 4.125rem;\n      left: 0;\n      padding: 0.625rem;\n      position: absolute;\n      top: 0;\n      z-index: 1;\n    "])));
var Badge = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: auto;\n  width: 100%;\n"], ["\n  height: auto;\n  width: 100%;\n"])));
var DiscountBadgeMedium = function () { return (_jsx(Wrapper, __assign({ className: "discount-badge-wrapper" }, { children: _jsx(Badge, { icon: 'discountMedium' }, void 0) }), void 0)); };
export default DiscountBadgeMedium;
var templateObject_1, templateObject_2;
