var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '@shared/atoms/Icon';
import { Wrapper, Headline, TextWrapper, PropWrapper, CancelIcon, DrillDownIcon, DescriptionToggle, DescriptionWrapper, } from './styles';
var ArticleDescription = function (_a) {
    var articleNumber = _a.articleNumber, description = _a.description, fabart = _a.fabart;
    var _b = useState(false), showDescription = _b[0], setShowDescription = _b[1];
    var toggleDescription = function (checkVisibility) {
        if (checkVisibility === void 0) { checkVisibility = false; }
        if (checkVisibility && showDescription) {
            return;
        }
        setShowDescription(!showDescription);
    };
    return (_jsxs(Wrapper, { children: [showDescription && (_jsx(Headline, __assign({ onClick: function () { return toggleDescription(); } }, { children: _jsx(FormattedMessage, { id: 'article.description.description', defaultMessage: 'Mehr Informationen' }, void 0) }), void 0)), !showDescription && (_jsxs(DescriptionToggle, __assign({ onClick: function () { return toggleDescription(true); } }, { children: [_jsx(FormattedMessage, { id: 'article.description.description', defaultMessage: 'Mehr Informationen' }, void 0), ' ', _jsx(DrillDownIcon, { icon: 'drilldown' }, void 0)] }), void 0)), showDescription && (_jsxs(Fragment, { children: [_jsxs(TextWrapper, { children: [_jsx("span", { children: "Artikelnummer:" }, void 0), _jsx(PropWrapper, { children: _jsx("span", { children: articleNumber }, void 0) }, void 0), _jsx("span", { children: "Fabrikartikelnummer:" }, void 0), _jsx(PropWrapper, { children: _jsx("span", { children: fabart }, void 0) }, void 0)] }, void 0), _jsxs("div", { children: ["Mehr zu diesem Produkt", _jsx(DescriptionWrapper, { children: description }, void 0)] }, void 0), _jsx(CancelIcon, __assign({ onClick: function () { return toggleDescription(); } }, { children: _jsx(Icon, { icon: 'closex', width: 12 }, void 0) }), void 0)] }, void 0))] }, void 0));
};
export default ArticleDescription;
