var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ClickAndXItem, ClickAndXItems, ClickAndXWrapper } from './styles';
import localStore from 'store';
import { deliveryModes } from '@shared/utils/constants';
import paymentTypes from 'services/enums/paymentMethods';
import ClickAndDeliverButtonWrapper from "molecules/ClickAndXList/ClickAndDeliverButtonWrapper";
var ClickAndXList = /** @class */ (function (_super) {
    __extends(ClickAndXList, _super);
    function ClickAndXList(props) {
        var _this = _super.call(this, props) || this;
        _this.handleBuyingChange = _this.handleBuyingChange.bind(_this);
        return _this;
    }
    ClickAndXList.prototype.handleBuyingChange = function () {
        this.props.setIsBuying(true);
    };
    ClickAndXList.prototype.render = function () {
        var _a, _b;
        var _c = this.props, history = _c.history, sessionId = _c.sessionId, isBuying = _c.isBuying, handlePosCheckout = _c.handlePosCheckout, theme = _c.theme, watchlist = _c.watchlist;
        var watchlistStatus = localStore.get('watchlistStatus');
        var deliveryMode = watchlist[0].article.deliveryMode;
        var showClickAndReserve = Boolean(theme.clickAndReserve);
        var showClickAndDeliver = isBuying && theme.clickAndDeliver && ((_a = theme.paypal) === null || _a === void 0 ? void 0 : _a.clientId);
        if (watchlistStatus === 'orderable' && Array.isArray(deliveryMode)) {
            showClickAndDeliver = deliveryMode.includes(deliveryModes.DROPSHIPPING);
            showClickAndReserve = deliveryMode.includes(deliveryModes.MANUFACTURE_ORDER);
        }
        return (_jsx(React.Fragment, { children: _jsx(ClickAndXWrapper, { children: _jsxs(ClickAndXItems, { children: [!isBuying && 'orderable' !== watchlistStatus && (_jsxs(ClickAndXItem, __assign({ onClick: this.handleBuyingChange }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: "Kaufen" }), void 0), _jsx("img", { src: '/icons/icon_clickncollect.svg', height: '50px', alt: 'clickncollect' }, void 0)] }), void 0)), !isBuying &&
                            showClickAndReserve &&
                            watchlistStatus !== 'orderable' && (_jsxs(ClickAndXItem, __assign({ onClick: function () {
                                return history.push("/" + sessionId + "/click-and-reserve");
                            } }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: _jsx(FormattedMessage, { id: 'clickAndX.reserve', defaultMessage: 'Reservieren' }, void 0) }), void 0), _jsx("img", { src: '/icons/icon_clicknreserve.svg', height: '50px', alt: 'clicknreserve' }, void 0)] }), void 0)), !isBuying &&
                            showClickAndReserve &&
                            watchlistStatus === 'orderable' && (_jsxs(ClickAndXItem, __assign({ onClick: function () {
                                return history.push("/" + sessionId + "/click-and-reserve", {
                                    deliveryMode: deliveryModes.MANUFACTURE_ORDER,
                                });
                            } }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: _jsx(FormattedMessage, { id: 'clickAndX.reserve', defaultMessage: 'Reservieren' }, void 0) }), void 0), _jsx("img", { src: '/icons/icon_clicknreserve.svg', height: '50px', alt: 'clicknreserve' }, void 0)] }), void 0)), _jsxs(ClickAndXItem, __assign({ style: { display: 'none' } }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: _jsx(FormattedMessage, { id: 'clickAndX.click.and.meet', defaultMessage: 'Click & Meet' }, void 0) }), void 0), _jsx("img", { src: '/icons/icon_clicknmeet.svg', height: '50px', alt: 'clicknmeet' }, void 0)] }), void 0), isBuying && theme.clickAndCollect && ((_b = theme.paypal) === null || _b === void 0 ? void 0 : _b.clientId) && (_jsxs(ClickAndXItem, __assign({ onClick: function () {
                                return history.push("/" + sessionId + "/click-and-collect", {
                                    paymentType: paymentTypes.clickAndCollect,
                                });
                            } }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: _jsx(FormattedMessage, { id: 'clickAndX.basket.pick', defaultMessage: 'Ware Abholen' }, void 0) }), void 0), _jsx("img", { src: '/icons/icon_clickncollect.svg', height: '50px', alt: 'clickncollect' }, void 0)] }), void 0)), showClickAndDeliver && _jsx(ClickAndDeliverButtonWrapper, { history: history, sessionId: sessionId, shippingCosts: theme.shipping }, void 0), isBuying && (_jsxs(ClickAndXItem, __assign({ onClick: function () { return handlePosCheckout(); } }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: _jsx(FormattedMessage, { id: 'clickAndX.pos', defaultMessage: 'Du bist vor Ort' }, void 0) }), void 0), _jsx("img", { src: '/icons/icon_clicknpos.svg', height: '50px', alt: 'clicknpost' }, void 0)] }), void 0))] }, void 0) }, void 0) }, void 0));
    };
    return ClickAndXList;
}(Component));
export default withTheme(withRouter(ClickAndXList));
