var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils & libs
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toJS } from '@shared/utils/immutable';
import { getSorts } from '@store/sort/actions';
import { selectSortedSorts, shouldLoadSorts as selectShouldLoadSorts, selectLoading, } from '@store/sort/selectors';
// components & container
import SessionContainer from 'containers/SessionContainer';
import SortPage from 'pages/Sort';
import { parse } from 'query-string';
var Sort = /** @class */ (function (_super) {
    __extends(Sort, _super);
    function Sort(props) {
        var _this = _super.call(this, props) || this;
        if (_this.props.shouldLoadSorts) {
            _this.props.getSorts();
        }
        _this.getCurrentSortId = function () {
            var location = _this.props.location;
            var params = parse(location.search, { arrayFormat: 'bracket' });
            return params.sort ? parseInt(params.sort, 10) : null;
        };
        return _this;
    }
    Sort.prototype.render = function () {
        var _this = this;
        var _a = this.props, location = _a.location, sorts = _a.sorts, loading = _a.loading;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(SortPage, __assign({}, sessionProps, { params: location.search, sorts: sorts, currentSortId: _this.getCurrentSortId(), loading: loading }), void 0)); } }, void 0));
    };
    return Sort;
}(Component));
var select = function () { return function (state) { return ({
    sorts: selectSortedSorts(state),
    shouldLoadSorts: selectShouldLoadSorts(state),
    loading: selectLoading(state),
}); }; };
export default withRouter(connect(select, { getSorts: getSorts })(toJS(Sort)));
