var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import styled, { keyframes } from "styled-components";
import { palette } from "@shared/utils/styled";
var bounce = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% {\n    transform: scale(0.0);\n  }\n  50% {\n    transform: scale(1.0);\n  }\n"], ["\n  0%, 100% {\n    transform: scale(0.0);\n  }\n  50% {\n    transform: scale(1.0);\n  }\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 2.5rem;\n  margin: auto;\n  position: relative;\n  width: 2.5rem;\n  grid-area: footer;\n  grid-row: auto;\n"], ["\n  height: 2.5rem;\n  margin: auto;\n  position: relative;\n  width: 2.5rem;\n  grid-area: footer;\n  grid-row: auto;\n"])));
var Bouncer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  animation: ", " 2s infinite ease-in-out;\n  background-color: ", ";\n  border-radius: 50%;\n  height: 100%;\n  left: 0;\n  opacity: 0.6;\n  position: absolute;\n  top: 0;\n  width: 100%;\n"], ["\n  animation: ", " 2s infinite ease-in-out;\n  background-color: ", ";\n  border-radius: 50%;\n  height: 100%;\n  left: 0;\n  opacity: 0.6;\n  position: absolute;\n  top: 0;\n  width: 100%;\n"])), bounce, palette("tertiaryHighlight"));
var Bouncer2 = styled(Bouncer)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  animation-delay: -1s;\n"], ["\n  animation-delay: -1s;\n"])));
var Spinner = function () {
    return (_jsxs(Wrapper, { children: [_jsx(Bouncer, {}, void 0), _jsx(Bouncer2, {}, void 0)] }, void 0));
};
/** @component */
export default Spinner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
