var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { withTheme } from 'styled-components';
import { Wrapper, Tab, StyledLink } from './styles';
import { filterSelected } from '@store/filterSelections/filterActions';
import { useDispatch } from 'react-redux';
var TabMenu = function (_a) {
    var tabs = _a.tabs, theme = _a.theme;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(filterSelected([]));
    }, []);
    return (_jsx(Wrapper, { children: tabs.map(function (tab) {
            var _a;
            var isCancelTab = tab.title === 'sort.tab.cancel';
            return (_jsx(Tab, __assign({ active: (_a = tab.active) !== null && _a !== void 0 ? _a : false }, { children: _jsx(StyledLink, __assign({ "data-cy-id": "tab-menu", to: tab.target }, { children: _jsx("span", __assign({ style: isCancelTab ? { color: theme.palette.activePrimaryHighlight } : undefined }, { children: tab.title }), void 0) }), void 0) }), tab.title));
        }) }, void 0));
};
export default withTheme(TabMenu);
