var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontWeight } from '@shared/utils/styled';
export var Name = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  margin-top: 0.5rem;\n"], ["\n  grid-column: 1 / 3;\n  margin-top: 0.5rem;\n"])));
export var Grid = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(8, 1fr);\n  margin-top: 0.5rem;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(8, 1fr);\n  margin-top: 0.5rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
