var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { key } from '@shared/utils/styled';
import { palette, fontSize } from '@shared/utils/styled';
import VerticalFlex from 'atoms/VerticalFlex';
import Text from '@shared/atoms/Text';
import Icon from '@shared/atoms/Icon';
import LinkWrapper from 'molecules/LinkWrapper';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-evenly;\n  grid-template-columns: 50% 50%;\n  height: 100%;\n  padding: 1rem; // Adds spacing inside the wrapper\n  border-radius: 10px; // Rounded corners for a modern look\n"], ["\n  display: flex;\n  justify-content: space-evenly;\n  grid-template-columns: 50% 50%;\n  height: 100%;\n  padding: 1rem; // Adds spacing inside the wrapper\n  border-radius: 10px; // Rounded corners for a modern look\n"])));
export var Action = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: stretch;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  outline: none;\n  padding: 0 0.5rem;\n  text-align: center;\n  transition: opacity 2s linear;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-items: stretch;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  outline: none;\n  padding: 0 0.5rem;\n  text-align: center;\n  transition: opacity 2s linear;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('white'), palette('iconColor'), palette('activeWhite'));
export var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-self: flex-end;\n  width: 100%;\n  height: 1rem;\n  margin-top: 1rem;\n  text-align: center;\n"], ["\n  align-self: flex-end;\n  width: 100%;\n  height: 1rem;\n  margin-top: 1rem;\n  text-align: center;\n"])));
export var StyledText = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: 0.025rem;\n  line-height: 1.5rem;\n  @media screen and (max-width: ", ") {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: 0.025rem;\n  line-height: 1.5rem;\n  @media screen and (max-width: ", ") {\n    font-size: ", ";\n  }\n"])), palette('tertiaryHighlight'), fontSize('large'), key('breakpoints.xsmall'), fontSize('default'));
export var IconWrapperPure = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 3.75rem;\n  @media screen and (max-width: ", ") {\n    height: 3rem;\n  }\n"], ["\n  height: 3.75rem;\n  @media screen and (max-width: ", ") {\n    height: 3rem;\n  }\n"])), key('breakpoints.xsmall'));
export var IconWrapper = styled(IconWrapperPure)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-self: center;\n  justify-content: center;\n"], ["\n  align-self: center;\n  justify-content: center;\n"]))).withComponent(VerticalFlex);
export var WatchlistIcon = styled(Icon)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-self: flex-end;\n"], ["\n  align-self: flex-end;\n"])));
export var CartBadgeCount = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  color: #fff;\n  margin-right: 0.5rem;\n"], ["\n  background: ", ";\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  color: #fff;\n  margin-right: 0.5rem;\n"])), palette('iconColor'));
export var CartBadge = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n"])));
export var NoLinkWrapper = LinkWrapper.withComponent('div');
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
