var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { key, palette } from "@shared/utils/styled";
import styled from "styled-components";
import BackgroundImage from "@shared/atoms/BackgroundImage";
// const isIE = /* @cc_on!@ */ false || !!document.documentMode;
// const isEdge = !isIE && !!window.StyleMedia;
/* const MenuIcon =
  isEdge || isIE
    ? styled(BackgroundImage)`
        background-image: url(${(props: any) =>
          key(['icons', props.icon], '/foo.svg')});
      `
    : styled.div<any>`
        mask: url(${(props: any) => key(['icons', props.icon], '/foo.svg')});
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center center;
        background-color: ${palette('iconColor')};
      `; */
var MenuIcon = styled(BackgroundImage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mask: url(", ");\n  mask-repeat: no-repeat;\n  mask-size: contain;\n  mask-position: center center;\n  background-color: ", ";\n"], ["\n  mask: url(", ");\n  mask-repeat: no-repeat;\n  mask-size: contain;\n  mask-position: center center;\n  background-color: ", ";\n"])), function (props) { return key(["icons", props.icon], "/foo.svg"); }, palette("iconColor"));
// -webkit-mask-image: url(${(props: any) => key(["icons", props.icon], "/foo.svg")});
// -webkit-mask-repeat: no-repeat;
// -webkit-mask-size: contain;
// -webkit-mask-position: center center;
// background-color:${palette("iconColor")};
// `;
//   background-image: url(${(props: any) => key(["icons", props.icon], "/foo.svg")});
// `;
/** @component */
export default MenuIcon;
var templateObject_1;
