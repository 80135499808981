var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette, fontSize } from '@shared/utils/styled';
import styled from 'styled-components';
var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 0.0625rem;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  outline: 0;\n"], ["\n  background-color: ", ";\n  border-radius: 0.0625rem;\n  border: 0;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  outline: 0;\n"])), palette('primaryHighlight'), palette('white'), fontSize('default'));
/** @component */
export default Button;
var templateObject_1;
