var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { put, call, takeLatest } from 'redux-saga/effects';
import errorHandler from 'services/connection';
import history from '@store/history';
import { requestAction as requestActionAction, requestActionComplete, orderAction as orderActionAction, orderActionComplete, } from './actions';
/**
 * Make call at the /sessions/{uuid}/watchlist/action endpoint to request an action on the selected items.
 *
 * @param api - @see: store/sagas.
 * @param action - Include all the data required for this call (payload, meta data, ...).
 */
export function requestAction(api, action) {
    var payload, meta, ref, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload, meta = action.meta;
                ref = meta.ref;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call([api, api.post], "sessions/" + meta.sessionId + "/watchlist/action", {
                        buyer: __assign({}, payload),
                        action: ref,
                    })];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(requestActionComplete())];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                error_1 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_1, requestActionComplete, requestActionAction, action.payload))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Make call to the /sessions/{uuid}/order endpoint to request an order on the selected items.
 *
 * @param api - @see: store/sagas.
 * @param action - Include all the data required for this call (payload, meta data, ...).
 */
export function orderAction(api, action) {
    var payload, meta, _a, salutation, payloadRemainder, body, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload, meta = action.meta;
                _a = payload.toJS(), salutation = _a.gender, payloadRemainder = __rest(_a, ["gender"]);
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call([api, api.post], "sessions/" + meta.sessionId + "/order", {
                        buyer: __assign(__assign({}, payloadRemainder), { salutation: salutation }),
                        // pin,
                    })];
            case 2:
                body = (_b.sent()).body;
                return [4 /*yield*/, put(orderActionComplete(body))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(history.push, "/sessions/" + meta.sessionId + "/order/complete")];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                error_2 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_2, orderActionComplete, orderActionAction, action.payload))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
/**
 * Saga watcher on the action REQUEST_ACTION.
 *
 * @param api - Endpoint for calling action.
 * @returns {IterableIterator<ForkEffect | *>} - Redux Saga action iterator.
 */
export function requestActionWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('REQUEST_ACTION', requestAction, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Saga watcher on the action ORDER_ACTION.
 *
 * @param api - Endpoint for calling action.
 * @returns {IterableIterator<ForkEffect | *>} - Redux Saga action iterator.
 */
export function orderActionWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('ORDER_ACTION', orderAction, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
