var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import localStore from 'store';
import { withTheme } from 'styled-components';
import QRCode from 'qrcode.react';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import SubMenu from 'organisms/SubMenu';
import Watchlist from 'organisms/Watchlist';
import ClickAndXList from 'molecules/ClickAndXList';
import { paymentSteps } from '@shared/utils/constants';
import Icon from '@shared/atoms/Icon';
import { Wrapper, CheckoutWrapper, CheckoutInfo, StyledButton, WatchlistWrapper, } from './styles';
var WatchlistArea = function (_a) {
    var createSession = _a.createSession, deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, sessionId = _a.sessionId, deviceId = _a.deviceId, theme = _a.theme, toggleWatchlist = _a.toggleWatchlist, topBorder = _a.topBorder, visible = _a.visible, watchlist = _a.watchlist, checkoutNumber = _a.checkoutNumber, clearCheckoutNumber = _a.clearCheckoutNumber, checkoutPos = _a.checkoutPos, getWatchlist = _a.getWatchlist;
    var intl = useIntl();
    var history = useHistory();
    var _b = useState(theme.paypal ? theme.paypal.clientId : null), clientId = _b[0], setClientId = _b[1];
    var _c = useState(false), isBuying = _c[0], setIsBuying = _c[1];
    var _d = useState(false), isPosCheckoutComplete = _d[0], setIsPosCheckoutComplete = _d[1];
    var handlePosCheckoutComplete = useCallback(function () {
        localStore.set('posCheckoutNotConfirmed', true);
        createSession(deviceId);
        setIsPosCheckoutComplete(true);
    }, [createSession, deviceId]);
    useEffect(function () {
        if (theme && theme.paypal && theme.paypal.clientId !== clientId) {
            setClientId(theme.paypal.clientId);
        }
        if (checkoutNumber) {
            handlePosCheckoutComplete();
        }
        getWatchlist(sessionId);
    }, [theme, checkoutNumber]);
    var handlePosCheckout = function () {
        var articles = watchlist
            .filter(function (item) { return item.selected; })
            .reduce(function (acc, item) {
            var article = {};
            article.sizeId = item.sizeId;
            article.articleId = item.articleId;
            acc.push(article);
            return acc;
        }, []);
        checkoutPos({
            articles: articles,
            customerId: null,
            type: paymentSteps.CHECKOUT,
        }, sessionId);
    };
    var canBuy = Boolean(theme.switches.buy);
    var headline = defineMessages({
        headline: {
            defaultMessage: "Warenkorb",
            id: 'watchlist.menu.headline',
        },
    }).headline;
    return (_jsxs(Wrapper, __assign({ visible: visible }, { children: [_jsx(SubMenu, { title: intl.formatMessage(headline), count: Boolean(watchlist) ? watchlist.length : 0, sessionId: sessionId, topBorder: topBorder, cancelIcon: _jsx(BackButtonSmall, { action: function () {
                        toggleWatchlist();
                        setIsBuying(false);
                    }, fill: 'white', withText: true }, void 0), iconRight: _jsx(Icon, { height: '40', icon: 'icon_trashcan', fill: 'white', onClick: function () { return deleteWatchlist(sessionId); } }, void 0), 
                /*  iconRight={
                    <div
                      style={{ fontSize: '1.65rem', padding: '0 10px', color: 'white' }}
                      onClick={() => deleteWatchlist(sessionId)}
                    >
                      🗑
                    </div>
                  } */
                noBack: isPosCheckoutComplete }, void 0), !isPosCheckoutComplete && (_jsx(WatchlistWrapper, { children: _jsx(Watchlist, { toggleWatchlist: toggleWatchlist, deleteWatchlistItem: deleteWatchlistItem, sessionId: sessionId, watchlist: watchlist, createSession: createSession, deviceId: deviceId }, void 0) }, void 0)), isPosCheckoutComplete && (_jsxs(CheckoutInfo, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'checkout.buy.complete.infoText.line1', default: 'Bitte zeige diesen QR Code an der Kasse, um zu bezahlen.' }, void 0) }, void 0), _jsx("h3", { children: _jsx(FormattedMessage, { id: 'checkout.buy.complete.infoText.line2', default: '-' }, void 0) }, void 0), _jsxs("div", { children: [_jsx("h2", __assign({ style: { textAlign: 'center' } }, { children: checkoutNumber }), void 0), _jsx(QRCode, { "data-cy-id": 'qr-code', value: "*WK" + checkoutNumber, fgColor: '#000', size: 120 }, void 0)] }, void 0), _jsx(StyledButton, __assign({ onClick: function () {
                            localStore.remove('posCheckoutNotConfirmed');
                            clearCheckoutNumber();
                            history.push('/');
                        } }, { children: _jsx(FormattedMessage, { defaultMessage: 'Schlie\u00DFen', id: 'checkout.buy.complete.close' }, void 0) }), void 0)] }, void 0)), watchlist && watchlist.length > 0 && (_jsx(CheckoutWrapper, { children: canBuy && false === isPosCheckoutComplete && (_jsx(React.Fragment, { children: _jsx(ClickAndXList, { sessionId: sessionId, isBuying: isBuying, setIsBuying: setIsBuying, checkoutNumber: checkoutNumber, watchlist: watchlist, checkoutPos: checkoutPos, handlePosCheckout: handlePosCheckout, createSession: createSession, getWatchlist: getWatchlist }, void 0) }, void 0)) }, void 0))] }), void 0));
};
export default withTheme(WatchlistArea);
