// @flow
import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    loadingAll: false,
    loadedAll: false,
});
var updateDevice = function (device) { return function (existingDevices) {
    var index = existingDevices.findIndex(function (existingDevice) { return existingDevice.get('uuid') === device.get('uuid'); });
    if (index === -1) {
        return existingDevices.push(device);
    }
    return existingDevices.set(index, device);
}; };
var updateDevices = function (devices) { return function (existingDevices) {
    if (existingDevices.size === 0) {
        return devices;
    }
    var newDevices = existingDevices;
    devices.forEach(function (device) {
        // $FlowIgnoreNextLine
        newDevices = newDevices.update(updateDevice(device));
    });
    return newDevices;
}; };
var reducer = handleActions({
    GET_DEVICES: function (state) { return state.set('loadingAll', true); },
    GET_DEVICE_THEME: function (state) { return state; },
    GET_DEVICES_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', updateDevices(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
}, initialState);
export default reducer;
