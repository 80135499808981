var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import { injectIntl, defineMessages } from 'react-intl';
// components
import SortList from 'molecules/SortList';
import history from '@store/history';
import SessionLayout from 'templates/SessionLayout';
import styled from 'styled-components';
import SubMenu from 'organisms/SubMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
var Spacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 4.3575rem;\n"], ["\n  width: 4.3575rem;\n"])));
var Sort = /** @class */ (function (_super) {
    __extends(Sort, _super);
    function Sort() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Sort.prototype.render = function () {
        var _a = this.props, deleteWatchlistItem = _a.deleteWatchlistItem, intl = _a.intl, params = _a.params, session = _a.session, watchlist = _a.watchlist, loading = _a.loading, currentSortId = _a.currentSortId, sorts = _a.sorts;
        var msg = defineMessages({
            filter: { defaultMessage: 'Sortieren', id: 'sort.menu.headline' },
        });
        return (_jsx(SessionLayout, __assign({ deleteWatchlistItem: deleteWatchlistItem, session: session, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.filter), cancelIcon: _jsx(BackButtonSmall, { action: function () {
                        return history.push("/" + session.uuid + "/results" + (params || ''));
                    }, fill: 'white', withText: true }, void 0) }, void 0), backgroundColor: palette('white'), watchlist: watchlist }, { children: _jsx(SortList, { loading: loading, params: params, session: session, currentSortId: currentSortId, sorts: sorts }, void 0) }), void 0));
    };
    return Sort;
}(Component));
export var SortComponent = Sort;
export default injectIntl(Sort);
var templateObject_1;
