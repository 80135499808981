var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Spinner from '@shared/atoms/Spinner';
// @flow
import React from 'react';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-column: 1/3;\n"], ["\n  grid-column: 1/3;\n"])));
var TileInfiniteSpinner = function () { return (_jsx(Wrapper, { children: _jsx(Spinner, {}, void 0) }, void 0)); };
/** @component */
export default TileInfiniteSpinner;
var templateObject_1;
