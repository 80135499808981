var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import Icon from '../Icon';
var CloseIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0.5rem;\n  right: 0.5rem;\n"], ["\n  position: absolute;\n  top: 0.5rem;\n  right: 0.5rem;\n"])));
/** @component */
export default CloseIcon;
var templateObject_1;
