var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import { Link } from 'react-router-dom';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Tile = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  margin-bottom: 0.9375rem;\n  text-decoration: none;\n  width: 100%;\n"], ["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  margin-bottom: 0.9375rem;\n  text-decoration: none;\n  width: 100%;\n"])), palette('text'), fontSize('default'));
export var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var Headline = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n"], ["\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n"])), fontWeight('bold'));
export var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 0.9375rem;\n"], ["\n  padding: 0 0.9375rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
