var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import CloseIcon from '@shared/atoms/CloseIcon';
import { Wrapper, CardFront, CardTitle, CardArrow, CardBack, Content, FlipCard, } from './styles';
var AboutUs = /** @class */ (function (_super) {
    __extends(AboutUs, _super);
    function AboutUs(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            showText: false,
        };
        return _this;
    }
    AboutUs.prototype.componentDidUpdate = function () {
        this.state.showText = false;
    };
    AboutUs.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, text = _a.text;
        return (_jsxs(Wrapper, { children: [_jsxs(CardFront, __assign({ onClick: function () { return _this.setState({ showText: true }); } }, { children: [_jsx(CardTitle, { children: title }, void 0), _jsx(CardArrow, { children: ">" }, void 0)] }), void 0), _jsxs(CardBack, __assign({ visible: this.state.showText }, { children: [_jsx(FlipCard, { children: _jsx(CloseIcon, { icon: 'closex', width: 30, onClick: function () { return _this.setState({ showText: false }); } }, void 0) }, void 0), _jsx(Content, { dangerouslySetInnerHTML: { __html: text } }, void 0)] }), void 0)] }, void 0));
    };
    return AboutUs;
}(Component));
export { AboutUs };
