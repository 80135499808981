var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React from 'react';
import { palette } from '@shared/utils/styled';
import ArticleList from 'molecules/ArticleList';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import history from '@store/history';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import { getSearchParams } from '@shared/utils/params';
import queryString from 'query-string';
var Result = function (_a) {
    var articlePage = _a.articlePage, articlePageCount = _a.articlePageCount, articlesTotalCount = _a.articlesTotalCount, articles = _a.articles, articlesLoading = _a.articlesLoading, deleteWatchlistItem = _a.deleteWatchlistItem, fetch = _a.fetch, filterDescription = _a.filterDescription, primaryCategory = _a.primaryCategory, session = _a.session, title = _a.title, watchlist = _a.watchlist, params = _a.params;
    var getSearchPageUrl = function () {
        var paramObjects = getSearchParams(params);
        var _a = queryString.parse(window.location.search), category = _a.category, primaryCategory = _a.primaryCategory, rest = __rest(_a, ["category", "primaryCategory"]);
        var queryStringExceptCategory = queryString.stringify(rest);
        var target = "/" + session.uuid + "/search";
        if (primaryCategory) {
            target += "/" + primaryCategory;
        }
        if (queryStringExceptCategory) {
            target += "?" + queryStringExceptCategory;
        }
        return target;
    };
    return (_jsx(_Fragment, { children: _jsx(SessionLayout, __assign({ deleteWatchlistItem: deleteWatchlistItem, session: session, backgroundColor: palette('white'), subMenu: _jsx(SubMenu, { cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push(getSearchPageUrl()); }, fill: 'white', withText: true }, void 0), count: articlesTotalCount, title: title }, void 0), watchlist: watchlist, hasFilters: Boolean(filterDescription) }, { children: _jsx(ArticleList, { articles: articles, basePath: "/" + session.uuid + "/articles/", currentPage: articlePage, filterDescription: filterDescription, fetchArticles: fetch, loading: articlesLoading, pageCount: articlePageCount, primaryCategory: primaryCategory, params: params }, void 0) }), void 0) }, void 0));
};
export default Result;
