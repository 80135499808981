import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
export var initialState = Map({
    data: List(),
    loadingAll: false,
    loadedAll: false,
    error: false,
});
export var updateInspiration = function (inspiration) { return function (existingInspirations) {
    var index = existingInspirations.findIndex(function (existingInspiration) {
        return existingInspiration.get('id') === inspiration.get('id');
    });
    if (index === -1) {
        return existingInspirations.push(inspiration);
    }
    return existingInspirations.set(index, inspiration);
}; };
var updateInspirations = function (inspirations) { return function (existingInspirations) {
    if (existingInspirations.size === 0) {
        return inspirations;
    }
    var newInspirations = existingInspirations;
    inspirations.forEach(function (inspiration) {
        newInspirations = newInspirations.update(updateInspiration(inspiration));
    });
    return newInspirations;
}; };
var reducer = handleActions({
    GET_INSPIRATIONS: function (state) { return state.set('loadingAll', true); },
    GET_INSPIRATIONS_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', updateInspirations(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
}, initialState);
export default reducer;
