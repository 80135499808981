var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { fontSize, palette } from "@shared/utils/styled";
var CenterText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  text-align: center;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  text-align: center;\n  width: 100%;\n"])), palette("text"), function (props) {
    return props.size ? fontSize(props.size) : fontSize("default");
});
/** @component */
export default CenterText;
var templateObject_1;
