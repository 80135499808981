var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import Spinner from '@shared/atoms/Spinner';
var LoadingScreen = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: stretch;\n  justify-content: center;\n  width: 100%;\n  height: calc(100vh - 16px);\n"], ["\n  align-items: stretch;\n  justify-content: center;\n  width: 100%;\n  height: calc(100vh - 16px);\n"])));
var PageSpinner = function () { return (_jsx(LoadingScreen, { children: _jsx(Spinner, {}, void 0) }, void 0)); };
/** @component */
export default PageSpinner;
var templateObject_1;
