var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { SearchIconContainer, ComboField, FieldWrapper, Radios, Error, StyledForm, StyledField, } from './styles';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectArticleError } from '@store/article/selectors';
var ScanForm = function (_a) {
    var onSubmit = _a.onSubmit, scanResult = _a.scanResult;
    var hasError = useSelector(selectArticleError);
    var _b = useState('Artikelnummer'), placeholder = _b[0], setPlaceholder = _b[1];
    var handleClick = function (name) { return setPlaceholder(name); };
    return (_jsx(Form, { onSubmit: onSubmit, initialValues: {
            type: 'number',
            code: scanResult,
        }, render: function (_a) {
            var handleSubmit = _a.handleSubmit, form = _a.form, submitting = _a.submitting, pristine = _a.pristine, values = _a.values;
            return (_jsxs(React.Fragment, { children: [_jsx(Error, { children: hasError && _jsx("span", { children: "Keine Artikel gefunden" }, void 0) }, void 0), _jsxs(StyledForm, __assign({ onSubmit: handleSubmit }, { children: [_jsxs(ComboField, { children: [_jsx(Field, __assign({ name: 'code' }, { children: function (_a) {
                                            var input = _a.input, meta = _a.meta;
                                            return (_jsx(StyledField, __assign({}, input, { type: 'text', placeholder: placeholder }), void 0));
                                        } }), void 0), _jsx(SearchIconContainer, __assign({ type: 'submit', disabled: pristine || submitting }, { children: _jsx(Icon, { icon: 'search', width: 40 }, void 0) }), void 0)] }, void 0), _jsxs(Radios, { children: [_jsxs(FieldWrapper, { children: [_jsx(Field, { id: 'numberRadio', name: 'type', component: 'input', type: 'radio', value: 'number', onClick: function () { return handleClick('Artikelnummer'); } }, void 0), _jsx("label", __assign({ htmlFor: 'numberRadio' }, { children: _jsx(FormattedMessage, { id: 'scan.form.articleNo', defaultMessage: 'Artikelnummer' }, void 0) }), void 0)] }, void 0), _jsxs(FieldWrapper, { children: [_jsx(Field, { id: 'scanRadio', name: 'type', component: 'input', type: 'radio', value: 'scan', onClick: function () { return handleClick('EAN'); } }, void 0), _jsx("label", __assign({ htmlFor: 'scanRadio' }, { children: _jsx(FormattedMessage, { id: 'scan.form.ean', defaultMessage: 'EAN' }, void 0) }), void 0)] }, void 0)] }, void 0)] }), void 0)] }, void 0));
        } }, void 0));
};
export default ScanForm;
