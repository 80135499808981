var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { palette, fontWeight } from '@shared/utils/styled';
import { Link } from 'react-router-dom';
var flash = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n0%, 100% {\n  opacity: 1;\n}\n50% {\n  opacity: 0.5;\n}\n"], ["\n0%, 100% {\n  opacity: 1;\n}\n50% {\n  opacity: 0.5;\n}\n"])));
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 1rem;\n  position: relative;\n"], ["\n  display: block;\n  margin-bottom: 1rem;\n  position: relative;\n"])));
export var ThumbnailImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  max-height: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 100%;\n"], ["\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  max-height: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 100%;\n"])));
export var ImageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  width: 100%;\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"], ["\n  background: ", ";\n  position: relative;\n  width: 100%;\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"])), palette('white'), flash);
export var Bold = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
export var InfoWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n  color: ", ";\n"], ["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n  color: ", ";\n"])), palette('text'));
export var ActionWrapper = styled(Link)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-decoration: none;\n  width: 100%;\n"], ["\n  text-decoration: none;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
