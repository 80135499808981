import { useRef, useEffect } from 'react';
var useDidUpdate = function (callback, deps) {
    var hasMount = useRef(false);
    useEffect(function () {
        if (hasMount.current) {
            callback();
        }
        else {
            hasMount.current = true;
        }
    }, deps);
};
export default useDidUpdate;
