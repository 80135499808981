// @flow
import { reversePalette } from 'styled-theme/composer';
import { darken, rgba } from 'polished';
var theme = {};
theme.palette = {
    primaryHighlight: '#ff6700',
    activePrimaryHighlight: darken(0.07, '#ff6700'),
    secondaryHighlight: '#424d56',
    activeSecondaryHighlight: darken(0.07, '#424d56'),
    tertiaryHighlight: '#006e99',
    secondaryBackground: '#1c394c',
    activeSecondaryBackground: darken(0.07, '#1c394c'),
    text: '#292f35',
    linkText: '#19499e',
    highlightText: '#19499e',
    iconColor: '#3B495E',
    // fixed
    primaryBackground: '#cad1d8',
    border: '#cad1d8',
    white: '#fff',
    black: '#000',
    placeholderText: rgba('#292f35', 0.5),
    transparentBlack: 'rgba(0, 0, 0, 0.7)',
    // state
    activeWhite: darken(0.03, '#fff'),
    activeGrey: darken(0.07, '#fff'),
    activeDark: 'rgba(0, 0, 0, 0.15)',
    selectedWhite: '#EDEDED',
    // availability
    available: '#5ca52d',
    inDifferentStore: '#FECD2F',
    inStock: '#FECD2F',
    orderable: '#ff9100',
    scheduled: '#5990e2',
};
theme.modalOverlayBg = 'rgba(128, 129, 136, 0.85)';
theme.reversePalette = reversePalette(theme.palette);
theme.fontSizes = {
    small: '10.5pt',
    default: '12pt',
    medium: '13.5pt',
    large: '15pt',
    extraLarge: '18pt',
};
theme.fontWeights = {
    light: 300,
    default: 400,
    bold: 600,
};
theme.sizes = {
    maxWidth: '1100px',
    fontSize: '12pt',
};
theme.icons = {
    activesupport: '/icons/active_icon_service.svg',
    search: '/icons/icon_search.svg',
    scan: '/icons/icon_info.svg',
    filter: '/icons/icon_filter.svg',
    filterActive: '/icons/icon_filter_active.svg',
    sort: '/icons/icon_sort.svg',
    sortActive: '/icons/icon_sort_active.svg',
    support: '/icons/icon_service.svg',
    inspiration: '/icons/icon_inspiration.svg',
    client: '/icons/icon_client.svg',
    range: '/icons/icon_range.svg',
    activesupportContent: '/icons/active_icon_content_service.svg',
    rangeContent: '/icons/icon_content_range.svg',
    searchContent: '/icons/icon_content_search.svg',
    scanContent: '/icons/icon_content_info.svg',
    supportContent: '/icons/icon_content_service.svg',
    inspirationContent: '/icons/icon_content_inspiration.svg',
};
theme.breakpoints = {
    large: '1720px',
    medium: '1280px',
    xsmall: '376px',
    xxsmall: '340px',
};
theme.logoUrl = '';
theme.customerWelcomeImage = '';
theme.switches = { buy: true, reserve: true };
export default theme;
