var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
var ConnectionError = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.7);\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 0;\n  width: 100%;\n  z-index: 1000;\n"], ["\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.7);\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 0;\n  width: 100%;\n  z-index: 1000;\n"])));
/** @component */
export default ConnectionError;
var templateObject_1;
