import { createSelector } from 'reselect';
import { selectArticleId } from '../article/selectors';
var selectState = function (state) { return state.get('watchlist'); };
export var selectLoading = createSelector(selectState, function (watchListState) {
    return watchListState.get('loading');
});
export var selectLoadingAll = createSelector(selectState, function (watchListState) {
    return watchListState.get('loadingAll');
});
export var selectLoaded = createSelector(selectState, function (watchListState) {
    return watchListState.get('loaded');
});
export var selectLoadingAllComplete = createSelector(selectState, function (watchListState) {
    return !watchListState.get('loadingAll') && watchListState.get('loadedAll');
});
export var selectWatchlist = createSelector(selectState, function (watchListState) {
    return watchListState.get('data');
});
export var selectCartPrice = createSelector(selectState, function (watchListState) {
    var price = 0;
    watchListState.get('data').map(function (item) {
        if (item.get('sizeId')) {
            var size = item
                .get('article')
                .get('sizes')
                .find(function (si) { return si.get('sizeId') === item.get('sizeId'); });
            if (size) {
                price += size.get('price');
            }
        }
    });
    return price;
});
export var selectWatchlistStatus = createSelector(selectState, function (watchListState) { return watchListState.get('status'); });
export var selectWatchListHasDifferentType = createSelector(selectState, function (watchListState) { return watchListState.get('hasDifferentType'); });
export var loadSingleWatchlistItem = function () {
    return createSelector([selectWatchlist, selectArticleId], function (watchlist, articleId) {
        return watchlist.find(function (item) { return item.get('articleId') === articleId; });
    });
};
export var selectWatchlistError = createSelector(selectState, function (watchlistState) { return watchlistState.get('error'); });
