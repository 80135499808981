import { createSelector } from 'reselect';
var selectFilterSelectionState = function (state) { return state.get('filterSelection'); };
export var selectFilterSelections = createSelector(selectFilterSelectionState, function (filterState) { return filterState.get('data'); });
export var selectTempFilterSelections = createSelector(selectFilterSelectionState, function (filterState) { return filterState.get('temp'); });
export var selectFilterDescription = createSelector(selectTempFilterSelections, function (filterSelections) {
    // Check if there are selected filters
    var selections = filterSelections.filter(function (selection) { return selection.get('items').size >= 1; });
    var selectionsInfo = selections.reduce(function (acc, filterSelection) {
        filterSelection.get('items').forEach(function (filterItem) {
            acc.push(filterItem.get('displayName'));
        });
        return acc;
    }, []);
    return selectionsInfo.join(', ');
});
