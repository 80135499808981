var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { toJS } from '@shared/utils/immutable';
import localStore from 'store';
import { getTheme } from '@store/theme/actions';
import { setIdentity } from '@store/identity/actions';
import { removeError } from '@store/error/actions';
import { getSession, createSession } from '@store/session/actions';
import { setWatchlistHasDifferentType } from '@store/watchlist/actions';
import { selectLoaded as identitySelectLoaded, selectError as identitySelectError, } from '@store/identity/selectors';
import { selectLoaded as sessionSelectLoaded } from '@store/session/selectors';
import { selectError } from '@store/error/selectors';
import { selectWatchListHasDifferentType, selectWatchlistStatus, } from '@store/watchlist/selectors';
import PageSpinner from '@shared/atoms/PageSpinner';
import ModalText from '@shared/atoms/ModalText';
import Error from '@shared/molecules/ConnectionError';
import CloseIcon from '@shared/atoms/CloseIcon';
import metaData from 'metadata';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  height: -webkit-fill-available;\n  /* height: fill-available;\n  height: stretch; */\n"], ["\n  height: 100vh;\n  height: -webkit-fill-available;\n  /* height: fill-available;\n  height: stretch; */\n"])));
var watchlistTypeError = defineMessages({
    watchlistTypeError: {
        defaultMessage: "Im Warenkorb befindet sich bereits ein Artikel: {type} Bitte erst Warenkorb abschliessen.",
        id: 'watchlist.error.type',
    },
}).watchlistTypeError;
var NeedleProvider = /** @class */ (function (_super) {
    __extends(NeedleProvider, _super);
    function NeedleProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onErrorCloseClick = function () {
            if (_this.props.identityError)
                return;
            _this.props.removeError();
        };
        _this.closeDifferentTypeModal = function () {
            _this.props.setWatchlistHasDifferentType(false);
        };
        return _this;
    }
    NeedleProvider.prototype.componentDidMount = function () {
        localStore.remove('posCheckoutNotConfirmed');
        window.needleVersion = metaData.buildMajor + "." + metaData.buildMinor + "." + metaData.buildRevision;
        var _a = this.props, deviceId = _a.deviceId, sessionId = _a.sessionId;
        this.props.setIdentity(deviceId);
        this.props.getTheme();
        if (this.props.sessionId) {
            this.props.getSession({ deviceId: deviceId, sessionId: sessionId });
        }
        else {
            this.props.createSession(deviceId);
        }
        var mafId = localStore.get('mafId');
        var mafGender = localStore.get('mafGender');
        if (mafId && !mafGender) {
            this.props.history.push("/" + sessionId + "/search");
        }
    };
    NeedleProvider.prototype.render = function () {
        var _a = this.props, children = _a.children, identityError = _a.identityError, identityLoaded = _a.identityLoaded, sessionLoaded = _a.sessionLoaded, error = _a.error, isWatchListHasDifferentType = _a.isWatchListHasDifferentType, watchListStatus = _a.watchListStatus, intl = _a.intl;
        if ((!identityLoaded || !sessionLoaded) &&
            !identityError &&
            !error.showError) {
            return _jsx(PageSpinner, {}, void 0);
        }
        var defaultMessagesForWatchlistTypes = {
            available: 'Kaufen',
            inDifferentStore: 'Anforderung aus anderer Filiale',
            orderable: 'beim Hersteller bestellen.',
        };
        return (_jsxs(Wrapper, { children: [children, ((error.hasError && error.showError) || identityError) && (_jsxs(Error, __assign({ onClick: this.onErrorCloseClick }, { children: [_jsx(ModalText, { children: error.message }, void 0), _jsx(CloseIcon, { icon: 'closex', width: 10 }, void 0)] }), void 0)), isWatchListHasDifferentType && (_jsxs(Error, __assign({ onClick: this.closeDifferentTypeModal }, { children: [_jsx(ModalText, { children: intl.formatMessage(watchlistTypeError, {
                                type: defaultMessagesForWatchlistTypes[watchListStatus],
                            }) }, void 0), _jsx(CloseIcon, { icon: 'closex', width: 10 }, void 0)] }), void 0))] }, void 0));
    };
    return NeedleProvider;
}(Component));
var select = createStructuredSelector({
    identityError: identitySelectError,
    identityLoaded: identitySelectLoaded,
    sessionLoaded: sessionSelectLoaded,
    error: selectError,
    isWatchListHasDifferentType: selectWatchListHasDifferentType,
    watchListStatus: selectWatchlistStatus,
});
export default injectIntl(withRouter(connect(select, {
    getSession: getSession,
    getTheme: getTheme,
    createSession: createSession,
    setIdentity: setIdentity,
    removeError: removeError,
    setWatchlistHasDifferentType: setWatchlistHasDifferentType,
})(toJS(NeedleProvider))));
var templateObject_1;
