var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { useEffect } from 'react';
import localStore from 'store';
import CategoryGridItem from 'molecules/CategoryGridItem';
import InfiniteScroll from '@shared/organisms/InfiniteScroll';
import { List } from './styles';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
var NoResultsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n"], ["\n  padding: 10px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  margin-bottom: 20px;\n  text-align: center;\n"], ["\n  font-size: 24px;\n  margin-bottom: 20px;\n  text-align: center;\n"])));
var Message = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 16px;\n  line-height: 1.5;\n  margin-bottom: 10px;\n  text-align: center;\n"], ["\n  font-size: 16px;\n  line-height: 1.5;\n  margin-bottom: 10px;\n  text-align: center;\n"])));
var ParamsList = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 10px;\n  text-align: center;\n"], ["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 10px;\n  text-align: center;\n"])));
var Param = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 5px;\n"], ["\n  margin-bottom: 5px;\n"])));
var Linked = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 16px;\n  line-height: 1.5;\n  text-align: center;\n  color: #000000;\n  text-decoration: underline;\n"], ["\n  font-size: 16px;\n  line-height: 1.5;\n  text-align: center;\n  color: #000000;\n  text-decoration: underline;\n"])));
var CategoryGrid = function (_a) {
    var basePath = _a.basePath, primaryCategoryId = _a.primaryCategoryId, categories = _a.categories, currentPage = _a.currentPage, loading = _a.loading, pageCount = _a.pageCount, getCategories = _a.getCategories;
    var search = useLocation().search;
    var queryParams = queryString.parse(search);
    var fetchCategories = function () {
        var filters = buildFilterforMatchAndFit();
        var params = __assign(__assign({ page: currentPage + 1, primaryCategory: primaryCategoryId }, filters), queryParams);
        getCategories(params);
    };
    useEffect(function () {
        if (!loading) {
            fetchCategories();
        }
    }, [primaryCategoryId]);
    var buildFilterforMatchAndFit = function () {
        // NEED-458
        var sizeEu = localStore.get('sizeEu');
        var sizeUk = localStore.get('sizeUk');
        var sizeUs = localStore.get('sizeUs');
        var sizeUsMen = localStore.get('sizeUsMen');
        var sizeUsWomen = localStore.get('sizeUsWomen');
        var sizeUsKids = localStore.get('sizeUsKids');
        if (primaryCategoryId === 1 && sizeUsMen) {
            sizeUs = sizeUsMen;
        }
        if (primaryCategoryId === 2 && sizeUsWomen) {
            sizeUs = sizeUsWomen;
        }
        if (primaryCategoryId === 3 || (primaryCategoryId === 4 && sizeUsKids)) {
            sizeUs = sizeUsKids;
        }
        var filter = { sizeEu: sizeEu, sizeUk: sizeUk, sizeUs: sizeUs };
        Object.keys(filter).forEach(function (key) { return (filter[key] === undefined ? delete filter[key] : {}); });
        return filter;
        // NEED-458 End
    };
    var renderItems = function (categories, basePath, primaryCategoryId) {
        return categories.map(function (category) { return (_jsx(CategoryGridItem, { basePath: basePath, primaryCategoryId: primaryCategoryId, category: category }, category.id)); });
    };
    return (_jsxs(_Fragment, { children: [!categories.length && !loading && (_jsxs(NoResultsContainer, { children: [_jsx(Title, { children: "No results found" }, void 0), _jsx(Message, { children: "Sorry, we couldn't find any matching results for your search." }, void 0), _jsx(ParamsList, { children: Object.entries(queryParams).map(function (_a) {
                            var key = _a[0], value = _a[1];
                            return (_jsxs(Param, { children: [_jsxs("strong", { children: [key, ":"] }, void 0), " ", value] }, key));
                        }) }, void 0), _jsx(Message, { children: "Please try again with different search terms or adjust your filters." }, void 0), _jsx(Linked, __assign({ to: "/" + basePath + "/search" }, { children: "Go back to Categories page" }), void 0)] }, void 0)), primaryCategoryId && (_jsx(InfiniteScroll, { currentPage: currentPage, customThreshold: 1, hasMore: currentPage < pageCount, height: 'calc(100vh - 8.75rem)', items: renderItems(categories, basePath, primaryCategoryId), loading: loading, loadMore: fetchCategories, waypointAsElement: true, wrapper: List }, void 0))] }, void 0));
};
export default CategoryGrid;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
