// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    data: Map(),
    loading: false,
    loaded: false,
});
var reducer = handleActions({
    GET_THEME: function (state) { return state.set('loading', true); },
    GET_THEME_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('data', action.payload);
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
}, initialState);
export default reducer;
