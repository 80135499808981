import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ArticleListItem from 'molecules/ArticleListItem';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Name, Slider } from './styles';
var ArticleCrossSelling = function (_a) {
    var article = _a.article, basePath = _a.basePath, accessoires = _a.accessoires;
    return (_jsxs(Wrapper, { children: [article.similarArticles && article.similarArticles.length > 0 && (_jsxs(Slider, { children: [_jsx(Name, { children: _jsx(FormattedMessage, { defaultMessage: 'Passend dazu', id: 'article.view.similar' }, void 0) }, void 0), _jsx("div", { children: article.similarArticles.map(function (similar) { return (_jsx(ArticleListItem, { article: similar, basePath: basePath, full: true }, "similar" + similar.id)); }) }, void 0)] }, void 0)), !article.isAccessoire && accessoires && accessoires.length > 0 && (_jsxs(Slider, { children: [_jsx(Name, { children: _jsx(FormattedMessage, { defaultMessage: 'Zubeh\u00F6r', id: 'article.view.accessoires' }, void 0) }, void 0), _jsx("div", { children: accessoires.map(function (similar) { return (_jsx(ArticleListItem, { article: similar, basePath: basePath, full: true }, "similar" + similar.id)); }) }, void 0)] }, void 0))] }, void 0));
};
export default ArticleCrossSelling;
