var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
export var FilterDescription = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 0.125rem solid ", ";\n  color: ", ";\n  display: block;\n  justify-content: center;\n  padding: 0.376rem 0.625rem;\n  height: 1.875rem;\n  text-align: center;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 0.125rem solid ", ";\n  color: ", ";\n  display: block;\n  justify-content: center;\n  padding: 0.376rem 0.625rem;\n  height: 1.875rem;\n  text-align: center;\n"])), palette('white'), palette('border'), palette('secondaryHighlight'));
export var FilterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1/3;\n"], ["\n  grid-column: 1/3;\n"])));
export var NoResultFound = styled(HorizontalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  grid-column: 1/3;\n"], ["\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  grid-column: 1/3;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
