var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
// components
import SessionContainer from 'containers/SessionContainer';
import CheckoutErrorPage from 'components/pages/CheckoutError';
var CheckoutError = function () {
    return (_jsx(SessionContainer, { children: function (sessionProps) { return _jsx(CheckoutErrorPage, __assign({}, sessionProps), void 0); } }, void 0));
};
export default connect()(toJS(CheckoutError));
