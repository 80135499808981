var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import { getMinPriceString, hasDiscounts, getCrossedPrice, } from "@shared/utils/currency";
import styled from "styled-components";
var Tag = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? "#b52025" : "inherit"); }, function (props) { return (props.crossed ? "line-through" : "none"); });
var PriceTag = function (_a) {
    var prices = _a.prices, targetPrices = _a.targetPrices, fromAssistant = _a.fromAssistant;
    var hasDiscount = hasDiscounts(prices, targetPrices);
    var crossed = getCrossedPrice(prices, targetPrices);
    return (_jsxs("span", __assign({ "data-cy-id": "article-price" }, { children: [hasDiscount && Boolean(crossed) && _jsx(Tag, __assign({ crossed: true }, { children: crossed }), void 0), hasDiscount && Boolean(crossed) && "  ", _jsx(Tag, __assign({ discount: hasDiscount && !fromAssistant }, { children: getMinPriceString(prices) }), void 0)] }), void 0));
};
/** @component */
export default PriceTag;
var templateObject_1;
