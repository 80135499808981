var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import theme from '../../../themes/default';
import Icon from '@shared/atoms/Icon';
var Wrapper = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  align-self: stretch;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  outline: none;\n  width: fit-content;\n"], ["\n  align-items: center;\n  align-self: stretch;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  outline: none;\n  width: fit-content;\n"])));
var CancelIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { return (props.width ? props.width : '1rem'); });
var CancelButton = function (_a) {
    var action = _a.action, customIcon = _a.customIcon, width = _a.width, fill = _a.fill;
    return (_jsx(Wrapper, __assign({ "data-cy-id": 'cancel-button', onClick: action }, { children: _jsx(CancelIcon, { icon: customIcon || 'closex', width: width, fill: fill }, void 0) }), void 0));
};
CancelButton.defaultProps = {
    fill: theme.palette.iconColor,
};
/** @component */
export default CancelButton;
var templateObject_1, templateObject_2;
