var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import WatchlistArea from 'organisms/WatchlistArea';
import { parse } from 'query-string';
import { withTheme } from 'styled-components';
import localStore from 'store';
import { withRouter } from 'react-router-dom';
import { Wrapper, WatchlistIcon, FooterItemLink, FooterItem, FooterLogo, CartCount, Image } from './styles';
import queryString from 'query-string';
var WatchlistInfo = /** @class */ (function (_super) {
    __extends(WatchlistInfo, _super);
    function WatchlistInfo(props) {
        var _this = _super.call(this, props) || this;
        _this.deviceId = localStore.get('deviceId');
        return _this;
    }
    WatchlistInfo.prototype.toggleWatchlist = function () {
        this.props.toggleWatchlist();
    };
    WatchlistInfo.prototype.render = function () {
        var _this = this;
        var _a = this.props, checkoutNumber = _a.checkoutNumber, clearCheckoutNumber = _a.clearCheckoutNumber, session = _a.session, watchlist = _a.watchlist, getWatchlist = _a.getWatchlist, deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, isOpen = _a.isOpen, checkoutPos = _a.checkoutPos, createSession = _a.createSession, location = _a.location, hasFilters = _a.hasFilters, theme = _a.theme, match = _a.match;
        var isResultPage = location.pathname.match("/" + session.uuid + "/results");
        var params = location.search;
        var primaryCategoryId = this.props.match.params.primaryCategoryId;
        var paramsObj = parse(params);
        var sortIcon = undefined !== paramsObj.sort ? 'sortActive' : 'sort';
        var _b = queryString.parse(params), category = _b.category, primaryCategory = _b.primaryCategory, rest = __rest(_b, ["category", "primaryCategory"]);
        var queryStringExceptCategory = queryString.stringify(rest);
        var filterIcon = hasFilters || queryStringExceptCategory ? 'filterActive' : 'filter';
        return (_jsxs(Wrapper, { children: [_jsx(FooterItemLink, __assign({ "data-cy-id": 'action-bar-show-all-article', to: "/" + session.uuid + "/scan" }, { children: _jsx(WatchlistIcon, { height: '50', icon: 'scan' }, void 0) }), void 0), isResultPage && (_jsx(FooterItemLink, __assign({ "data-cy-id": 'action-bar-show-all-article', to: "/" + session.uuid + "/sorts" + (params || '') }, { children: _jsx(WatchlistIcon, { height: '50', icon: sortIcon }, void 0) }), void 0)), _jsx(FooterLogo, { children: _jsx(FooterItemLink, __assign({ "data-cy-id": 'action-bar-show-all-article', to: '/', style: { width: '100%' } }, { children: _jsx(Image, { src: theme.logoUrl }, void 0) }), void 0) }, void 0), isResultPage || primaryCategoryId ? (_jsx(FooterItemLink, __assign({ "data-cy-id": 'action-bar-show-all-article', to: "/" + session.uuid + "/filters" + (primaryCategoryId ? "/" + primaryCategoryId + "/" + params : "" + params || "/" + (primaryCategoryId || '')) }, { children: _jsx(WatchlistIcon, { height: '50', icon: filterIcon }, void 0) }), void 0)) : null, _jsxs(FooterItem, __assign({ onClick: function () { return _this.toggleWatchlist(); } }, { children: [_jsx(WatchlistIcon, { height: '50', icon: 'cart' }, void 0), _jsx(CartCount, { children: Boolean(watchlist) ? watchlist.length : 0 }, void 0)] }), void 0), _jsx(WatchlistArea, { checkoutPos: checkoutPos, checkoutNumber: checkoutNumber, clearCheckoutNumber: clearCheckoutNumber, createSession: createSession, deleteWatchlist: deleteWatchlist, deleteWatchlistItem: deleteWatchlistItem, deviceId: this.deviceId, toggleWatchlist: function () { return _this.toggleWatchlist(); }, sessionId: session.uuid, visible: isOpen, watchlist: watchlist, getWatchlist: getWatchlist }, void 0)] }, void 0));
    };
    return WatchlistInfo;
}(Component));
WatchlistInfo.defaultProps = {
    hasFilters: false,
};
export default withTheme(withRouter(WatchlistInfo));
