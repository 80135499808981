// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    id: null,
    loaded: false,
    error: null,
});
var reducer = handleActions({
    SET_IDENTITY: function (state) { return state.set('error', null); },
    SET_IDENTITY_COMPLETE: {
        next: function (state, action) {
            return state
                .set('id', action.payload)
                .set('error', null)
                .set('loaded', true);
        },
        throw: function (state, action) {
            return state.set('error', action.error).set('loaded', false);
        },
    },
}, initialState);
export default reducer;
