import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { useHistory } from 'react-router';
import localStore from 'store';
import { parse, stringify } from 'query-string';
import { defineMessages, useIntl } from 'react-intl';
import { getUUID } from '@shared/utils/auth';
import Page from '@shared/molecules/NotFound';
var NotFoundContainer = function () {
    var history = useHistory();
    var intl = useIntl();
    var hasSessionId = !!getUUID(window.location.pathname);
    var sessionId = localStore.get('sessionId');
    var msg = defineMessages({
        notFound: {
            defaultMessage: 'Oops! Page not found',
            id: 'page.not.found',
        },
    });
    if (!hasSessionId && sessionId !== undefined) {
        var parsedSearch = parse(window.location.search);
        var search = '';
        // Attach additional search queries when available
        if (Object.keys(parsedSearch).length > 1) {
            // Delete first one as it is the deviceId
            delete parsedSearch[Object.keys(parsedSearch)[0]];
            search = "?" + stringify(parsedSearch);
        }
        // Workaround to give the history an url if the user clicks back
        history.push("/" + sessionId + "/search");
        history.push("/" + sessionId + history.location.pathname + search);
    }
    return _jsx(Page, { notFoundMessage: intl.formatMessage(msg.notFound) }, void 0);
};
export default NotFoundContainer;
