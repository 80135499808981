var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { key, palette, fontWeight } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n"], ["\n  margin-bottom: 1.25rem;\n"])));
export var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.25rem;\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  padding: 1.25rem 0.9375rem;\n  text-align: center;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    font-size: 1.5rem;\n  }\n"], ["\n  color: ", ";\n  font-size: 1.25rem;\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  padding: 1.25rem 0.9375rem;\n  text-align: center;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    font-size: 1.5rem;\n  }\n"])), palette('text'), fontWeight('bold'), key('breakpoints.medium'));
export var Slide = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 60% !important;\n  padding-left: 0.9375rem;\n"], ["\n  width: 60% !important;\n  padding-left: 0.9375rem;\n"])));
export var SimilarWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  min-height: 0;\n  min-width: 0;\n"], ["\n  grid-column: 1 / 3;\n  min-height: 0;\n  min-width: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
