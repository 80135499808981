var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import LinkWrapper from 'molecules/LinkWrapper';
import MenuIcon from '@shared/atoms/MenuIcon';
import { Wrapper, Action, TextWrapper, StyledText, IconWrapperPure, NoLinkWrapper, CartBadge, CartBadgeCount, } from './styles';
var DashboardActionBar = function (_a) {
    var session = _a.session, watchlistCount = _a.watchlistCount, toggleWatchlist = _a.toggleWatchlist;
    var msgs = defineMessages({
        watchlist: {
            defaultMessage: 'Warenkorb',
            id: 'dashboard.actions.watchlist',
        },
        search: {
            defaultMessage: 'Stöbern',
            id: 'dashboard.actions.search',
        },
        scan: {
            defaultMessage: 'Scanne den Barcode',
            id: 'dashboard.actions.scan',
        },
        articles: {
            defaultMessage: 'Artikel',
            id: 'dashboard.actions.watchlist.articles',
        },
        scanFeet: {
            defaultMessage: 'Fußvermessung',
            id: 'dashboard.actions.scanFeet',
        },
    });
    return (_jsxs(Wrapper, { children: [_jsx(LinkWrapper, __assign({ "data-cy-id": 'action-bar-show-all-article', to: "/" + session.uuid + "/search" }, { children: _jsxs(Action, { children: [_jsx(IconWrapperPure, { children: _jsx(MenuIcon, { height: '75', icon: 'searchNew' }, void 0) }, void 0), _jsx(TextWrapper, { children: _jsx(StyledText, __assign({ wrapped: true }, { children: _jsx(FormattedMessage, __assign({}, msgs.search), void 0) }), void 0) }, void 0)] }, void 0) }), void 0), _jsx(LinkWrapper, __assign({ "data-cy-id": 'action-bar-show-all-article', to: "/" + session.uuid + "/scan" }, { children: _jsxs(Action, { children: [_jsx(IconWrapperPure, { children: _jsx(MenuIcon, { height: '75', icon: 'scan' }, void 0) }, void 0), _jsx(TextWrapper, { children: _jsx(StyledText, __assign({ wrapped: true }, { children: _jsx(FormattedMessage, __assign({}, msgs.scan), void 0) }), void 0) }, void 0)] }, void 0) }), void 0), _jsx(NoLinkWrapper, __assign({ onClick: function () { return toggleWatchlist(); } }, { children: _jsxs(Action, { children: [_jsx(IconWrapperPure, { children: _jsx(MenuIcon, { height: '75', icon: 'cart' }, void 0) }, void 0), _jsx(TextWrapper, { children: _jsxs(StyledText, __assign({ wrapped: true }, { children: [_jsx(FormattedMessage, __assign({}, msgs.watchlist), void 0), _jsxs(CartBadge, { children: [_jsx(CartBadgeCount, { children: watchlistCount }, void 0), _jsx(FormattedMessage, __assign({}, msgs.articles), void 0)] }, void 0)] }), void 0) }, void 0)] }, void 0) }), void 0)] }, void 0));
};
export default DashboardActionBar;
