import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import sagas from './sagas';
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
var configureStore = function (initialState, services) {
    if (services === void 0) { services = {}; }
    var sagaMiddleware = createSagaMiddleware();
    var store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));
    sagaMiddleware.run(sagas, services);
    return store;
};
export default configureStore;
