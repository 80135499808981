// @flow
import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    checkoutNumber: null,
    data: List(),
    loaded: false,
    loading: false,
});
var updateSession = function (session) { return function (existingSessions) {
    // $FlowIgnoreNextLine
    var index = existingSessions.findIndex(function (existingSession) { return existingSession.get('uuid') === session.get('uuid'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existingSessions.push(session);
    }
    // $FlowIgnoreNextLine
    return existingSessions.set(index, session);
}; };
var reducer = handleActions({
    CHECKOUT_POS: function (state) { return state.set('checkingOut', true); },
    CHECKOUT_COMPLETE: {
        next: function (state, action) {
            return state
                .set('checkingOut', false)
                .set('checkoutNumber', action.payload.checkoutNumber);
        },
    },
    GET_SESSION: function (state) { return state.set('loading', true); },
    GET_SESSION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    CLEAR_CHECKOUT_NUMBER: function (state) {
        return state.delete('checkoutNumber');
    },
}, initialState);
export default reducer;
