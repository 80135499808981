var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomerDataForm from 'atoms/CustomerDataForm';
import Watchlist from 'organisms/Watchlist';
import PaypalMethod from 'molecules/PaypalMethod';
import paymentTypes from 'services/enums/paymentMethods';
import { deliveryModes } from '@shared/utils/constants';
import { getPriceString } from '@shared/utils/currency';
import localStore from 'store';
import { Wrapper, FullWidthRow, BranchWrapper, WatchlistWrapper, StyledButton, PaymentInfoTitle, PaymentInfoAmount, } from './styles';
import { useHistory } from 'react-router';
var ClickAndReserve = function (_a) {
    var _b;
    var store = _a.store, sessionId = _a.sessionId, createSession = _a.createSession, watchlist = _a.watchlist, requestAction = _a.requestAction, createSession = _a.createSession, cartPrice = _a.cartPrice, theme = _a.theme;
    var history = useHistory();
    var deviceId = localStore.get('deviceId');
    var isManufacturerOrder = localStore.get('watchlistStatus') === 'orderable';
    var _c = useState(undefined), customerName = _c[0], setCustomerName = _c[1];
    var _d = useState(undefined), customerEmail = _d[0], setCustomerEmail = _d[1];
    var _e = useState('startReservation'), step = _e[0], setStep = _e[1];
    // Handle reservation
    var handleReservation = function (sessionId) { return __awaiter(void 0, void 0, void 0, function () {
        var nameParts, firstname, lastname, email, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    nameParts = customerName === null || customerName === void 0 ? void 0 : customerName.split(' ');
                    firstname = (_b = (_a = nameParts === null || nameParts === void 0 ? void 0 : nameParts.slice(0, -1)) === null || _a === void 0 ? void 0 : _a.join('')) !== null && _b !== void 0 ? _b : '';
                    lastname = (_d = (_c = nameParts === null || nameParts === void 0 ? void 0 : nameParts.slice(-1)) === null || _c === void 0 ? void 0 : _c[0]) !== null && _d !== void 0 ? _d : '';
                    email = customerEmail;
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, requestAction({ firstname: firstname, lastname: lastname, email: email }, sessionId, 'reserve')];
                case 2:
                    _e.sent();
                    createSession(deviceId);
                    history.push("/" + sessionId + "/thank-you");
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    console.error('Reservation failed:', error_1);
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var renderCartInfo = function (border) {
        if (border === void 0) { border = 'bottom'; }
        var deliveryMode = (history.location.state &&
            history.location.state.deliveryMode) ||
            null;
        var shipping = deliveryMode === deliveryModes.MANUFACTURE_ORDER
            ? 0.0
            : parseFloat(theme.shipping);
        return (_jsxs(React.Fragment, { children: [_jsx(PaymentInfoTitle, { children: _jsx(FormattedMessage, { defaultMessage: 'Preis des Warenkorbs', id: 'clickAndCollect.basketPrice' }, void 0) }, void 0), _jsx(PaymentInfoAmount, { children: getPriceString(cartPrice) }, void 0), _jsx(PaymentInfoTitle, { children: _jsx(FormattedMessage, { defaultMessage: 'Versand', id: 'clickAndCollect.shipping' }, void 0) }, void 0), _jsx(PaymentInfoAmount, { children: getPriceString(shipping) }, void 0), _jsx(PaymentInfoTitle, { children: _jsx(FormattedMessage, { defaultMessage: 'GesamteSumme', id: 'clickAndCollect.totalPrice' }, void 0) }, void 0), _jsx(PaymentInfoAmount, { children: getPriceString(shipping + cartPrice) }, void 0)] }, void 0));
    };
    var renderStore = function (border) {
        if (border === void 0) { border = 'bottom'; }
        return (_jsxs(BranchWrapper, __assign({ borderLocation: border }, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { defaultMessage: 'Abholfiliale', id: 'clickAndCollect.pickupBranch' }, void 0) }, void 0), store && (_jsxs(React.Fragment, { children: [_jsx("p", { children: store.name }, void 0), _jsx("p", { children: store.street }, void 0), _jsxs("p", { children: [store.zip, " ", store.city] }, void 0)] }, void 0))] }), void 0));
    };
    var clientId = (_b = theme.paypal) === null || _b === void 0 ? void 0 : _b.clientId;
    var currency = localStore.get('currency') || 'EUR';
    var sign = localStore.get('currencySign') || '€';
    return (_jsxs(Wrapper, { children: [step === 'startReservation' && (_jsxs(React.Fragment, { children: [isManufacturerOrder && renderCartInfo(), _jsx(FullWidthRow, { children: renderStore() }, void 0), _jsxs(FullWidthRow, { children: [!isManufacturerOrder && (_jsx(CustomerDataForm, { onSubmit: function (values) {
                                    var name = values.name, eMail = values.eMail;
                                    setCustomerName(name && name.trim() || '');
                                    setCustomerEmail(eMail.trim());
                                    setStep('confirmReservation');
                                } }, void 0)), isManufacturerOrder && (_jsxs(React.Fragment, { children: [_jsx(PaypalMethod, { sessionId: sessionId, deviceId: deviceId, clientId: clientId, currency: currency, createSession: createSession, redirectAfterCheckout: true, paymentType: paymentTypes.clickAndReserve, deliveryMode: deliveryModes.MANUFACTURE_ORDER }, void 0), _jsx(FormattedMessage, { defaultMessage: 'Du wirst an Paypal weitergeleitet, um den Bezahlvorgang abzuschlie\u00DFen.', id: 'clickAndCollect.paypalRedirectMsg' }, void 0)] }, void 0))] }, void 0)] }, void 0)), step === 'confirmReservation' && (_jsxs(React.Fragment, { children: [_jsx(WatchlistWrapper, { children: _jsx(Watchlist, { watchlist: watchlist, readonly: true }, void 0) }, void 0), _jsx(FullWidthRow, { children: renderStore('top') }, void 0), _jsx(FullWidthRow, { children: _jsx(StyledButton, __assign({ onClick: function () { return handleReservation(sessionId); } }, { children: _jsx(FormattedMessage, { defaultMessage: 'Reservierung senden', id: 'clickAndReserve.confirmReservation' }, void 0) }), void 0) }, void 0)] }, void 0))] }, void 0));
};
export default ClickAndReserve;
