export var idleTime = 1000 * 60 * 5;
export var pollInterval = 3000 * 10;
export var checkInterval = 1000 * 5;
export var pageSize = 20;
export var rare = ['inStock', 'inDifferentStore', 'orderable', 'scheduled'];
export var available = ['available'];
export var availablities = available.concat(rare);
export var MOCK_API_URL = '';
export var assistantAppUrl = '';
export var processActionTypes = {
    CHECKOUT: 'checkout',
    RESERVE: 'reserve',
    REQUEST: 'request',
    REQUEST_STOCK: 'requestStock',
    SHARE: 'share',
    ORDER: 'order',
    ORDER_DELIVERY: 'order/delivery',
    REQUEST_CUSTOMER_ADD: 'customer/add?ref=request',
    RESERVE_CUSTOMER_ADD: 'customer/add?ref=reserve',
    CHECKOUT_COMPLETE: 'checkout/complete',
};
export var paymentSteps = {
    CART: 'cart',
    CHECKOUT: 'checkout',
    PAYMENT: 'payment',
};
export var paymentMethods = {
    POS: 'pos',
    PAYPAL: 'Paypal',
};
export var inactiveTimeout = 5 * 60; // 5 min
export var appNames = {
    TERMINAL: 'terminal',
    CUSTOMER: 'customer',
    ASSISTANT: 'assistant',
};
export var standardWmsSizes = {
    s: 'slim',
    m: 'middle',
    w: 'wide',
};
export var deliveryModes = {
    MANUFACTURE_ORDER: 'manufacturer_order',
    DROPSHIPPING: 'dropshipping',
};
export var connectionErrorMessage = "Die Verbindung zum Server ist zur Zeit unterbrochen. Es wird\nautomatisch versucht, eine neue Verbindung herzustellen. Sobald\neine Verbindung besteht, k\u00F6nnen Sie das Handheld wieder verwenden.";
