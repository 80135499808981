var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  text-align: center;\n  padding-top: 5rem;\n"], ["\n  background: ", ";\n  text-align: center;\n  padding-top: 5rem;\n"])), palette('white'));
export var ThanksMsg = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 1.5;\n"], ["\n  line-height: 1.5;\n"])));
export var Divider = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  text-align: center;\n  margin: 2rem auto;\n  width: 50%;\n  height: 2px;\n  background-color: #000;\n"], ["\n  background: ", ";\n  text-align: center;\n  margin: 2rem auto;\n  width: 50%;\n  height: 2px;\n  background-color: #000;\n"])), palette('white'));
var templateObject_1, templateObject_2, templateObject_3;
