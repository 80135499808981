import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
export var initialState = Map({
    data: List(),
    loadingAll: false,
    loadedAll: false,
    error: false,
});
export var updateDepartment = function (department) { return function (existingDepartments) {
    var index = existingDepartments.findIndex(function (existingDepartment) { return existingDepartment.get('id') === department.get('id'); });
    if (index === -1) {
        return existingDepartments.push(department);
    }
    return existingDepartments.set(index, department);
}; };
var updateDepartments = function (departments) { return function (existingDepartments) {
    if (existingDepartments.size === 0) {
        return departments;
    }
    var newDepartments = existingDepartments;
    departments.forEach(function (department) {
        newDepartments = newDepartments.update(updateDepartment(department));
    });
    return newDepartments;
}; };
var reducer = handleActions({
    GET_DEPARTMENTS: function (state) { return state.set('loadingAll', true); },
    GET_DEPARTMENTS_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', updateDepartments(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
}, initialState);
export default reducer;
