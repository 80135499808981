var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import MenuIcon from '@shared/atoms/MenuIcon';
import LinkWrapper from 'molecules/LinkWrapper';
import { css } from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-around;\n  background-color: ", ";\n  color: ", ";\n  border-top: 0.125rem solid ", ";\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-around;\n  background-color: ", ";\n  color: ", ";\n  border-top: 0.125rem solid ", ";\n"])), palette('white'), palette('text'), palette('border'));
var sharedFooterItem = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 5rem;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  width: 5rem;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"])));
export var FooterItemLink = styled(LinkWrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  font-size: ", ";\n  font-weight: ", ";\n  padding: 0;\n"], ["\n  ", "\n  font-size: ", ";\n  font-weight: ", ";\n  padding: 0;\n"])), sharedFooterItem, fontSize('large'), fontWeight('bold'));
export var FooterItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  position: relative;\n"], ["\n  ", "\n  position: relative;\n"])), sharedFooterItem);
export var FooterLogo = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  width: 33%;\n"], ["\n  ", "\n  width: 33%;\n"])), sharedFooterItem);
export var WatchlistIcon = styled(MenuIcon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 40%;\n  background-color: ", ";\n"], ["\n  width: 40%;\n  background-color: ", ";\n"])), palette('iconColor'));
export var CartCount = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  position: absolute;\n  top: 12%;\n  border-radius: 20px;\n  right: 20%;\n  font-size: ", ";\n  height: 23px;\n  display: flex;\n  width: 23px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  position: absolute;\n  top: 12%;\n  border-radius: 20px;\n  right: 20%;\n  font-size: ", ";\n  height: 23px;\n  display: flex;\n  width: 23px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n"])), palette('highlightText'), palette('white'), fontSize('small'));
export var Image = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
