var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import ControlledSlider from '@shared/molecules/ControlledSlider';
import SliderArticleExtended from 'molecules/SliderArticleExtended';
import { Wrapper, Headline, Slide, SimilarWrapper } from './styles';
var ArticleSlider = /** @class */ (function (_super) {
    __extends(ArticleSlider, _super);
    function ArticleSlider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ArticleSlider.prototype.render = function () {
        var _a = this.props, articles = _a.articles, headline = _a.headline, watchlist = _a.watchlist, session = _a.session;
        return (_jsx(Wrapper, { children: _jsxs(SimilarWrapper, { children: [Boolean(headline) && _jsx(Headline, { children: headline }, void 0), _jsx(ControlledSlider, __assign({ customClass: '', config: { slidesPerView: 'auto', spaceBetween: 0 } }, { children: articles.map(function (article) {
                            return (_jsx(Slide, __assign({ big: true }, { children: _jsx(SliderArticleExtended, { session: session, article: article, active: false }, void 0) }), article.id));
                        }) }), void 0)] }, void 0) }, void 0));
    };
    return ArticleSlider;
}(Component));
export default ArticleSlider;
