var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { key } from '@shared/utils/styled';
import { palette } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #fff;\n  height: 100%;\n  overflow-y: auto;\n"], ["\n  background-color: #fff;\n  height: 100%;\n  overflow-y: auto;\n"])));
export var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 5rem;\n  width: 100%;\n  @media screen and (max-width: ", ") {\n    margin-top: 1.5rem;\n  }\n"], ["\n  text-align: center;\n  margin-top: 5rem;\n  width: 100%;\n  @media screen and (max-width: ", ") {\n    margin-top: 1.5rem;\n  }\n"])), key('breakpoints.xxsmall'));
export var StyledButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  width: 80%;\n"], ["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  width: 80%;\n"])), palette('secondaryHighlight'), palette('white'));
var templateObject_1, templateObject_2, templateObject_3;
