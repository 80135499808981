var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  align-self: stretch;\n  justify-content: center;\n  min-width: 4.375rem;\n"], ["\n  align-items: center;\n  align-self: stretch;\n  justify-content: center;\n  min-width: 4.375rem;\n"])));
export var InnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 15%;\n  padding: 0.3125rem;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 15%;\n  padding: 0.3125rem;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.hasSelections ? palette('activeWhite') : 'transparent';
}, palette('activeWhite'));
var templateObject_1, templateObject_2;
