var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { checkoutPos, createSession, clearCheckoutNumber, } from '@store/session/actions';
import { getWatchlist, deleteWatchlist } from '@store/watchlist/actions';
import BaseLayout from 'templates/BaseLayout';
import WatchlistInfo from 'organisms/WatchlistInfo';
import { selectCheckoutNumber } from '@store/session/selectors';
var SessionLayout = /** @class */ (function (_super) {
    __extends(SessionLayout, _super);
    function SessionLayout(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { openWatchlist: false };
        _this.toggleWatchlist = function () {
            if (_this.props.watchlist.length <= 0 &&
                _this.state &&
                !_this.state.openWatchlist) {
                return;
            }
            _this.setState({
                openWatchlist: !_this.state.openWatchlist,
            });
        };
        return _this;
    }
    SessionLayout.prototype.renderBottomMenu = function () {
        var _a = this.props, bottomMenu = _a.bottomMenu, checkoutNumber = _a.checkoutNumber, checkoutPos = _a.checkoutPos, clearCheckoutNumber = _a.clearCheckoutNumber, createSession = _a.createSession, deleteWatchlistItem = _a.deleteWatchlistItem, hideBottomMenu = _a.hideBottomMenu, isWatchlistOpen = _a.isWatchlistOpen, session = _a.session, watchlist = _a.watchlist, hasFilters = _a.hasFilters, getWatchlist = _a.getWatchlist, deleteWatchlist = _a.deleteWatchlist;
        var openWatchlist = this.state.openWatchlist;
        if (hideBottomMenu) {
            return null;
        }
        if (bottomMenu) {
            return bottomMenu;
        }
        return (_jsx(WatchlistInfo, { checkoutPos: checkoutPos, checkoutNumber: checkoutNumber, clearCheckoutNumber: clearCheckoutNumber, createSession: createSession, deleteWatchlist: deleteWatchlist, deleteWatchlistItem: deleteWatchlistItem, isOpen: isWatchlistOpen || openWatchlist, session: session, toggleWatchlist: this.toggleWatchlist, watchlist: watchlist, hasFilters: hasFilters, getWatchlist: getWatchlist }, void 0));
    };
    SessionLayout.prototype.render = function () {
        var _a = this.props, backgroundColor = _a.backgroundColor, children = _a.children, subMenu = _a.subMenu;
        return (_jsxs(BaseLayout, __assign({ bottomMenu: this.renderBottomMenu(), backgroundColor: backgroundColor }, { children: [subMenu && subMenu, children] }), void 0));
    };
    return SessionLayout;
}(Component));
var mapDispatchToProps = function (dispatch) { return ({
    checkoutPos: function (payload, sessionId) {
        dispatch(checkoutPos(payload, sessionId));
    },
    clearCheckoutNumber: function () { return dispatch(clearCheckoutNumber()); },
    createSession: function (deviceId) { return dispatch(createSession(deviceId)); },
    getWatchlist: function (sessionId) { return dispatch(getWatchlist(sessionId)); },
    deleteWatchlist: function (sessionId) { return dispatch(deleteWatchlist(sessionId)); },
}); };
var select = function () { return function (state) { return ({
    checkoutNumber: selectCheckoutNumber(state),
}); }; };
export default withTheme(connect(select, mapDispatchToProps)(SessionLayout));
