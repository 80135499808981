var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import P from "@shared/atoms/P";
var ModalText = styled(P)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: white;\n  height: 9rem;\n  padding: 1rem;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n  text-align: center;\n  border: 5px solid gray;\n"], ["\n  background-color: white;\n  height: 9rem;\n  padding: 1rem;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n  text-align: center;\n  border: 5px solid gray;\n"])));
/** @component */
export default ModalText;
var templateObject_1;
