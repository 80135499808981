var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
//@ts-nocheck
import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import slice from 'lodash/slice';
import styled from 'styled-components';
import Spinner from '@shared/atoms/Spinner';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100% ;\n  overflow-y: auto;\n  width:100%;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100% ;\n  overflow-y: auto;\n  width:100%;\n  align-items: center;\n"])));
/* NEED-328 */
var InfiniteScroll = /** @class */ (function (_super) {
    __extends(InfiniteScroll, _super);
    function InfiniteScroll() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleLoad = function () {
            if (!_this.props.loading && _this.props.hasMore) {
                _this.props.loadMore(_this.props.currentPage + 1);
            }
        };
        _this.getContent = function () {
            var _a = _this.props, hasMore = _a.hasMore, items = _a.items, loading = _a.loading, waypointAsElement = _a.waypointAsElement;
            var visibleItems = items;
            var lastItem; // optional
            var content = [visibleItems];
            /* needs pagination */
            if (hasMore) {
                /* slice items if last element should be waypoint */
                if (waypointAsElement) {
                    visibleItems = slice(items, 0, -1);
                    lastItem = items[items.length - 1];
                    content.push(_jsx(Waypoint, __assign({ onEnter: _this.handleLoad, bottomOffset: '-100px' }, { children: _jsx("div", { children: lastItem }, void 0) }), 1));
                }
                else {
                    content.push(_jsx(Waypoint, { onEnter: _this.handleLoad, bottomOffset: '-100px' }, 1));
                }
            }
            loading && content.push(_jsx(Spinner, {}, 2));
            return content;
        };
        _this.wrapContent = function (content) {
            var CustomWrapper = _this.props.wrapper;
            return CustomWrapper ? (_jsx(CustomWrapper, { children: content }, void 0)) : (_jsx(Wrapper, __assign({ height: _this.props.height }, { children: content }), void 0));
        };
        return _this;
    }
    InfiniteScroll.prototype.render = function () {
        return this.wrapContent(this.getContent());
    };
    return InfiniteScroll;
}(Component));
/** @component */
export default InfiniteScroll;
var templateObject_1;
