var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import { Component } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import { injectIntl, defineMessages } from 'react-intl';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import DropDownHandler from './components/DropDownHandler';
import { Wrapper } from './components/DropDownStyles';
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Filter.prototype.render = function () {
        var _a = this.props, deleteWatchlistItem = _a.deleteWatchlistItem, intl = _a.intl, params = _a.params, session = _a.session, watchlist = _a.watchlist;
        var msg = defineMessages({
            filter: { defaultMessage: 'Filter', id: 'filter.menu.headline' },
        });
        return (_jsx(SessionLayout, __assign({ deleteWatchlistItem: deleteWatchlistItem, session: session, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.filter) }, void 0), backgroundColor: palette('white'), watchlist: watchlist }, { children: _jsx(Wrapper, { children: _jsx(DropDownHandler, {}, void 0) }, void 0) }), void 0));
    };
    return Filter;
}(Component));
export var FilterComponent = Filter;
export default injectIntl(Filter);
