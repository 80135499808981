var emailExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var requiredValidator = function (value) { return (value ? undefined : 'Pflichtfeld'); };
export var nameValidator = function (value) {
    return /(\s)/.test(value) ? undefined : 'Bitte gib deinen vollen Namen ein';
};
export var mailValidator = function (value) {
    return emailExpression.test(value.trim())
        ? undefined
        : 'Bitte gib eine gültige E-Mail Adresse ein';
};
