var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toJS } from '@shared/utils/immutable';
import { parse } from 'query-string';
import localStore from 'store';
// actions
import { getArticles } from '@store/article/actions';
import { resetTempFilterSelections } from '@store/filterSelection/actions';
import { getPrimaryCategories } from '@store/primaryCategory/actions';
import { getCategory, getCategories } from '@store/category/actions';
// selectors
import { selectArticles, selectTotal, selectArticlePageCount, selectLoadingAll as selectLoadingAllArticles, } from '@store/article/selectors';
import { selectFilterDescription } from '@store/filterSelection/selectors';
import { selectCategory, selectLoading as selectLoadingCat, } from '@store/category/selectors';
import { selectPrimaryCategory, selectShouldLoad as selectShouldLoadPriCats, } from '@store/primaryCategory/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/Result';
var Result = /** @class */ (function (_super) {
    __extends(Result, _super);
    function Result(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentPage: 0,
            filters: ""
        };
        _this.getTitle = function () {
            var _a = _this.props, primaryCategory = _a.primaryCategory, category = _a.category, location = _a.location;
            var params = parse(location.search, { arrayFormat: 'bracket' });
            var searchTitle = Boolean(params.search)
                ? "Suche: '" + params.search + "'"
                : 'Suche';
            var singleTitle = !Boolean(primaryCategory) && Boolean(category)
                ? category.displayName
                : searchTitle;
            var title = Boolean(primaryCategory) && Boolean(category)
                ? // $FlowIgnoreNextLine
                    primaryCategory.displayName + ": " + category.displayName
                : singleTitle;
            return title;
        };
        _this.fetch = function (params) {
            var pageParams = parse(_this.props.location.search);
            var pageparamfilter = __rest(pageParams
            // Show the Active filter Icon and Filters Value @Start
            , []);
            // Show the Active filter Icon and Filters Value @Start
            delete pageparamfilter["category"];
            delete pageparamfilter["primaryCategory"];
            var pageParamsFilters = Object.values(pageparamfilter).map(function (item) { return item; });
            // Show the Active filter Icon and Filters Value @End
            _this.setState({ currentPage: _this.state.currentPage + 1, filters: pageParamsFilters.toString() });
            // NEED-458
            var sizeEu = localStore.get('sizeEu');
            var sizeUk = localStore.get('sizeUk');
            var sizeUs = localStore.get('sizeUs');
            var sizeUsMen = localStore.get('sizeUsMen');
            var sizeUsWomen = localStore.get('sizeUsWomen');
            var sizeUsKids = localStore.get('sizeUsKids');
            if (sizeEu) {
                pageParams.sizeEu = sizeEu;
            }
            if (sizeUs) {
                pageParams.sizeUs = sizeUs;
            }
            if (sizeUsMen) {
                pageParams.sizeUsMen = sizeUsMen;
            }
            if (sizeUsWomen) {
                pageParams.sizeUsWomen = sizeUsWomen;
            }
            if (sizeUsKids) {
                pageParams.sizeUsKids = sizeUsKids;
            }
            if (sizeUk) {
                pageParams.sizeUk = sizeUk;
            }
            // NEED-458 End
            if (!_this.props.articlesLoading) {
                _this.props.getArticles(__assign(__assign({}, pageParams), params));
            }
        };
        return _this;
    }
    Result.prototype.componentDidMount = function () {
        var _a = this.props, location = _a.location, shouldLoadPriCats = _a.shouldLoadPriCats, isLoadingCategory = _a.isLoadingCategory;
        this.props.resetTempFilterSelections();
        var params = parse(location.search, {
            arrayFormat: 'bracket',
        });
        if (Boolean(params.primaryCategory) && shouldLoadPriCats) {
            this.props.getPrimaryCategories();
        }
        if (Boolean(params.category) &&
            Boolean(params.primaryCategory) &&
            !isLoadingCategory) {
            this.props.getCategories({
                page: this.state.currentPage,
                primaryCategory: params.primaryCategory,
            });
        }
    };
    Result.prototype.render = function () {
        var _this = this;
        var _a = this.props, articlePageCount = _a.articlePageCount, articlesTotalCount = _a.articlesTotalCount, articles = _a.articles, articlesLoading = _a.articlesLoading, filterDescription = _a.filterDescription, location = _a.location, primaryCategory = _a.primaryCategory;
        var _b = this.state, currentPage = _b.currentPage, filters = _b.filters;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { title: _this.getTitle(), articlePage: currentPage, articlePageCount: articlePageCount, articlesTotalCount: articlesTotalCount, articles: articles, articlesLoading: articlesLoading, fetch: function (params) { return _this.fetch(params); }, filterDescription: filters, params: location.search, primaryCategory: primaryCategory }), void 0)); } }, void 0));
    };
    return Result;
}(Component));
var select = function () { return function (state, props) { return ({
    articlePageCount: selectArticlePageCount(state),
    articlesTotalCount: selectTotal(state),
    articles: selectArticles(state),
    articlesLoading: selectLoadingAllArticles(state),
    category: selectCategory()(state, props),
    filterDescription: selectFilterDescription(state),
    isLoadingCategory: selectLoadingCat(state),
    primaryCategory: selectPrimaryCategory()(state, props),
    shouldLoadPriCats: selectShouldLoadPriCats(state),
}); }; };
export var ResultContainer = Result;
export default withRouter(connect(select, {
    getArticles: getArticles,
    getCategory: getCategory,
    getCategories: getCategories,
    getPrimaryCategories: getPrimaryCategories,
    resetTempFilterSelections: resetTempFilterSelections,
})(toJS(Result)));
