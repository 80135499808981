var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
export var PaypalButtons = function (props) {
    var _a = useState(false), sdkReady = _a[0], setSdkReady = _a[1];
    var _b = __assign({}, props), clientId = _b.clientId, onButtonReady = _b.onButtonReady, createOrder = _b.createOrder, onApprove = _b.onApprove;
    var currency = props.currency || 'EUR';
    var _c = useState(false), error = _c[0], setError = _c[1];
    var _d = useState(''), errorMessage = _d[0], setErrorMessage = _d[1];
    var addPaypalSdk = function () {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = "https://www.paypal.com/sdk/js?client-id=" + clientId + "&currency=" + currency;
        script.async = true;
        script.onload = function () {
            setSdkReady(true);
        };
        script.onerror = function () {
            // throw new Error('Paypal SDK could not be loaded.');
            console.error("Paypal SDK could not be loaded.");
        };
        document.body.appendChild(script);
    };
    var onError = function (e) {
        setError(true);
        setErrorMessage(e);
    };
    useEffect(function () {
        if (window !== undefined && window.paypal === undefined) {
            addPaypalSdk();
        }
        else if (window !== undefined &&
            window.paypal !== undefined &&
            onButtonReady) {
            onButtonReady();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!sdkReady && window.paypal === undefined) {
        return _jsx("div", { children: "Loading..." }, void 0);
    }
    var Button = window.paypal.Buttons.driver('react', {
        React: React,
        ReactDOM: ReactDOM,
    });
    return (_jsxs(React.Fragment, { children: [!error && (_jsx(Button, __assign({}, props, { createOrder: createOrder, onApprove: function (data) {
                    onApprove(data);
                }, onError: function (e) { return onError(e); }, style: {
                    shape: 'pill',
                    label: 'checkout',
                    layout: 'horizontal',
                } }), void 0)), error && (_jsxs("div", { children: ["Es ist ein Fehler aufgetreten. Bitte w\u00E4hle eine andere Zahlart oder wende dich an den Kundendienst (", errorMessage, ")"] }, void 0))] }, void 0));
};
