import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
import { createSession } from '@store/session/actions';
import { requestAction } from '@store/action/actions';
import SessionContainer from 'containers/SessionContainer';
import Page from '../../components/pages/ClickAndReserve';
import { selectWatchlist, selectCartPrice } from '@store/watchlist/selectors';
import { selectThemeLoading } from '@store/theme/selectors';
var ClickAndReserve = function (_a) {
    var createSession = _a.createSession, requestAction = _a.requestAction, cartPrice = _a.cartPrice, isThemeLoading = _a.isThemeLoading;
    var handleCreateSession = function (deviceId) {
        createSession(deviceId);
    };
    var handleRequestAction = function (values, sessionId, ref, resolve, reject) {
        requestAction(values, sessionId, ref, resolve, reject);
    };
    return (_jsx(SessionContainer, { children: function (_a) {
            var session = _a.session, watchlist = _a.watchlist;
            return (_jsx(Page, { session: session, watchlist: watchlist, createSession: handleCreateSession, requestAction: handleRequestAction, cartPrice: cartPrice, isThemeLoading: isThemeLoading }, void 0));
        } }, void 0));
};
var mapStateToProps = function (state) { return ({
    watchlist: selectWatchlist(state),
    cartPrice: selectCartPrice(state),
    isThemeLoading: selectThemeLoading(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createSession: function (deviceId) { return dispatch(createSession(deviceId)); },
    requestAction: function (values, sessionId, ref, resolve, reject) { return dispatch(requestAction(values, sessionId, ref, resolve, reject)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(toJS(ClickAndReserve));
