var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import { palette } from '@shared/utils/styled';
// components
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import ClickNReserve from 'molecules/ClickAndReserve';
import { useHistory } from 'react-router';
var ClickAndReserve = function (_a) {
    var session = _a.session, createSession = _a.createSession, requestAction = _a.requestAction, watchlist = _a.watchlist, cartPrice = _a.cartPrice;
    var history = useHistory();
    return (_jsx(SessionLayout, __assign({ session: session, backgroundColor: palette('white'), subMenu: _jsx(SubMenu, { cancelIcon: _jsx(BackButtonSmall, { action: function () { return history.push("/" + session.uuid); }, fill: 'white', withText: true }, void 0), title: 'Click & Reserve' }, void 0), hideBottomMenu: true }, { children: _jsx(ClickNReserve, { sessionId: session.uuid, store: session.store, createSession: createSession, watchlist: watchlist, requestAction: requestAction, cartPrice: cartPrice }, void 0) }), void 0));
};
export default ClickAndReserve;
