import { createSelector } from 'reselect';
export var selectState = function (state) { return state.get('sort'); };
export var selectSorts = createSelector(selectState, function (sortState) {
    return sortState.get('data');
});
export var selectSortedSorts = createSelector(selectSorts, function (sorts) {
    return sorts.sortBy(function (sort) { return sort.get('sort'); });
});
export var shouldLoadSorts = createSelector(selectState, function (state) { return !state.get('loaded') && !state.get('loading'); });
export var selectLoading = createSelector(selectState, function (sortState) {
    return sortState.get('loading');
});
export var selectLoaded = createSelector(selectState, function (sortState) {
    return sortState.get('loaded');
});
