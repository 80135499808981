var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import WatchlistItem from 'organisms/WatchlistItem';
import { FormattedMessage } from 'react-intl';
import { Wrapper, StyledButton, ButtonWrapper } from './styles';
import localStore from 'store';
var Watchlist = /** @class */ (function (_super) {
    __extends(Watchlist, _super);
    function Watchlist() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Watchlist.prototype.resetSession = function () {
        this.props.createSession(this.props.sessionId);
        this.props.history.push('/');
    };
    Watchlist.prototype.render = function () {
        var _this = this;
        var _a = this.props, watchlist = _a.watchlist, deleteWatchlistItem = _a.deleteWatchlistItem, sessionId = _a.sessionId, toggleWatchlist = _a.toggleWatchlist, readonly = _a.readonly;
        var watchlistStatus = localStore.get('watchlistStatus');
        return (_jsxs(Wrapper, { children: [watchlist &&
                    orderBy(watchlist, 'createdAt', 'desc').map(function (watchlistItem, index) { return (_jsx(WatchlistItem, { deleteWatchlistItem: deleteWatchlistItem, item: watchlistItem, index: index, sessionId: sessionId, toggleWatchlist: toggleWatchlist, readonly: readonly || 'available' !== watchlistStatus }, watchlistItem.id)); }), 'orderable' === watchlistStatus && (_jsx(ButtonWrapper, { children: _jsx(StyledButton, __assign({ onClick: function () { return _this.resetSession(); } }, { children: _jsx(FormattedMessage, { id: 'watchlist.reset', defaultMessage: 'Transaktion abbrechen' }, void 0) }), void 0) }, void 0))] }, void 0));
    };
    return Watchlist;
}(Component));
export default withRouter(Watchlist);
