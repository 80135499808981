var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';
import { Divider, ThanksMsg, Wrapper } from './styles';
import ThankYouDeleteWatchListWrapper from "organisms/ThankYou/ThankYouDeleteWatchListWrapper";
var ThankYou = /** @class */ (function (_super) {
    __extends(ThankYou, _super);
    function ThankYou(props) {
        return _super.call(this, props) || this;
    }
    ThankYou.prototype.render = function () {
        var session = this.props.session;
        return (_jsxs(Wrapper, { children: [_jsx(ThankYouDeleteWatchListWrapper, { sessionId: session === null || session === void 0 ? void 0 : session.uuid }, void 0), _jsx(ThanksMsg, { children: _jsx(FormattedMessage, { defaultMessage: 'Vielen Dank f\u00FCr deine Bestellung', id: 'thankyou.page.thanksMsg' }, void 0) }, void 0), _jsx(Divider, {}, void 0), _jsx("h3", { children: _jsx(FormattedMessage, { defaultMessage: "Wie geht's jetzt weiter?", id: 'thankyou.page.hru' }, void 0) }, void 0), _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: 'Du erh\u00E4ltst in K\u00FCrze eine E-Mail mit der Information, dass die Ware\n            f\u00FCr dich bereit ist.', id: 'thankyou.page.mailNotify' }, void 0) }, void 0), _jsx(Divider, {}, void 0), _jsx("h3", { children: _jsx(FormattedMessage, { defaultMessage: 'Weitershoppen', id: 'thankyou.page.continueShopping' }, void 0) }, void 0), _jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: 'Klick einfach auf das Logo und lass dich inspirieren', id: 'thankyou.page.backMsg' }, void 0) }, void 0)] }, void 0));
    };
    return ThankYou;
}(Component));
export default withTheme(withRouter(injectIntl(ThankYou)));
