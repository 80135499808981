var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { noop } from 'lodash';
export var metaCreator = function (metaFields, resolve, reject) {
    if (resolve === void 0) { resolve = noop; }
    if (reject === void 0) { reject = noop; }
    return (__assign(__assign({}, metaFields), { resolve: resolve, reject: reject }));
};
