var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { withRouter } from "react-router-dom";
import { Description, Headline, Info, InnerWrapper, ThumbnailImage, Wrapper } from "./styles";
import SearchResultPrice from "src/customer/searchResultPrice/components/SearchResultPrice";
import PriceTag from "@shared/molecules/PriceTag";
;
var ArticleListItem = function (_a) {
    var _b, _c, _d;
    var article = _a.article, history = _a.history, basePath = _a.basePath, full = _a.full, params = _a.params;
    var color = article.color, id = article.id, brand = article.brand;
    var brandName = brand === null || brand === void 0 ? void 0 : brand.name;
    return (_jsx(Wrapper, __assign({ "data-cy-id": "article-list-item", full: full, id: "" + id }, { children: _jsxs(InnerWrapper, __assign({ onClick: function () { return history.push("" + basePath + id + params); } }, { children: [_jsx(ThumbnailImage, { children: _jsx("img", { src: ((_b = article === null || article === void 0 ? void 0 : article.images) === null || _b === void 0 ? void 0 : _b.thumbnail) || ((_d = (_c = article === null || article === void 0 ? void 0 : article.images) === null || _c === void 0 ? void 0 : _c.main) === null || _d === void 0 ? void 0 : _d.thumbnail) }, void 0) }, void 0), _jsxs(Info, { children: [_jsx(Headline, { children: brandName || "-" }, void 0), _jsx(Description, {}, void 0), _jsx("div", { children: color || "-" }, void 0), (article === null || article === void 0 ? void 0 : article.price) ? _jsx(SearchResultPrice, { price: article === null || article === void 0 ? void 0 : article.price }, void 0) : _jsx(PriceTag, { prices: article === null || article === void 0 ? void 0 : article.prices, targetPrices: article === null || article === void 0 ? void 0 : article.targetPrices }, void 0)] }, void 0)] }), void 0) }), void 0));
};
export default withRouter(ArticleListItem);
