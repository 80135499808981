var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import AboutUs from 'organisms/AboutUs';
import SubMenu from 'organisms/SubMenu';
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import { Wrapper, Items } from './styles';
var AboutUsArea = function (_a) {
    var theme = _a.theme, toggleAboutUs = _a.toggleAboutUs, visible = _a.visible;
    var intl = useIntl();
    var _b = defineMessages({
        headline: {
            defaultMessage: "\u00DCber uns",
            id: 'aboutUs.headline',
        },
        aboutUsTitle: {
            defaultMessage: "\u00DCber uns",
            id: 'aboutUs.aboutUs',
        },
        conditions: {
            defaultMessage: "AGB",
            id: 'aboutUs.conditions',
        },
        imprint: {
            defaultMessage: "Impressum",
            id: 'aboutUs.imprint',
        },
        deliveryTerms: {
            defaultMessage: "Lieferbedingungen",
            id: 'aboutUs.deliveryTerms',
        },
        cancellationPolicy: {
            defaultMessage: "Widerrufsbelehrung",
            id: 'aboutUs.cancellationPolicy',
        },
        privacyPolicy: {
            defaultMessage: "Datenschutzbestimmungen",
            id: 'aboutUs.privacyPolicy',
        },
    }), headline = _b.headline, aboutUsTitle = _b.aboutUsTitle, conditions = _b.conditions, imprint = _b.imprint, deliveryTerms = _b.deliveryTerms, cancellationPolicy = _b.cancellationPolicy, privacyPolicy = _b.privacyPolicy;
    return (_jsxs(Wrapper, __assign({ visible: visible }, { children: [_jsx(SubMenu, { title: intl.formatMessage(headline), topBorder: true, cancelIcon: _jsx(BackButtonSmall, { action: function () { return toggleAboutUs(); }, fill: 'white', withText: true }, void 0) }, void 0), _jsxs(Items, { children: [theme.aboutUs && (_jsx(AboutUs, { title: intl.formatMessage(aboutUsTitle), text: theme.aboutUs }, void 0)), theme.conditions && (_jsx(AboutUs, { title: intl.formatMessage(conditions), text: theme.conditions }, void 0)), theme.imprint && (_jsx(AboutUs, { title: intl.formatMessage(imprint), text: theme.imprint }, void 0)), theme.deliveryTerms && (_jsx(AboutUs, { title: intl.formatMessage(deliveryTerms), text: theme.deliveryTerms }, void 0)), theme.cancellationPolicy && (_jsx(AboutUs, { title: intl.formatMessage(cancellationPolicy), text: theme.cancellationPolicy }, void 0)), theme.privacyPolicy && (_jsx(AboutUs, { title: intl.formatMessage(privacyPolicy), text: theme.privacyPolicy }, void 0))] }, void 0)] }), void 0));
};
export { AboutUsArea };
