var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var initialState = { id: 0, name: '', type: '' };
var filterReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action === null || action === void 0 ? void 0 : action.type) {
        case 'FILTER_SELECTED':
            return action.payload;
        default:
            return __spreadArray([], state, true);
    }
};
export default filterReducer;
