var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import { Link } from 'react-router-dom';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin-top: ", ";\n"], ["\n  position: relative;\n  margin-top: ", ";\n"])), function (props) { return (props.hasBanner ? '3rem' : 0); });
export var Banner = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  left: 25%;\n  position: absolute;\n  top: -1.75rem;\n  width: 50%;\n"], ["\n  left: 25%;\n  position: absolute;\n  top: -1.75rem;\n  width: 50%;\n"])));
export var Tile = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n  width: 100%;\n  padding-bottom: 0.625rem;\n  display: block;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n  width: 100%;\n  padding-bottom: 0.625rem;\n  display: block;\n"])), palette('text'), fontSize('default'));
export var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-height: 21.875rem;\n"], ["\n  max-height: 21.875rem;\n"])));
export var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 0.9375rem;\n"], ["\n  padding: 0 0.9375rem;\n"])));
export var Descripction = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
