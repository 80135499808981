import { createSelector } from 'reselect';
import { getSearchParams } from '@shared/utils/params';
var selectState = function (state) { return state.get('category'); };
export var selectCategories = createSelector(selectState, function (state) {
    return state.get('data');
});
export var selectSortedCategories = createSelector(selectCategories, function (categories) { return categories.sortBy(function (category) { return category.get('sort'); }); });
var selectCategoryId = function (state, props) {
    var params = getSearchParams(props.location.search);
    return parseInt(params.category, 10);
};
export var selectPageCount = createSelector(selectState, function (state) {
    return state.get('pageCount');
});
export var selectCurrentPage = createSelector(selectState, function (state) {
    return state.get('currentPage');
});
export var selectLoadingAll = createSelector(selectState, function (state) {
    return state.get('loadingAll');
});
export var selectLoading = createSelector(selectState, function (state) {
    return state.get('loading');
});
export var selectCategory = function () {
    return createSelector([selectCategories, selectCategoryId], function (categories, categoryId) {
        return categories.find(function (category) { return category.get('id') === categoryId; });
    });
};
