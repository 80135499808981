var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import reportWebVitals from './reportWebVitals';
import ConnectedIntlProvider from 'providers/ConnectedIntlProvider';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Map, List, fromJS } from 'immutable';
import localStore from 'store';
import qs from 'qs';
import configureStore from '@store/configure';
import history from '@store/history';
import api from './services/api';
import NeedleProvider from 'providers/NeedleProvider';
import { getUUID, getAuthHeader } from '@shared/utils/auth';
var deviceId = getUUID(window.location.search) || localStore.get('deviceId');
var queryParams = qs.parse(window.location.search.toLowerCase());
localStore.remove('sizeEu');
localStore.remove('sizeUsMen');
localStore.remove('sizeUsWomen');
localStore.remove('sizeUsKids');
localStore.remove('sizeUs');
localStore.remove('sizeUk');
localStore.remove('mafId');
localStore.remove('matchnfitGender');
localStore.remove('sizeWms');
if (queryParams.sizeuk) {
    var sizeUk = queryParams.sizeuk.replace(/(\d+)(\.5)/, '$1½');
    localStore.set('sizeUk', sizeUk);
}
if (queryParams.sizeeu) {
    localStore.set('sizeEu', queryParams.sizeeu);
}
if (queryParams.sizeus) {
    var sizeUs = queryParams.sizeus.replace(/(\d+)(\.5)/, '$1½');
    localStore.set('sizeUs', sizeUs);
}
if (queryParams.mafid) {
    localStore.set('mafId', queryParams.mafid);
}
if (queryParams.p) {
    localStore.set('isDirectPayment', true);
}
var mafGender = queryParams.gender;
if (mafGender && mafGender.length === 1) {
    if (mafGender === 'h') {
        localStore.set('matchnfitGender', 1);
    }
    if (mafGender === 'd') {
        localStore.set('matchnfitGender', 2);
    }
    if (mafGender === 'j') {
        localStore.set('matchnfitGender', 3);
    }
    if (mafGender === 'm') {
        localStore.set('matchnfitGender', 4);
    }
}
// NEED-458 End
var sessionId = getUUID(window.location.pathname) || localStore.get('sessionId');
var filterSelections = localStore.get('filterSelections');
var lang = localStore.get('currentLanguage');
var store = configureStore(Map({
    filterSelection: Map({
        data: fromJS(filterSelections) || List(),
        temp: fromJS(filterSelections) || List(),
    }),
    intl: Map({
        currentLanguage: lang || 'de',
    }),
}), {
    api: api.create({ authToken: getAuthHeader(deviceId) }),
});
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(ConnectedIntlProvider, { children: _jsx(Router, __assign({ history: history, basename: '/' }, { children: _jsx(NeedleProvider, __assign({ deviceId: deviceId, sessionId: sessionId }, { children: _jsx(App, {}, void 0) }), void 0) }), void 0) }, void 0) }), void 0) }, void 0), document.getElementById('app'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
