import { createSelector } from 'reselect';
import { getSearchParams } from '@shared/utils/params';
var selectState = function (state) { return state.get('primaryCategory'); };
export var selectPrimaryCategories = createSelector(selectState, function (state) {
    return state.get('data');
});
var selectPrimaryCategoryId = function (state, props) {
    var params = getSearchParams(props.location.search);
    return parseInt(params.primaryCategory, 10);
};
export var selectLoadingAll = createSelector(selectState, function (state) {
    return state.get('loadingAll');
});
export var selectLoadedAll = createSelector(selectState, function (state) {
    return state.get('loadedAll');
});
export var selectShouldLoad = createSelector(selectState, function (state) { return !state.get('loadingAll') && !state.get('loadedAll'); });
export var selectPrimaryCategory = function () {
    return createSelector([selectPrimaryCategories, selectPrimaryCategoryId], function (primaryCategories, primaryCategoryId) {
        return primaryCategories.find(function (primaryCategory) { return primaryCategory.get('id') === primaryCategoryId; });
    });
};
