var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
// swiper core styles
import 'swiper/swiper.min.css';
// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
/* eslint-enable */
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width:100%;\n  .swiper-container-horizontal,\n  .swiper-container-vertical,\n  .swiper-container {\n    height: 100%;\n    padding-left: ", ";\n\n  }\n  .swiper-wrapper {\n    height: 100%;\n  }\n  .swiper-slide {\n    height: auto;\n  }\n  .swiper-button-next,\n  .swiper-button-prev {\n    background-color: ", "; \n    color: black; \n    position: absolute;\n    top: 50%;\n    transform: translateY(50%);\n  }\n\n  .swiper-button-next {\n    right: 5px;\n    top:", "\n  }\n\n  .swiper-button-prev {\n    left: 0;\n    top:", "\n\n  }\n"], ["\n  height: 100%;\n  width:100%;\n  .swiper-container-horizontal,\n  .swiper-container-vertical,\n  .swiper-container {\n    height: 100%;\n    padding-left: ", ";\n\n  }\n  .swiper-wrapper {\n    height: 100%;\n  }\n  .swiper-slide {\n    height: auto;\n  }\n  .swiper-button-next,\n  .swiper-button-prev {\n    background-color: ", "; \n    color: black; \n    position: absolute;\n    top: 50%;\n    transform: translateY(50%);\n  }\n\n  .swiper-button-next {\n    right: 5px;\n    top:", "\n  }\n\n  .swiper-button-prev {\n    left: 0;\n    top:", "\n\n  }\n"])), function (props) { return (props.customPaddingLeft ? props.customPaddingLeft : '0'); }, function (props) { return (props.customPaddingLeft ? 'white' : 'unset'); }, function (props) { return (props.customPaddingLeft ? '0px' : '35px'); }, function (props) { return (props.customPaddingLeft ? '0px' : '35px'); });
var Slider = /** @class */ (function (_super) {
    __extends(Slider, _super);
    function Slider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Slider.prototype.render = function () {
        var defaultConfig = {
            slidesPerView: 1,
            spaceBetween: 4,
        };
        var config = this.props.config ? this.props.config : defaultConfig;
        var _a = this.props, children = _a.children, customPaddingLeft = _a.customPaddingLeft;
        var slides = children &&
            children.map(function (slideContent, index) { return (_jsx(SwiperSlide, __assign({ virtualIndex: index }, { children: slideContent }), index)); });
        return (_jsx(Wrapper, __assign({ customPaddingLeft: customPaddingLeft }, { children: _jsx(Swiper, __assign({ className: 'mySwiper' }, config, { children: slides }), void 0) }), void 0));
    };
    return Slider;
}(Component));
/** @component */
export default Slider;
var templateObject_1;
