var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { ClickAndXItem } from "molecules/ClickAndXList/styles";
import { getAuthHeader, getUUID } from "@shared/utils/auth";
import localStore from "store";
import axios from "axios";
import paymentTypes from "services/enums/paymentMethods";
import { deliveryModes } from "@shared/utils/constants";
import { FormattedMessage } from "react-intl";
import Spinner from "@shared/atoms/Spinner";
var ClickAndDeliverButtonWrapper = function (_a) {
    var history = _a.history, sessionId = _a.sessionId, shippingCosts = _a.shippingCosts;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    if (loading) {
        return _jsx("div", __assign({ style: { maxWidth: "100px" } }, { children: _jsx(Spinner, {}, void 0) }), void 0);
    }
    return (_jsx("div", { children: _jsxs(ClickAndXItem, __assign({ onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var deviceId, currency, apiUrl, headers, hasPermission, urlObject, origin, redirect, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setLoading(true);
                            deviceId = getUUID(window.location.search) || localStore.get('deviceId');
                            currency = localStore.get('currency') || 'EUR';
                            apiUrl = process.env.REACT_APP_API_URL;
                            headers = {
                                'Authorization': getAuthHeader(deviceId),
                            };
                            return [4 /*yield*/, axios
                                    .get(apiUrl + "irisone/v1/permissions/has-permission", { headers: headers })
                                    .then(function (e) { return e.data; })];
                        case 1:
                            hasPermission = _a.sent();
                            if (!hasPermission) return [3 /*break*/, 5];
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            urlObject = new URL(window.location.href);
                            origin = urlObject.origin + "/" + sessionId;
                            return [4 /*yield*/, axios
                                    .post(apiUrl + "irisone/v1/carts/" + sessionId, {
                                    origin: origin,
                                    currency: currency,
                                    deviceId: deviceId,
                                    shippingCosts: parseFloat(shippingCosts) ? parseFloat(shippingCosts) / 100 : 0
                                }, { headers: headers })
                                    .then(function (e) { return e.data; })];
                        case 3:
                            redirect = _a.sent();
                            window.location.replace(redirect);
                            return [2 /*return*/];
                        case 4:
                            e_1 = _a.sent();
                            console.error(e_1);
                            window.location.replace(origin + "/checkout/error");
                            return [2 /*return*/];
                        case 5:
                            history.push("/" + sessionId + "/click-and-collect", {
                                paymentType: paymentTypes.clickAndDeliver,
                                deliveryMode: deliveryModes.DROPSHIPPING,
                            });
                            return [2 /*return*/];
                    }
                });
            }); } }, { children: [_jsx("span", __assign({ style: { fontSize: 'smaller' } }, { children: _jsx(FormattedMessage, { id: 'clickAndX.basket.ship', defaultMessage: 'Nach Hause liefern' }, void 0) }), void 0), _jsx("img", { src: '/icons/icon_clickndeliver.svg', height: '50px', alt: 'clickndeliver' }, void 0)] }), void 0) }, void 0));
};
export default ClickAndDeliverButtonWrapper;
