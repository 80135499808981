// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    checking: false,
    connectedAPI: true,
    connectedWeb: true,
});
var reducer = handleActions({
    CHECK_SERVICE_CONNECTION: function (state) { return state.set('checking', true); },
    CHECK_SERVICE_CONNECTION_COMPLETE: {
        next: function (state) {
            return state.set('connectedAPI', true).set('checking', false);
        },
        throw: function (state) {
            return state.set('connectedAPI', false);
        },
    },
    CHECK_WEB_CONNECTION: function (state) { return state.set('checking', true); },
    CHECK_WEB_CONNECTION_COMPLETE: {
        next: function (state) {
            return state.set('connectedWeb', true).set('checking', false);
        },
        throw: function (state) {
            return state.set('connectedWeb', false);
        },
    },
}, initialState);
export default reducer;
