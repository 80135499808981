// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    scanning: false,
    searching: false,
    scanResult: '',
});
var reducer = handleActions({
    END_SCAN_SEARCH: {
        next: function (state, action) {
            return state
                .set('scanning', false)
                .set('searching', false)
                .set('scanResult', action.payload);
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    START_SCAN: function (state) {
        state
            .set('scanning', true)
            .set('searching', true)
            .set('scanResult', '');
    },
    STOP_SCAN: function (state) {
        return state
            .set('scanning', false)
            .set('searching', false)
            .set('scanResult', '');
    },
}, initialState);
export default reducer;
