var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import { palette } from '@shared/utils/styled';
import styled from 'styled-components';
import VerticalFlex from 'atoms/VerticalFlex';
export var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n  max-width: 100%;\n  height: 40vh;\n"], ["\n  justify-content: center;\n  align-items: center;\n  max-width: 100%;\n  height: 40vh;\n"])));
export var Tab = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 2px solid ", ";\n  border-radius: 5px;\n  width: 90%;\n  height: 17%;\n  margin-top: 1.5%;\n  background-color: ", ";\n"], ["\n  border: 2px solid ", ";\n  border-radius: 5px;\n  width: 90%;\n  height: 17%;\n  margin-top: 1.5%;\n  background-color: ", ";\n"])), palette('border'), function (props) {
    return props.active ? palette('selectedWhite') : 'transparent';
});
export var StyledLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: inherit;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-decoration: none;\n  width: 100%;\n"], ["\n  align-items: center;\n  color: inherit;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-decoration: none;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
