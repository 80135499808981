var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router';
import { parse } from 'query-string';
// utils
import { toJS } from '@shared/utils/immutable';
// actions
import { getArticle } from '@store/article/actions';
import { createWatchlistItem } from '@store/watchlist/actions';
import { checkoutPos, createSession } from '@store/session/actions';
// selectors
import { makeSelectArticle, loadSingleArticle, selectCategoryId, } from '@store/article/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import ArticlePage from 'pages/Article';
var Article = function (_a) {
    var article = _a.article, categoryId = _a.categoryId, shouldLoadArticle = _a.shouldLoadArticle, getArticle = _a.getArticle, checkoutPos = _a.checkoutPos, createSession = _a.createSession, createWatchlistItem = _a.createWatchlistItem;
    var location = useLocation();
    var params = useParams();
    useEffect(function () {
        if (Boolean(params.articleId) && shouldLoadArticle) {
            getArticle(parseInt(params.articleId));
        }
    }, [params]);
    var queryParams = parse(location.search, { arrayFormat: 'bracket' });
    return (_jsx(SessionContainer, __assign({ checkoutPos: function (payload, sessionId) {
            return checkoutPos(payload, sessionId);
        } }, { children: function (sessionProps) { return (_jsx(ArticlePage, __assign({}, sessionProps, { article: article, createWatchlistItem: function (payload) {
                return createWatchlistItem(payload, sessionProps.session.uuid);
            }, checkoutPos: function (payload, sessionId) {
                return checkoutPos(payload, sessionId);
            }, createSession: function (deviceId) { return createSession(deviceId); }, params: params, source: queryParams && queryParams.source ? queryParams.source : null, primaryCategoryId: queryParams && queryParams.pc ? queryParams.pc : null, categoryId: categoryId }), void 0)); } }), void 0));
};
var select = function () { return function (state, props) { return ({
    article: makeSelectArticle()(state, props),
    categoryId: selectCategoryId(state),
    shouldLoadArticle: loadSingleArticle()(state, props),
}); }; };
export var ArticleCont = Article;
var mapDispatchToProps = function (dispatch) { return ({
    checkoutPos: function (payload, sessionId) {
        return dispatch(checkoutPos(payload, sessionId));
    },
    createSession: function (deviceId) { return dispatch(createSession(deviceId)); },
    createWatchlistItem: function (payload, sessionId) {
        return dispatch(createWatchlistItem(payload, sessionId));
    },
    getArticle: function (articleId) { return dispatch(getArticle(articleId)); },
}); };
export default withRouter(connect(select, mapDispatchToProps)(toJS(ArticleCont)));
