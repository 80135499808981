var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import DepartmentListItem from 'molecules/DepartmentListItem';
import { Wrapper } from './styles';
var DepartmentList = /** @class */ (function (_super) {
    __extends(DepartmentList, _super);
    function DepartmentList(props) {
        var _this = _super.call(this, props) || this;
        _this.isActive = function (departmentId) {
            var params = _this.props.params;
            if (params &&
                params.department &&
                parseInt(params.department, 10) === departmentId) {
                return true;
            }
            return false;
        };
        return _this;
    }
    DepartmentList.prototype.render = function () {
        var _this = this;
        var _a = this.props, departments = _a.departments, params = _a.params, session = _a.session;
        return (_jsx(Wrapper, { children: departments.map(function (department) { return (_jsx(DepartmentListItem, { department: department, params: params, session: session, active: _this.isActive(department.id) }, department.id)); }) }, void 0));
    };
    return DepartmentList;
}(Component));
export default DepartmentList;
