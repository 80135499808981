var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useEffect } from 'react';
// utils
import { useHistory } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
import { connect } from 'react-redux';
import localStore from 'store';
import qs from 'qs';
// actions
import { checkoutPos, createSession, clearCheckoutNumber, } from '@store/session/actions';
import { selectCheckoutNumber } from '@store/session/selectors';
import { getWatchlist } from '@store/watchlist/actions';
// selectors
import { selectWelcomeImage, selectCheckoutMessage, } from '@store/theme/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/SessionDashboard';
var SessionDashboard = function (_a) {
    var getWatchlist = _a.getWatchlist, welcomeImage = _a.welcomeImage, checkoutMessage = _a.checkoutMessage, checkout = _a.checkout, checkoutNumber = _a.checkoutNumber, createSession = _a.createSession, checkoutPos = _a.checkoutPos, theme = _a.theme;
    var queryParams = qs.parse(window.location.search);
    var isPosCheckout = Boolean(queryParams.pos);
    var history = useHistory();
    useEffect(function () {
        var primaryCategoryId = localStore.get('mafGender');
        if (primaryCategoryId && !checkout) {
            history.push("/search/" + primaryCategoryId);
        }
    }, []);
    return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { checkout: checkout, createSession: function (deviceId) { return createSession(deviceId); }, isPosCheckout: isPosCheckout, checkoutPos: function (payload, sessionId) { return checkoutPos(payload, sessionId); }, checkoutNumber: checkoutNumber, clearCheckoutNumber: clearCheckoutNumber, welcomeImage: welcomeImage, checkoutMessage: checkoutMessage, theme: theme, getWatchlist: getWatchlist }), void 0)); } }, void 0));
};
var select = function () { return function (state) { return ({
    welcomeImage: selectWelcomeImage(state),
    checkoutMessage: selectCheckoutMessage(state),
    checkoutNumber: selectCheckoutNumber(state),
}); }; };
var mapDispatchToProps = function (dispatch) { return ({
    checkoutPos: function (payload, sessionId) {
        dispatch(checkoutPos(payload, sessionId));
    },
    clearCheckoutNumber: function () { return dispatch(clearCheckoutNumber()); },
    createSession: function (deviceId) {
        dispatch(createSession(deviceId));
    },
    getWatchlist: function (sessionId) { return dispatch(getWatchlist(sessionId)); },
}); };
export default connect(select, mapDispatchToProps)(toJS(SessionDashboard));
