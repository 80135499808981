var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  grid-template-rows: max-content min-content;\n  justify-items: stretch;\n  height: calc(100vh - 5rem);\n  margin-top: 2.5rem;\n"], ["\n  background: ", ";\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  grid-template-rows: max-content min-content;\n  justify-items: stretch;\n  height: calc(100vh - 5rem);\n  margin-top: 2.5rem;\n"])), palette('white'));
export var FullWidthRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 2 / 7;\n"], ["\n  grid-column: 2 / 7;\n"])));
export var BranchWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  /* margin-top: 2.5rem; */\n  text-align: center;\n  ", ";\n"], ["\n  /* margin-top: 2.5rem; */\n  text-align: center;\n  ", ";\n"])), function (props) {
    return props.borderLocation === 'bottom'
        ? 'border-bottom: 2px solid #000;'
        : 'border-top: 2px solid #000;';
});
export var PaymentInfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 2.5rem;\n  text-align: center;\n  ", ";\n"], ["\n  margin-top: 2.5rem;\n  text-align: center;\n  ", ";\n"])), function (props) {
    return props.borderLocation === 'bottom'
        ? 'border-bottom: 2px solid #000;'
        : 'border-top: 2px solid #000;';
});
export var WatchlistWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-column: 1/8;\n  height: calc(100vh - 30rem);\n"], ["\n  grid-column: 1/8;\n  height: calc(100vh - 30rem);\n"])));
export var StyledButton = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 100%;\n"], ["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 100%;\n"])), palette('secondaryHighlight'), palette('white'), palette('activeWhite'));
export var PaymentInfoTitle = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  grid-column: 2/ 5;\n  width: 100%;\n  text-align: right;\n"], ["\n  grid-column: 2/ 5;\n  width: 100%;\n  text-align: right;\n"])));
export var PaymentInfoAmount = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  grid-column: 5/ 8;\n  padding-left: 2rem;\n"], ["\n  grid-column: 5/ 8;\n  padding-left: 2rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
