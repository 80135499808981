// @flow
import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    loadingAll: false,
    loadedAll: false,
});
var updateEntry = function (entry) { return function (existing) {
    var index = existing.findIndex(function (existingEntry) { return existingEntry.get('id') === entry.get('id'); });
    if (index === -1) {
        return existing.push(entry);
    }
    return existing.set(index, entry);
}; };
var updateEntries = function (entries) { return function (existing) {
    if (existing.size === 0) {
        return entries;
    }
    var newEntries = existing;
    entries.forEach(function (device) {
        // $FlowIgnoreNextLine
        newEntries = newEntries.update(updateEntry(device));
    });
    return newEntries;
}; };
var reducer = handleActions({
    GET_PRIMARY_CATEGORIES: function (state) { return state.set('loadingAll', true); },
    GET_PRIMARY_CATEGORIES_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', updateEntries(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
}, initialState);
export default reducer;
