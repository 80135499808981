var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spinner from '@shared/atoms/Spinner';
import { Header, ItemBlock, ItemButton, Main } from '../DropDownStyles';
var DropDownItems = function (_a) {
    var title = _a.title, filterItems = _a.filterItems, onItemsClick = _a.onItemsClick, onCloseHandle = _a.onCloseHandle, loading = _a.loading, selectedItems = _a.selectedItems, qs = _a.qs, filterType = _a.filterType;
    return (_jsx(_Fragment, { children: _jsxs(Main, { children: [_jsxs(Header, __assign({ onClick: function (e) { return onCloseHandle(e); } }, { children: [_jsx("div", __assign({ style: { display: 'flex', justifyContent: 'center', width: '100%' } }, { children: _jsx("h3", { children: title }, void 0) }), void 0), _jsx("div", __assign({ style: { display: 'flex', flex: 1, justifyContent: 'flex-end' } }, { children: _jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 10.9 5.9' }, { children: _jsx("path", { opacity: '0.4', d: 'M5.4 5.9c-.1 0-.3-.1-.4-.1L.1.9C0 .7 0 .3.1.1s.5-.2.7 0l4.6 4.6L10 .1c.2-.2.5-.2.7 0s.2.5 0 .7l-4.9 5c-.1.1-.2.1-.4.1z' }, void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ style: { overflow: 'auto', maxHeight: '500px' } }, { children: _jsx("div", __assign({ style: {
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            marginLeft: '5px',
                        } }, { children: !loading ? ((filterItems === null || filterItems === void 0 ? void 0 : filterItems.length) > 0 ? (filterItems.map(function (item) { return (_jsx(_Fragment, { children: _jsx(ItemBlock, { children: filterType === 3 || filterType === 0 ? (_jsxs("div", __assign({ onClick: function () { return onItemsClick(item.id, item.name); }, style: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: '0.3rem',
                                        borderRadius: '10%',
                                        minWidth: '6rem',
                                        backgroundColor: selectedItems.some(function (items) { return items.value == item.id && items.type == filterType; })
                                            ? '#EDEDED'
                                            : 'transparent',
                                    } }, { children: [_jsx("div", { children: _jsx("img", { style: {
                                                    width: '30px',
                                                    borderRadius: '0.5em',
                                                }, src: item.image
                                                    ? item.image
                                                    : 'https://storage.googleapis.com/c91b2bb74b7db747a90a1741add6745f/needle_images/app/fallback.svg' }, void 0) }, void 0), _jsx("div", __assign({ style: { margin: '0.2rem' } }, { children: item.name }), void 0)] }), void 0)) : (_jsx(ItemButton, __assign({ onClick: function () { return onItemsClick(item.id ? item.id : item.name, item.name); }, style: {
                                        backgroundColor: selectedItems.some(function (items) {
                                            return item.id
                                                ? items.value == item.id && items.type == filterType
                                                : items.value == item.name && items.type == filterType;
                                        })
                                            ? 'rgb(237, 237, 237)'
                                            : 'transparent',
                                    } }, { children: item.name }), void 0)) }, void 0) }, void 0)); })) : (_jsx("p", { children: "Keine Ergebnisse gefunden" }, void 0))) : (_jsx(Spinner, {}, void 0)) }), void 0) }), void 0)] }, void 0) }, void 0));
};
export default DropDownItems;
