import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
import localStore from 'store';
export var initialState = Map({
    creating: false,
    data: List(),
    error: null,
    status: null,
    deleting: false,
    deletingAll: false,
    loadedAll: false,
    loadingAll: false,
    hasDifferentType: false,
});
/**
 * Updater function to mutate mutate a specific item.
 *
 * @param item - The item that is either existing or new.
 * @returns {Function} The mutated existing object.
 */
export var updateItem = function (item) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (exitem) { return exitem.get('id') === item.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(item);
    }
    // $FlowIgnoreNextLine
    return existing.set(index, item);
}; };
var reducer = handleActions({
    CREATE_WATCHLIST_ITEM: function (state) { return state.set('creating', true); },
    CREATE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state) {
            return state
                .set('creating', false)
                .update('data', function (watchlistItems) { return watchlistItems; });
        },
        throw: function (state) {
            return state.set('creating', false);
        },
    },
    DELETE_WATCHLIST_ITEM: function (state) { return state.set('deleting', true); },
    DELETE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state
                .set('deleting', false)
                .update('data', function (existing) {
                return existing.filter(function (item) { return item.get('id') !== action.payload.get('id'); });
            });
        },
        throw: function (state) {
            return state.set('deleting', false);
        },
    },
    DELETE_WATCHLIST: function (state) { return state.set('deletingAll', true); },
    DELETE_WATCHLIST_COMPLETE: {
        next: function (state) {
            return state.set('deletingAll', false).set('data', List());
        },
        throw: function (state) {
            return state.set('deletingAll', false);
        },
    },
    GET_WATCHLIST: function (state) { return state.set('loadingAll', true); },
    GET_WATCHLIST_COMPLETE: {
        next: function (state, action) {
            var error = false;
            try {
                var sizeId_1 = action.payload.get(0).get('sizeId');
                var firstItemStock_1 = action.payload
                    .get(0)
                    .get('article')
                    .get('sizes')
                    .find(function (size) { return sizeId_1 === size.get('sizeId'); })
                    .get('stock');
                if (action.payload.size > 1) {
                    // eslint-disable-next-line array-callback-return
                    action.payload.map(function (value) {
                        var itemSizeId = value.get('sizeId');
                        var itemStock = value
                            .get('article')
                            .get('sizes')
                            .find(function (size) { return itemSizeId === size.get('sizeId'); })
                            .get('stock');
                        if (itemStock !== firstItemStock_1) {
                            error = true;
                        }
                    });
                }
                if (error) {
                    // TODO Refactor this - I don't like how this is handled
                    localStore.set('watchlistStatus', null);
                    return state
                        .set('loadingAll', false)
                        .set('loadedAll', false)
                        .set('data', action.payload)
                        .set('error', {
                        code: 'NO_MIXED_CARTS_ALLOWED',
                        message: 'watchlist.error.mixedCart',
                        action: action,
                    });
                }
                var watchlistStatus = action.payload
                    .get(0)
                    .get('article')
                    .get('sizes')
                    .find(function (size) { return sizeId_1 === size.get('sizeId'); })
                    .get('stock');
                localStore.set('watchlistStatus', watchlistStatus);
            }
            catch (e) {
                localStore.set('watchlistStatus', null);
            }
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('data', action.payload)
                .set('status', localStore.get('watchlistStatus'))
                .set('error', null);
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    UPDATE_WATCHLIST_ITEM: function (state) { return state; },
    UPDATE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state.update('data', updateItem(action.payload));
        },
        throw: function (state) {
            return state;
        },
    },
    SET_WATCHLIST_STATUS: {
        next: function (state, action) {
            return state.set('status', action.payload);
        },
        throw: function (state) {
            return state;
        },
    },
    SET_WATCHLIST_HAS_DIFFERENT_TYPE: {
        next: function (state, action) {
            return state
                .set('hasDifferentType', action.payload)
                .set('loading', false);
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
