var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import Spinner from '@shared/atoms/Spinner';
import { Wrapper } from './styles';
import TabMenu from 'molecules/TabMenu';
import { stringify, parse } from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
var SortList = /** @class */ (function (_super) {
    __extends(SortList, _super);
    function SortList(props) {
        var _this = _super.call(this, props) || this;
        var baseParams = parse(props.params, { arrayFormat: 'bracket' });
        _this.getTabs = function () {
            var _a = _this.props, sorts = _a.sorts, session = _a.session, currentSortId = _a.currentSortId;
            var tabs = sorts.reduce(function (acc, item) {
                baseParams.sort = item.id;
                acc.push({
                    title: item.displayName,
                    target: "/" + session.uuid + "/results?" + stringify(baseParams, {
                        arrayFormat: 'bracket',
                    }),
                    active: item.id === currentSortId,
                    id: item.id,
                });
                return acc;
            }, []);
            delete baseParams.sort;
            var resetSortsTab = {
                title: _jsx(FormattedMessage, { id: 'sort.tab.cancel', default: 'ABBRECHEN' }, void 0),
                target: "/" + session.uuid + "/results?" + stringify(baseParams, {
                    arrayFormat: 'bracket',
                }),
            };
            tabs.push(resetSortsTab);
            return tabs;
        };
        return _this;
    }
    SortList.prototype.render = function () {
        var loading = this.props.loading;
        var tabs = this.getTabs();
        if (loading) {
            return _jsx(Spinner, {}, void 0);
        }
        return (_jsx(Wrapper, { children: _jsx(TabMenu, { tabs: tabs }, void 0) }, void 0));
    };
    return SortList;
}(Component));
export default injectIntl(SortList);
