var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import localStore from 'store';
import styled from 'styled-components';
import PropTypes from 'prop-types';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #fff;\n  padding: 25px;\n  height: 100%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  background-color: #fff;\n  padding: 25px;\n  height: 100%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
function MatchAndFit(props) {
    var intl = useIntl();
    var url = props.matchAndFitUrl + "&" + (props.matchAndFitOptions || '');
    window.addEventListener('message', function (event) {
        if (event.origin === 'https://feet.matchandfit.com') {
            // Match & Fit doesn't give us the possibility to receive JSON, so we'll just convert the string to valid JSON.
            var data = "{" + event.data + "}";
            data = data
                .replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:([^\/])/g, '"$2":$4')
                .replace(/sizeWms":(\w+)/, 'sizeWms":"$1"');
            var dataJSON = JSON.parse(data);
            // eslint-disable-next-line
            for (var _i = 0, _a = Object.entries(dataJSON); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                try {
                    localStore.set(key, ("" + value).replace(/(\d+)(\.5)/, '$1½'));
                }
                catch (err) {
                    console.log('Error while setting Match & Fit settings: ', err);
                }
            }
            props.handler();
        }
    }, false);
    return (_jsxs(Wrapper, { children: [_jsx("p", { children: _jsx(FormattedMessage, { defaultMessage: '-', id: 'matchandfit.headline' }, void 0) }, void 0), _jsx("iframe", { style: {
                    border: '0',
                    width: '100%',
                    height: '100%',
                }, height: '100', 
                // m=0: Do not send a message on initial load, d=1 : Delete local Storage on load
                src: url, title: 'Fu\u00DFvermessung' }, void 0)] }, void 0));
}
MatchAndFit.propTypes = {
    handler: PropTypes.func,
    matchAndFitOptions: PropTypes.string,
    matchAndFitUrl: PropTypes.string,
};
export default MatchAndFit;
var templateObject_1;
