var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { getImageUrl } from "@shared/utils/images";
import BackgroundImage from "@shared/atoms/BackgroundImage";
import Icon from "@shared/atoms/Icon";
import Slider from "@shared/organisms/Slider";
import { Close, Fullscreen, MainImage, Slide, SliderWrapper } from "./styles";
var ArticleImage = function (_a) {
    var _b, _c, _d, _e;
    var article = _a.article;
    var _f = useState(false), sliderVisible = _f[0], setSliderVisible = _f[1];
    var toggleSlider = function () {
        setSliderVisible(!sliderVisible);
    };
    if (!article) {
        return (_jsx(_Fragment, {}, void 0));
    }
    return (_jsxs("div", { children: [_jsx(MainImage, { "data-cy-id": "article-main-image", onClick: toggleSlider, src: article.images.large || ((_b = article.images.main) === null || _b === void 0 ? void 0 : _b.large) }, void 0), sliderVisible && (_jsx(Fullscreen, { children: _jsxs(SliderWrapper, { children: [_jsx(Close, __assign({ onClick: toggleSlider }, { children: _jsx(Icon, { icon: "closex", width: 16 }, void 0) }), void 0), _jsxs(Slider, __assign({ config: { loop: true } }, { children: [_jsx(Slide, { children: _jsx(BackgroundImage, { url: article.images.large || ((_c = article.images.main) === null || _c === void 0 ? void 0 : _c.large) }, void 0) }, article.images.large || ((_d = article.images.main) === null || _d === void 0 ? void 0 : _d.large)), ((_e = article === null || article === void 0 ? void 0 : article.images) === null || _e === void 0 ? void 0 : _e.detail) &&
                                    article.images.detail.map(function (image) { return (_jsx(Slide, { children: _jsx(BackgroundImage, { url: getImageUrl(image) }, void 0) }, getImageUrl(image))); })] }), void 0)] }, void 0) }, void 0))] }, void 0));
};
export default ArticleImage;
