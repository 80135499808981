import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import _ from "lodash";
import PriceTag from "@shared/molecules/PriceTag";
import DiscountBadgeMedium from "molecules/DiscountBadgeMedium";
import { hasDiscounts } from "@shared/utils/currency";
var SearchResultPrice = function (_a) {
    var price = _a.price;
    if (_.isNil(price) || (_.isNil(price === null || price === void 0 ? void 0 : price.prices) && _.isNil(price === null || price === void 0 ? void 0 : price.targetPrices))) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsx(PriceTag, { prices: price === null || price === void 0 ? void 0 : price.prices, targetPrices: price === null || price === void 0 ? void 0 : price.targetPrices }, void 0), hasDiscounts(price === null || price === void 0 ? void 0 : price.prices, price === null || price === void 0 ? void 0 : price.targetPrices) ? _jsx(DiscountBadgeMedium, {}, void 0) : _jsx("div", {}, void 0)] }, void 0));
};
export default SearchResultPrice;
