var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
// swiper core styles
import 'swiper/swiper.min.css';
// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation, } from 'swiper';
/* eslint-enable */
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  min-height: 0;\n  min-width: 0;\n  position: relative;\n  width: ", ";\n  height: 100%;\n  z-index: 0;\n"], ["\n  margin-top: 0.5rem;\n  min-height: 0;\n  min-width: 0;\n  position: relative;\n  width: ", ";\n  height: 100%;\n  z-index: 0;\n"])), function (props) { return props.customWidth; });
var ControlledSlider = function (_a) {
    var children = _a.children, config = _a.config, customWidth = _a.customWidth;
    return (_jsx(Wrapper, __assign({ customWidth: customWidth || '100%' }, { children: _jsx(Swiper, __assign({ slidesPerView: (config === null || config === void 0 ? void 0 : config.slidesPerView) || 1, spaceBetween: (config === null || config === void 0 ? void 0 : config.spaceBetween) || 10, navigation: (config === null || config === void 0 ? void 0 : config.navigation) || false, breakpoints: {
                '640': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
            }, className: 'mySwiper' }, { children: children.map(function (slideContent, index) {
                return (_jsx(SwiperSlide, __assign({ virtualIndex: index }, { children: slideContent }), index));
            }) }), void 0) }), void 0));
};
/** @component */
export default ControlledSlider;
var templateObject_1;
