var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, key, fontWeight } from '@shared/utils/styled';
import Icon from '@shared/atoms/Icon';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  position: relative;\n  @media screen and (min-width: ", ") {\n    margin-left: 7.5rem;\n  }\n"], ["\n  grid-column: 1 / 3;\n  position: relative;\n  @media screen and (min-width: ", ") {\n    margin-left: 7.5rem;\n  }\n"])), key('breakpoints.large'));
export var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n  margin-top: 1.25rem;\n  width: 100%;\n"], ["\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n  margin-top: 1.25rem;\n  width: 100%;\n"])), fontWeight('bold'));
export var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  max-width: 50rem;\n  grid-template-columns: 1fr 3.5fr;\n  padding-bottom: 1.25rem;\n"], ["\n  display: grid;\n  max-width: 50rem;\n  grid-template-columns: 1fr 3.5fr;\n  padding-bottom: 1.25rem;\n"])));
export var PropWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 0.625rem;\n"], ["\n  padding-left: 0.625rem;\n"])));
export var CancelIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  height: 2rem;\n  padding: 0.625rem;\n  position: absolute;\n  right: 0;\n  top: 0.625rem;\n  font-size: 0;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  color: ", ";\n  height: 2rem;\n  padding: 0.625rem;\n  position: absolute;\n  right: 0;\n  top: 0.625rem;\n  font-size: 0;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('border'), palette('activeWhite'));
export var DrillDownIcon = styled(Icon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 1.2rem;\n  vertical-align: middle;\n  width: 3rem;\n"], ["\n  height: 1.2rem;\n  vertical-align: middle;\n  width: 3rem;\n"])));
export var DescriptionToggle = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  display: block;\n  grid-column: 1 / 3;\n  margin-top: 1.25rem;\n  transition: opacity 0.2s ease-in;\n  font-weight: bold;\n"], ["\n  color: ", ";\n  display: block;\n  grid-column: 1 / 3;\n  margin-top: 1.25rem;\n  transition: opacity 0.2s ease-in;\n  font-weight: bold;\n"])), palette('linkText'));
export var DescriptionWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  white-space: pre-wrap;\n"], ["\n  white-space: pre-wrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
