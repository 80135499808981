var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
export var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 0.0625rem solid;\n  ", ";\n  border-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  line-height: 1.25rem;\n  max-height: 3.75rem;\n  min-height: 3.75rem;\n  text-align: center;\n  padding: 0 0.5rem;\n  position: sticky;\n  top: 0;\n  z-index: 2;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 0.0625rem solid;\n  ", ";\n  border-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  line-height: 1.25rem;\n  max-height: 3.75rem;\n  min-height: 3.75rem;\n  text-align: center;\n  padding: 0 0.5rem;\n  position: sticky;\n  top: 0;\n  z-index: 2;\n"])), palette('primaryHighlight'), function (props) { return (props.topBorder ? 'border-top: 0.0625rem solid' : ''); }, palette('border'), palette('white'), fontSize('large'), fontWeight('bold'));
export var Count = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 2px solid;\n  border-color: ", ";\n  border-radius: 7px;\n  margin-left: 5px;\n  padding: 0.175rem;\n"], ["\n  border: 2px solid;\n  border-color: ", ";\n  border-radius: 7px;\n  margin-left: 5px;\n  padding: 0.175rem;\n"])), palette('border'));
export var Spacer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 4.375rem;\n"], ["\n  width: 4.375rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
