var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import GoBack from 'molecules/GoBack';
import { Wrapper, Spacer, Count } from './styles';
var SubMenu = function (_a) {
    var cancelIcon = _a.cancelIcon, title = _a.title, iconRight = _a.iconRight, noBack = _a.noBack, topBorder = _a.topBorder, count = _a.count, action = _a.action;
    return (_jsxs(Wrapper, __assign({ topBorder: topBorder }, { children: [noBack ? _jsx(Spacer, {}, void 0) : cancelIcon || _jsx(GoBack, { action: action }, void 0), _jsxs("div", { children: [title, count !== 0 && _jsx(Count, { children: count }, void 0)] }, void 0), iconRight || _jsx(Spacer, {}, void 0)] }), void 0));
};
SubMenu.defaultProps = {
    count: 0,
};
export default SubMenu;
