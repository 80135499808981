var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import styled from "styled-components";
var NotFoundContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n\n  @media (max-width: 300px) {\n    transform: scale(0.5);\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n\n  @media (max-width: 300px) {\n    transform: scale(0.5);\n  }\n"])));
var NotFoundStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  max-width: 520px;\n  width: 100%;\n  line-height: 1.4;\n  text-align: center;\n"], ["\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  max-width: 520px;\n  width: 100%;\n  line-height: 1.4;\n  text-align: center;\n"])));
var NotFound404 = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 252px;\n  font-weight: 900;\n  margin: 0px;\n  color: #262626;\n  text-transform: uppercase;\n  letter-spacing: -40px;\n  margin: -70px;\n  transform: translateX(-20px);\n  span {\n    text-shadow: -8px 0px 0px #fff;\n  }\n"], ["\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 252px;\n  font-weight: 900;\n  margin: 0px;\n  color: #262626;\n  text-transform: uppercase;\n  letter-spacing: -40px;\n  margin: -70px;\n  transform: translateX(-20px);\n  span {\n    text-shadow: -8px 0px 0px #fff;\n  }\n"])));
var NotFound = function (props) { return (_jsx(NotFoundContainer, { children: _jsx(NotFoundStyle, { children: _jsxs("div", { children: [_jsxs(NotFound404, { children: [_jsx("span", { children: "4" }, void 0), _jsx("span", { children: "0" }, void 0), _jsx("span", { children: "4" }, void 0)] }, void 0), _jsx("h3", { children: props.notFoundMessage.toUpperCase() }, void 0)] }, void 0) }, void 0) }, void 0)); };
/** @component */
export default NotFound;
var templateObject_1, templateObject_2, templateObject_3;
