var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { palette, fontSize, fontWeight } from "@shared/utils/styled";
var Text = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.2px;\n  white-space: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.2px;\n  white-space: ", ";\n"])), palette("primary"), fontSize("default"), fontWeight("light"), function (props) { return (props.wrapped ? "normal" : "nowrap"); });
/** @component */
export default Text;
var templateObject_1;
