var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
import { key } from '@shared/utils/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  height: calc(100vh - 5rem);\n  overflow-y: auto;\n  padding: 0 0.625rem 0.625rem;\n"], ["\n  color: ", ";\n  height: calc(100vh - 5rem);\n  overflow-y: auto;\n  padding: 0 0.625rem 0.625rem;\n"])), palette('text'));
export var Main = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-column-gap: 0.625rem;\n  grid-template-columns: 1fr 2fr;\n  padding-top: 1.5rem;\n"], ["\n  background: ", ";\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-column-gap: 0.625rem;\n  grid-template-columns: 1fr 2fr;\n  padding-top: 1.5rem;\n"])), palette('white'));
export var BrandImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  width: 5rem;\n"], ["\n  margin-bottom: 1rem;\n  width: 5rem;\n"])));
export var InfoArea = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var ArticleImageWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  img {\n    max-width: 100%;\n    height: auto;\n  }\n"], ["\n  img {\n    max-width: 100%;\n    height: auto;\n  }\n"])));
export var Light = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('light'));
export var Name = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
export var Headline = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  color: ", ";\n"])), fontSize('large'), fontWeight('bold'), palette('transparentBlack'));
export var Block = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
export var Tag = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
export var WmsSize = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: #b52025;\n  margin-top: 1rem;\n"], ["\n  color: #b52025;\n  margin-top: 1rem;\n"])));
export var Slider = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  margin-top: 0.75rem;\n  /* max-height: 12rem; */\n"], ["\n  grid-column: 1 / 3;\n  margin-top: 0.75rem;\n  /* max-height: 12rem; */\n"])));
export var DiscountBadgeWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      .discount-badge-wrapper {\n          right: 0;\n          left: unset;\n      }\n    "], ["\n      .discount-badge-wrapper {\n          right: 0;\n          left: unset;\n      }\n    "])));
export var ButtonWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 5rem;\n  width: 100%;\n  @media screen and (max-width: ", ") {\n    margin-top: 1.5rem;\n  }\n  clear: both;\n"], ["\n  text-align: center;\n  margin-top: 5rem;\n  width: 100%;\n  @media screen and (max-width: ", ") {\n    margin-top: 1.5rem;\n  }\n  clear: both;\n"])), key('breakpoints.xxsmall'));
export var StyledButton = styled(Button)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  grid-column: 1 / 3;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 80%;\n"], ["\n  background-color: ", ";\n  border: 1px black;\n  color: ", ";\n  height: 2.8125rem;\n  padding: 0 1.875rem;\n  grid-column: 1 / 3;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n  &:disabled {\n    background-color: grey;\n  }\n  width: 80%;\n"])), palette('secondaryHighlight'), palette('white'), palette('activeWhite'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
