var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var ratio = function (_a) {
    var width = _a.width, height = _a.height;
    return (height / width) * 100 + "%";
};
var AspectRatio = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  &:before {\n    content: '';\n    display: block;\n    padding-top: ", ";\n  }\n  > .ratioContainer {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n  }\n"], ["\n  position: relative;\n  &:before {\n    content: '';\n    display: block;\n    padding-top: ", ";\n  }\n  > .ratioContainer {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n  }\n"])), ratio);
/** @component */
export default AspectRatio;
var templateObject_1;
