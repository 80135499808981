export var format = function (body, newFormat) {
    if (Array.isArray(body)) {
        var a_1 = [];
        body.map(function (item) { return a_1.push(format(item, newFormat)); });
        return a_1;
    }
    else if (body instanceof Object) {
        var o_1 = {};
        // eslint-disable-next-line array-callback-return
        Object.keys(body).map(function (key) {
            o_1[newFormat(key)] = format(body[key], newFormat);
        });
        return o_1;
    }
    return body;
};
