var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import union from "lodash/union";
import { getImageUrl } from "@shared/utils/images";
import { getPriceString, hasDiscounts } from "@shared/utils/currency";
import sortBy from "lodash/sortBy";
import ArticleImage from "organisms/ArticleImage";
import SliderArticle from "molecules/SliderArticle";
import ArticleCrossSelling from "organisms/ArticleCrossSelling";
import ControlledSlider from "@shared/molecules/ControlledSlider";
import SizeSelect from "molecules/SizeSelect";
import Spinner from "@shared/atoms/Spinner";
import ArticleDescription from "molecules/ArticleDescription";
import PriceTag from "@shared/molecules/PriceTag";
import DiscountBadgeMedium from "molecules/DiscountBadgeMedium";
import { standardWmsSizes } from "@shared/utils/constants";
import { Block, BrandImage, ButtonWrapper, DiscountBadgeWrapper, Headline, InfoArea, Light, Main, Name, Slider, StyledButton, Tag, WmsSize, Wrapper } from "./styles";
var ArticleView = function (_a) {
    var _b, _c;
    var article = _a.article, basePath = _a.basePath, currentSize = _a.currentSize, createWatchlistItem = _a.createWatchlistItem, primaryCategoryId = _a.primaryCategoryId, watchlistItem = _a.watchlistItem, handleSizeSelect = _a.handleSizeSelect, params = _a.params;
    var getColorVariants = function () {
        var variants = [];
        // Only generate color selection, when base article has color variants
        if (Boolean(article) &&
            Boolean(article.relatedArticles) &&
            article.relatedArticles.length > 0) {
            variants.push(article);
            variants = variants.concat(article.relatedArticles);
        }
        return variants;
    };
    var getDesiredPrices = function (article) {
        var firstAvailableSize = article.sizes.find(function (size) {
            return size.stock === "available";
        });
        if (firstAvailableSize) {
            return { min: firstAvailableSize.price, max: firstAvailableSize.price };
        }
        return article.prices;
    };
    var getCurrentPrice = function () {
        if (currentSize) {
            var size = article.sizes.find(function (si) { return si.sizeId === currentSize.sizeId; });
            if (size && (size === null || size === void 0 ? void 0 : size.price) < (size === null || size === void 0 ? void 0 : size.targetPrice)) {
                return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), " ", _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), " "] }, void 0));
            }
            return _jsx(Tag, { children: getPriceString(size === null || size === void 0 ? void 0 : size.price) }, void 0);
        }
        var desiredPrices = getDesiredPrices(article);
        return (_jsx(PriceTag, { prices: desiredPrices, targetPrices: getDesiredPrices }, void 0));
    };
    var isDiscounted = function () {
        if (!article || !article.prices) {
            return false;
        }
        if (Boolean(currentSize) && currentSize) {
            return currentSize.price < currentSize.targetPrice;
        }
        return hasDiscounts(article.prices, article.targetPrices);
    };
    var extractAccessoires = function (article) {
        var furnitures = article.furnitures, accessoires = article.accessoires;
        var part1 = accessoires || [];
        var part2 = furnitures || [];
        return union(part1, part2);
    };
    // This is added as work around to allow translations on ids with variables not static
    var FormattedMessageFixed = function (props) {
        return _jsx(FormattedMessage, __assign({}, props), void 0);
    };
    var colorVariants = getColorVariants();
    var isAddToWatchlistDisabled = !currentSize || Boolean(watchlistItem);
    var articleWmsSize = "";
    var articleSizeTransId = "";
    try {
        if (undefined !== article.content) {
            var widthContent = article.content.filter(function (contentAttribute) { return contentAttribute.name === "width"; });
            articleWmsSize = widthContent.length
                ? standardWmsSizes[widthContent[0].value.toLowerCase()]
                : "";
            articleSizeTransId = "article.sizes." + articleWmsSize;
        }
    }
    catch (e) {
        articleWmsSize = "";
    }
    if (!article) {
        return (_jsx(Wrapper, { children: _jsx(Spinner, {}, void 0) }, void 0));
    }
    return (_jsx(Wrapper, { children: _jsxs(Fragment, { children: [_jsxs(Main, { children: [_jsx(ArticleImage, { article: article }, void 0), _jsxs(InfoArea, { children: [isDiscounted() && _jsxs(DiscountBadgeWrapper, { children: [" ", _jsx(DiscountBadgeMedium, {}, void 0)] }, void 0), _jsx(BrandImage, { src: ((_c = (_b = article.brand) === null || _b === void 0 ? void 0 : _b.image) === null || _c === void 0 ? void 0 : _c.medium) && getImageUrl(article.brand.image) }, void 0), _jsxs(Block, { children: [_jsx(Headline, { children: article.brand.name }, void 0), article.displayName && _jsxs(Light, { children: [article.displayName, " \u2014 "] }, void 0), article.color && article.color, article.number && _jsxs("div", { children: ["Art.Nr. ", article.number] }, void 0)] }, void 0), Boolean(article.prices) && (_jsx("div", { children: _jsx(Headline, { children: getCurrentPrice() }, void 0) }, void 0)), articleWmsSize && (_jsxs(WmsSize, { children: [_jsx(FormattedMessage, { id: "article.width", defaultMessage: "Weite" }, void 0), ":", " ", _jsx(FormattedMessageFixed, { id: articleSizeTransId, defaultMessage: articleWmsSize }, void 0)] }, void 0))] }, void 0), _jsx(ArticleDescription, { description: article.description, fabart: article.fabart, articleNumber: article.number }, void 0), Boolean(colorVariants) && colorVariants.length > 0 && (_jsxs(Slider, { children: [_jsx(Name, { children: "Farben" }, void 0), _jsx(ControlledSlider, __assign({ config: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                        navigation: false
                                    } }, { children: sortBy(colorVariants, "id").map(function (colorVariant) { return (_jsx(SliderArticle, { article: colorVariant, basePath: basePath, active: colorVariant.id === article.id, params: params }, "related" + colorVariant.id)); }) }), void 0)] }, void 0)), _jsx(SizeSelect, { clickHandler: function (size) { return handleSizeSelect(size); }, current: currentSize ? currentSize.sizeId : null, sizes: article === null || article === void 0 ? void 0 : article.sizes, primaryCategoryId: primaryCategoryId }, void 0), _jsx(ArticleCrossSelling, { article: article, accessoires: extractAccessoires(article), basePath: basePath }, void 0)] }, void 0), _jsx(ButtonWrapper, { children: _jsx(StyledButton, __assign({ onClick: function () { return createWatchlistItem(); }, disabled: isAddToWatchlistDisabled }, { children: _jsx(FormattedMessage, { id: "article.addto.basket", defaultMessage: "In den Warenkorb" }, void 0) }), void 0) }, void 0)] }, void 0) }, void 0));
};
export default ArticleView;
