var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ScanForm from 'molecules/ScanForm';
import BarcodePicker from '@shared/atoms/BarcodePicker';
import { Main, Center, Headline, Error } from './styles';
import Button from '@shared/atoms/Button';
import Spinner from '@shared/atoms/Spinner';
import addEanPrefix from '@shared/utils/ean';
import { useSelector } from 'react-redux';
import { selectArticleError, selectLoading } from 'src/store/article/selectors';
var ScanSearch = function (_a) {
    var searchArticle = _a.searchArticle, scanArticle = _a.scanArticle, resetArticleError = _a.resetArticleError, sessionId = _a.sessionId;
    var error = useSelector(selectArticleError);
    var loading = useSelector(selectLoading);
    var _b = useState(false), scanErrors = _b[0], setScanErrors = _b[1];
    var _c = useState(true), cameraAccess = _c[0], setCameraAccess = _c[1];
    var _d = useState(''), scanResult = _d[0], setScanResult = _d[1];
    var submitHandler = function (data) {
        var code = data.code, type = data.type;
        var articleId = code;
        if (type === 'scan') {
            articleId = addEanPrefix(articleId);
        }
        searchArticle(articleId, "/" + sessionId + "/", type);
    };
    var scan = function (scannedData) {
        setScanResult(scannedData);
        var ean = addEanPrefix(scannedData);
        scanArticle("" + ean, "/" + sessionId + "/", 'scan');
    };
    var scanAgain = function () {
        resetArticleError();
        setScanErrors(false);
        setCameraAccess(true);
        setScanResult('');
    };
    var showBarCodeComponent = !scanResult && !loading;
    var showTryAgainMsg = error && scanResult;
    var tryAgainMsg = (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Scan nicht erfolgreich', id: 'scan.fallback.headline' }, void 0) }, void 0), _jsx(Center, { children: _jsx(FormattedMessage, { defaultMessage: 'Suchen Sie erneut oder nutzen Sie die manuelle Eingabe.', id: 'scan.fallback.message' }, void 0) }, void 0), _jsx(Error, { children: scanResult }, void 0), _jsx(Center, { children: _jsx(Button, __assign({ onClick: scanAgain }, { children: "Erneut Scannen" }), void 0) }, void 0)] }, void 0));
    var errorMsg = (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Scan nicht erfolgreich', id: 'scan.fallback.headline' }, void 0) }, void 0), _jsx(Center, { children: _jsx(FormattedMessage, { defaultMessage: 'Suchen Sie erneut oder nutzen Sie die manuelle Eingabe.', id: 'scan.fallback.message' }, void 0) }, void 0)] }, void 0));
    return (_jsxs(Main, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Barcode Scannen oder manuelle eingebe', id: 'scan.headline' }, void 0) }, void 0), showBarCodeComponent && (_jsx(BarcodePicker, { onScan: scan, handleCameraAccess: function (access) { return setCameraAccess(access); } }, void 0)), loading && _jsx(Spinner, {}, void 0), scanErrors && errorMsg, showTryAgainMsg && tryAgainMsg, !cameraAccess && 0 === scanResult.length && false === loading && (_jsx("div", { children: _jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Kein Zugriff auf die Kamera', id: 'scan.fallback.noCameraAccess' }, void 0) }, void 0) }, void 0)), _jsx(ScanForm, { onSubmit: submitHandler, scanResult: scanResult }, void 0)] }, void 0));
};
export default ScanSearch;
