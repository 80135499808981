var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { palette } from '@shared/utils/styled';
import store from 'store';
import Dashboard from 'organisms/Dashboard';
import SessionLayout from 'templates/SessionLayout';
import { withTheme } from 'styled-components';
var SessionDashboard = function (_a) {
    var checkout = _a.checkout, checkoutMessage = _a.checkoutMessage, checkoutNumber = _a.checkoutNumber, checkoutPos = _a.checkoutPos, clearCheckoutNumber = _a.clearCheckoutNumber, createSession = _a.createSession, deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, getWatchlist = _a.getWatchlist, session = _a.session, theme = _a.theme, isPosCheckout = _a.isPosCheckout, watchlist = _a.watchlist, welcomeImage = _a.welcomeImage;
    var initState = useCallback(function () {
        var now = new Date();
        store.set('dashVisit', now);
        return (store.get('enteredFromScan') === true && watchlist.length > 0 && !checkout);
    }, [checkout, watchlist.length]);
    var _b = useState(false), openWatchlist = _b[0], setopenWatchlist = _b[1];
    useEffect(function () {
        return setopenWatchlist(initState());
    }, [initState, watchlist]);
    return (_jsx(SessionLayout, __assign({ deleteWatchlistItem: deleteWatchlistItem, backgroundColor: palette('white'), session: session, hideBottomMenu: true, noBorder: true, watchlist: watchlist }, { children: _jsx(Dashboard, { createSession: createSession, deleteWatchlist: deleteWatchlist, deleteWatchlistItem: deleteWatchlistItem, openWatchlist: openWatchlist, session: session, watchlist: watchlist, welcomeImage: welcomeImage, checkout: checkout, checkoutMessage: checkoutMessage, checkoutNumber: checkoutNumber, checkoutPos: checkoutPos, clearCheckoutNumber: clearCheckoutNumber, theme: theme, isPosCheckout: isPosCheckout, getWatchlist: getWatchlist }, void 0) }), void 0));
};
export default withTheme(SessionDashboard);
