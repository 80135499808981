var _a;
import { createActions, createAction } from 'redux-actions';
export var checkServiceConnectionComplete = (_a = createActions('CHECK_SERVICE_CONNECTION_COMPLETE', 'CHECK_WEB_CONNECTION_COMPLETE'), _a.checkServiceConnectionComplete), checkWebConnectionComplete = _a.checkWebConnectionComplete;
export var checkServiceConnection = createAction('CHECK_SERVICE_CONNECTION', function (payload) { return payload; }, function (payload) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return ({ params: params });
});
export var checkWebConnection = createAction('CHECK_WEB_CONNECTION', function (payload) { return payload; }, function (payload) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return ({ params: params });
});
