import { createSelector } from 'reselect';
export var selectIdentityState = function (state) { return state.get('identity'); };
export var selectId = createSelector(selectIdentityState, function (identityState) {
    return identityState.get('id');
});
export var selectLoaded = createSelector(selectIdentityState, function (identityState) {
    return identityState.get('loaded');
});
export var selectError = createSelector(selectIdentityState, function (identityState) {
    return identityState.get('error');
});
