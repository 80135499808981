import { prop } from 'styled-tools';
import theme from '../themes/default';
/**
 * Returns the value of `props.theme[path]` or `styledTheme[path]`.
 *
 * @example
 * const Button = styled.button`
 *  font-family: ${key('fonts.primary')};
 *  color: ${key(['colors', 'primary', 0])};
 * `
 *
 * @param path - Key path of theme.
 * @param defaultValue - Default value for this combination.
 * @returns {function(*): *} - Value of given key path.
 */
export var key = function (path, defaultValue) { return function (props) {
    var propPath = Array.isArray(path) ? path.join('.') : path;
    var returnVal = prop(propPath, prop(propPath, defaultValue)(theme))(props.theme);
    return returnVal;
}; };
/**
 * Returns the value of `props.theme[palette || reversePalette][path][index]` or
 * `styledTheme[palette || reversePalette][path][index]` (default theme)
 *
 * The arguments can be passed in any order, as long as types are kept.
 * @param {number} index The index of tone in theme palette tones array
 * @param {string} [path=props.palette] The key of the tones in theme palette object
 * @param {Object} [exceptions] An object with path as key and index as value
 * @param {string} [defaultValue] Default value
 * @example
 * // index = 1
 * // exception = { grayscale: 0 }
 * const Button = styled.button`
 *  background-color: ${palette({ grayscale: 0 }, 1, true)};
 * `
 *
 * // renders props.theme.reversePalette.grayscale[0]
 * <Button palette="grayscale" />
 * @returns {Tones}
 */
export var palette = function (value) {
    return function (props) {
        var defaultValue = 'inherit';
        return key(['palette', value], defaultValue !== value && defaultValue)(props);
    };
};
export var fontSize = function (value) { return function (props) {
    var defaultValue = 'inherit';
    return key(['fontSizes', value], defaultValue !== value && defaultValue)(props);
}; };
export var fontWeight = function (value) { return function (props) {
    var defaultValue = 'inherit';
    return key(['fontWeights', value], defaultValue !== value && defaultValue)(props);
}; };
