var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from "react";
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
var fontSize = function (_a) {
    var width = _a.width, height = _a.height;
    var size = width || height;
    return size ? size / 16 + "rem" : "1.25em";
};
var Wrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: inline-block;\n  font-size: ", ";\n  height: 1em;\n  width: 1em;\n\n  svg {\n    fill: ", ";\n    ", ";\n  }\n"], ["\n  box-sizing: border-box;\n  display: inline-block;\n  font-size: ", ";\n  height: 1em;\n  width: 1em;\n\n  svg {\n    fill: ", ";\n    ", ";\n  }\n"])), fontSize, function (props) { return props.fill || palette("iconColor"); }, function (props) {
    return !props.noMetrics &&
        "height: 100%;\n       width: 100%;";
});
var Icon = function (_a) {
    var icon = _a.icon, props = __rest(_a, ["icon"]);
    // eslint-disable-next-line import/no-webpack-loader-syntax
    var svgDir = require.context("!@svgr/webpack?icon=true!./icons/");
    var SvgIconComponent = svgDir("./" + icon + ".svg").default;
    if (icon) {
        return (_jsx(Wrapper, __assign({}, props, { children: _jsx(SvgIconComponent, {}, void 0) }), void 0));
    }
};
/** @component */
export default Icon;
var templateObject_1;
