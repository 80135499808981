var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import { injectIntl, defineMessages } from 'react-intl';
import { stringify } from 'query-string';
// components
import { BackButtonSmall } from '@shared/molecules/BackButtonSmall';
import DepartmentList from 'molecules/DepartmentList';
import history from '@store/history';
import SessionLayout from 'templates/SessionLayout';
import styled from 'styled-components';
import SubMenu from 'organisms/SubMenu';
var Spacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 4.3575rem;\n"], ["\n  width: 4.3575rem;\n"])));
var Department = /** @class */ (function (_super) {
    __extends(Department, _super);
    function Department() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Department.prototype.render = function () {
        var _a = this.props, deleteWatchlistItem = _a.deleteWatchlistItem, departments = _a.departments, departmentsLoading = _a.departmentsLoading, intl = _a.intl, params = _a.params, session = _a.session, watchlist = _a.watchlist;
        var msg = defineMessages({
            departments: {
                defaultMessage: 'Abteilungen',
                id: 'departments.menu.headline',
            },
        });
        return (_jsx(SessionLayout, __assign({ deleteWatchlistItem: deleteWatchlistItem, session: session, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.departments), cancelIcon: _jsx(Spacer, {}, void 0), iconRight: _jsx(BackButtonSmall, { action: function () {
                        return history.push("/" + session.uuid + "/inspiration?" + (params
                            ? stringify(params, {
                                arrayFormat: 'bracket',
                            })
                            : ''));
                    }, fill: 'white', withText: true }, void 0) }, void 0), backgroundColor: palette('white'), watchlist: watchlist }, { children: Boolean(departments) && (_jsx(DepartmentList, { departments: departments, loading: departmentsLoading, params: params, session: session }, void 0)) }), void 0));
    };
    return Department;
}(Component));
export var DepartmentComponent = Department;
export default injectIntl(Department);
var templateObject_1;
