var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
var H2 = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4;\n  line-height: 2rem;\n  margin: 0.875rem 0;\n"], ["\n  color: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4;\n  line-height: 2rem;\n  margin: 0.875rem 0;\n"])), palette('highlightText'), fontWeight('bold'));
/** @component */
export default H2;
var templateObject_1;
