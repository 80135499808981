import { getSearchParams } from '@shared/utils/params';
import { createSelector } from 'reselect';
import { selectAll as selectAllDepartments } from '@store/department/selectors';
import { makeSelectSession } from '@store/session/selectors';
var selectState = function (state) { return state.get('inspiration'); };
var selectSearchParams = function (state, props) {
    return props.location.search ? getSearchParams(props.location.search) : null;
};
export var selectShouldLoadAll = createSelector(selectState, function (state) { return !state.get('loadingAll') && !state.get('loadedAll'); });
export var selectAll = createSelector(selectState, function (state) {
    return state.get('data');
});
export var selectLoadingAll = createSelector(selectState, function (state) {
    return state.get('loadingAll');
});
export var selectCurrent = function () {
    return createSelector([selectAll, selectSearchParams, selectAllDepartments, makeSelectSession()], function (state, searchParams, departments, session) {
        var paramsDepId = searchParams && searchParams.department
            ? parseInt(searchParams.department, 10)
            : null;
        var paramsPriCatId = searchParams && searchParams.priCat
            ? parseInt(searchParams.priCat, 10)
            : null;
        // Inspiration page is already selected?
        if (paramsPriCatId) {
            return state.find(function (insp) {
                return insp.get('primaryCategoryId') === paramsPriCatId &&
                    insp.get('departmentId') === paramsDepId;
            });
        }
        // On inital visit
        // Is there a page connected to the session?
        if (session && session.get('lastInspirationId')) {
            return state.find(function (item) { return item.get('id') === session.get('lastInspirationId'); });
        }
        // Otherwise return the first page or when possible the first page of the first department
        if (departments && departments.length > 0) {
            var departmentPages = state.find(function (item) { return item.get('departmentId') === departments.get(0); });
            return departmentPages.get(0);
        }
        return state.get(0);
    });
};
