var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// @flow
import styled from 'styled-components';
import { key } from '@shared/utils/styled';
import VerticalFlex from 'atoms/VerticalFlex';
import { palette } from '@shared/utils/styled';
export var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: stretch;\n  height: calc(100% - 3.125rem);\n  width: 100%;\n  padding: 0;\n  transition: background-color 0.5s ease;\n  overflow: auto;\n"], ["\n  justify-content: stretch;\n  height: calc(100% - 3.125rem);\n  width: 100%;\n  padding: 0;\n  transition: background-color 0.5s ease;\n  overflow: auto;\n"])));
export var ImageWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  opacity: ", ";\n  transition: opacity 0.5s ease;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  opacity: ", ";\n  transition: opacity 0.5s ease;\n"])), function (props) { return (props.hide ? 0 : 1); });
export var LogoWrapper = styled(ImageWrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 0 1 20%;\n  margin-top: 10%;\n"], ["\n  flex: 0 1 20%;\n  margin-top: 10%;\n"])));
export var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 40%;\n  @media screen and (max-width: ", ") {\n    width: 70%;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 100%;\n  }\n"], ["\n  width: 40%;\n  @media screen and (max-width: ", ") {\n    width: 70%;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 100%;\n  }\n"])), key('breakpoints.medium'), key('breakpoints.xsmall'));
export var Logo = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 15%;\n  @media screen and (max-width: ", ") {\n    width: 30%;\n  }\n  @media screen and (max-width: ", ") {\n    width: 25%;\n  }\n"], ["\n  width: 15%;\n  @media screen and (max-width: ", ") {\n    width: 30%;\n  }\n  @media screen and (max-width: ", ") {\n    width: 25%;\n  }\n"])), key('breakpoints.medium'), key('breakpoints.large'));
export var CheckoutWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  margin: 10px 0;\n  text-align: center;\n"], ["\n  width: 100%;\n  margin: 10px 0;\n  text-align: center;\n"])));
export var CheckoutMessage = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  margin: 0 auto;\n  font-size: 1.5rem;\n  color: #72b93a;\n  padding: 5px;\n  max-width: 90%;\n  line-height: 1.5rem;\n  text-align: center;\n"], ["\n  position: relative;\n  margin: 0 auto;\n  font-size: 1.5rem;\n  color: #72b93a;\n  padding: 5px;\n  max-width: 90%;\n  line-height: 1.5rem;\n  text-align: center;\n"])));
export var CheckoutNumber = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 4rem;\n  font-weight: bold;\n  margin: 0 auto;\n  margin-top: 20px;\n  max-width: 90%;\n"], ["\n  font-size: 4rem;\n  font-weight: bold;\n  margin: 0 auto;\n  margin-top: 20px;\n  max-width: 90%;\n"])));
export var FooterWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border-top: 0.125rem solid ", ";\n  margin: 0;\n  padding: 0 5px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 3.125rem;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border-top: 0.125rem solid ", ";\n  margin: 0;\n  padding: 0 5px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 3.125rem;\n"])), palette('white'), palette('black'), palette('border'));
export var FooterItem = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var FooterLink = styled.a(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  text-decoration: none;\n"], ["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  text-decoration: none;\n"])), palette('black'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
